import React, { Fragment } from "react";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Form } from "react-final-form";
import { Row } from "react-flexbox-grid";
//import { get } from "lodash";
import { toastr } from "react-redux-toastr";
import { apiBaseUrl } from "api/config";

import LMCLogo from "../../resources/lmc-logo.png";
import FormFields from "components/template/FormFields";

import FormContent from "./components/formContent";

const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  .instructions-field {
    margin-top: 10px;
  }
  .service-field{
    margin-bottom: 10px;
  }
`;

const Img = styled.img`
  margin: 40px auto 0;
  flex-shrink: 0;
  width: 205px;
`;

const TitleLabel = styled.div`
  font-size: 30px;
  color: #184961;
  margin-top: 40px;
  text-align: center;
  padding: 0px 20px;
`;

const DescLabel = styled.div`
  font-size: 18px;
  margin-top: 5px;
  color: #184961;
  text-align: center;
`;

const TitleThanksLabel = styled.div`
  font-size: 30px;
  color: #184961;
  margin-top: 10px;
  text-align: center;
`;

const DescThanksLabel = styled.div`
  font-size: 18px;
  margin: 5px auto;
  margin-bottom: 10px;
  color: #184961;
  width: 300px;
  text-align: center;
`;

const VesselCode = styled.div`
  font-family: "Neutraface2-Text-Demi";
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 5px;
  color: #fff;
  text-align: center;
  background-color: #8b9cb8;
  width: 100%;
  padding: 15px;
  margin-bottom: 30px;
`;

const SectionWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const FielsContainer = styled.div`
  width: 100%;
`;

const SectionContainer = styled.div`
  padding: 20px 50px;
  .primary {
    .field-section:first-child > div {
      font-weight: 400;
      color: #184961;
      font-family: "Neutraface2-Text-Bold" !important;
    }
    .field-section > select {
      padding-left: 0px;
    }
    .field-section > select,
    .react-datepicker-ignore-onclickoutside,
    .react-datepicker__month-container,
    .field-section > textarea,
    .field-section > input,
    .react-datepicker__input-container > input {
      padding-right: 0px;
      font-weight: 400;
      font-family: "Neutraface2-Text-Book" !important;
    }
    .field-section {
      input {
        padding-left: 10px;
        padding-top: 1px;
      }
      .hour {
        min-width: unset;
        width: 100%;
        outline: none;
        border: none;
      }
      .hour > div {
        box-shadow: none !important;
        outline: none !important;
        &:hover {
          border-color: #dfdfdf;
        }
      }
    }
  }
`;

const ActionButton = styled.button`
  font-family: "Neutraface2-Text-Bold";
  background: #314357;
  border-radius: 20px;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  height: 50px;
  margin-top: 40px;
  border: none;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;
`;

const CancelLink = styled.a`
  font-family: "Neutraface2-Text-Book";
  font-weight: 400;
  font-size: 18px;
  color: #184961;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  text-decoration: underline;
  cursor: pointer;
`;

class ActivitiesReminder extends React.PureComponent {
  state = {
    headers: {},
    token: '',
    activityId: 0,
    loading: true,
    thanks: false,
    cancelled: false,
    activity: {}
  };

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query && query.activity && query.token) {
      this.setState({ activityId: query.activity, token: query.token}, () => {
        this.loadActivity();
      });
    } else {
      setTimeout(() => toastr.error("Error", "Token Not Found"), 100);
    }
  }

  loadActivity = () => {
    const { activityId, token } = this.state;

    let headers = {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: token
    };
    axios
      .get(`${apiBaseUrl}/activities/${activityId}`, {
        headers: headers
      })
      .then(response => {
        if (response.data) {
          const { activity_id, vessel, arrival_date, subject, special_instructions } = response.data;
          if (activity_id) {
            this.setState({
              loading: false,
              headers,
              activity: {
                id: activity_id,
                vessel: vessel || "",
                service: subject,
                special_instructions: special_instructions || '',
                date: arrival_date,
                time: moment(arrival_date).format("h:mma")
              }
            });
          }
        }
      })
      .catch(e => {
        toastr.error("Error", "Link expired");
      });
  };

  getActivityFields = () => {
    const { activity } = this.state;
    const fields = [
      {
        field: "service",
        label: "SERVICE:",
        type: "text_field",
        className: "primary no-padding-left service-field",
        required: false,
        disabled: true,
        defaultValue: activity.service || "",
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12
      },
      {
        type: "date",
        field: "arrival",
        className: "primary no-padding-left",
        label: "DATE:",
        errorMessage: "Enter the date",
        required: true,
        defaultValue: activity.date ? new Date(activity.date) : new Date(),
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
        xl: 6
      },
      {
        type: "inputable_time",
        field: "time",
        className: "primary",
        label: "TIME:",
        errorMessage: "Enter the time",
        required: true,
        defaultValue: {
          value: activity.time || "",
          label: activity.time || ""
        },
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
        xl: 6
      },
      {
        type: "text_area",
        field: "special_instructions",
        className: "primary instructions-field",
        label: "SPECIAL INSTRUCTIONS:",
        required: false,
        defaultValue: activity.special_instructions || "",
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12
      }
    ];
    return fields;
  };

  handleSubmit = () => {
    const { activityId, headers } = this.state;

    if(this.mainInfoFields.validateFields()){
      const fields = this.mainInfoFields.getFieldValues();
      const selectedTime = moment(fields.time.value, "h:mma").format("HH:mm:ss");
      const selectedDate = moment(fields.arrival).format("YYYY-MM-DD");
      const activityDate = moment(`${selectedDate}T${selectedTime}`, "YYYY-MM-DDTHH:mm:ss");

      const params = {
        specific_start: activityDate.format(),
        special_instructions: fields.special_instructions
      }

      axios
        .put(`${apiBaseUrl}/activities/${activityId}`, { activity: params }, {
          headers: headers
        })
        .then(response => {
          this.setState({ thanks: true });
        })
        .catch(e => {
          toastr.error("Error", e.message);
        });
    }
  };

  setMainFieldsRef = ref => {
    this.mainInfoFields = ref;
  };

  handleCancel = e => {
    e.preventDefault();
    const { activityId, headers } = this.state;

    axios
    .delete(`${apiBaseUrl}/activities/${activityId}`, {
      headers: headers
    })
    .then(response => {
      this.setState({ cancelled: true, thanks: true });
    })
    .catch(e => {
      toastr.error("Error", e.message);
    });  
  };

  gotToWeb = () => {
    this.setState({ loading: true }, () => {
      window.location.href = 'https://www.lauderdalemarinecenter.com/';
    });
  }

  render() {
    const { activity, loading, thanks, cancelled } = this.state;
    const activityFields = this.getActivityFields();

    return (
      <FormContent loading={loading}>
        <Fragment>
          {!thanks && (
            <Form
              onSubmit={this.handleSubmit}
              render={({ handleSubmit, submitting }) => (
                <FormContainer onSubmit={handleSubmit}>
                  <Img src={LMCLogo} />

                  <Fragment>
                    <TitleLabel>{"Your Service"}</TitleLabel>
                    <DescLabel>
                      {"Please confirm or modify your service."}
                    </DescLabel>

                    <SectionWrapper>
                      <SectionContainer>
                        <Row>
                          <VesselCode>
                            VESSEL: {activity.vessel || ""}
                          </VesselCode>
                        </Row>
                        <Row>
                          <FielsContainer>
                            <FormFields
                              ref={this.setMainFieldsRef}
                              fields={activityFields}
                            />
                          </FielsContainer>
                        </Row>
                        <Row>
                          <ActionButton>CONFIRM</ActionButton>
                        </Row>
                        <Row>
                          <CancelLink onClick={this.handleCancel}>
                            Cancel Service
                          </CancelLink>
                        </Row>
                      </SectionContainer>
                    </SectionWrapper>
                  </Fragment>
                </FormContainer>
              )}
            />
          )}
          {thanks && (
            <Fragment>
              <Img src={LMCLogo} />
              <SectionWrapper>
                <SectionContainer>
                  <TitleThanksLabel>{"Thank You!"}</TitleThanksLabel>
                  <DescThanksLabel>
                    {!cancelled && (
                      <span>
                        Thank you for your confirmation.
                        <br />
                        We look forward to welcoming you to Lauderdale Marine
                        Center.
                      </span>
                    )}
                    {cancelled && (
                      <span>
                        One of our friendly staff will contact you shortly to
                        confirm.
                      </span>
                    )}
                  </DescThanksLabel>
                  <ActionButton onClick={this.gotToWeb}>VIEW OUR WEBSITE</ActionButton>
                </SectionContainer>
              </SectionWrapper>
            </Fragment>
          )}
        </Fragment>
      </FormContent>
    );
  }
}

export default withRouter(ActivitiesReminder);
