import React, { Fragment } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Row, Col } from "react-flexbox-grid";
import { isEmpty } from "lodash";
import { toastr } from "react-redux-toastr";
import { cdnBaseUrl } from "api/config";
import LoadingSpinner from "components/basic/LoadingSpinner";
import PoweredByImage from "resources/powered_by.png";
import PrintIcon from "resources/print_icon.png";
import { HollowButton, BlackButton } from "components/basic/Buttons";
import { googleApiKey } from "api/config";
import { formatPhoneNumber } from "utils/basic";

import {
  CustomCol,
  Wrapper,
  ContentWrapper,
  ContainerWrapper,
  ContentHeader,
  ContentInfo,
  ContentInfoItems,
  ItemsWrapper,
  DescriptionWrapper,
  TitleSection,
  TitleSubSection,
  Logo,
  PoweredBy,
  PoweredByContent,
  PrintButton,
  Icon,
  FooterContainer,
  getJobInfo,
  updateAssignment,
  handleImageError
} from "./index";

const Map = styled.img`
  margin-left: -10px;
  @media (max-width: 699px) {
    margin-top: 20px;
    margin-left: 0px;
  }
`;

class Job extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      assignmentId: 0,
      job: {
        provider: {
          address: {}
        },
        user: {},
        boat: {},
        boatLocation: {},
        services: [],
        service: {}
      },
      token: ""
    };
  }

  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && !isEmpty(queryParams)) {
      getJobInfo(queryParams)
        .then(job => {
          this.setState(
            {
              job,
              token: job.token,
              assignmentId: queryParams.assignment_id,
              loading: false
            },
            () => {}
          );
        })
        .catch(e =>
          toastr.error("Error", "Something went wrong. Try again later.")
        );
    } else {
      this.setState({ loading: true }, () => {
        setTimeout(() => toastr.error("Error", "Missing parameters"), 200);
      });
    }
  }

  jobTransition = (transition = "decline") => {
    const { token, assignmentId } = this.state;
    const params = {
      token,
      assignmentId,
      data: {
        transition
      }
    };

    this.setState({ loading: true }, () => {
      updateAssignment(params)
        .then(() => {
          setTimeout(() => window.location.reload(), 500);
        })
        .catch(e => {
          this.setState({ loading: false });
          toastr.error("Error", "Something went wrong. Try again later.");
          console.warn("Error", e);
        });
    });
  };

  render() {
    const { loading, job } = this.state;
    let LogoImg = "";

    if (job.provider.logo && job.provider.logo.url) {
      LogoImg = job.provider.logo.url;
    } else {
      LogoImg = job.provider.id
        ? `${cdnBaseUrl}provider/logo/${job.provider.id}/logo.png`
        : "";
    }

    const mapSize = job.boat.name ? "300x285" : "300x120";
    const location =
      job.boatLocation && job.boatLocation.street
        ? `${job.boatLocation.street || ""}, ${job.boatLocation.city ||
            ""}, ${job.boatLocation.state || ""}, ${job.boatLocation.country ||
            ""}`
        : "";
    const googleStaticMap = `http://maps.googleapis.com/maps/api/staticmap?center=${location}
      &zoom=13&size=${mapSize}&maptype=roadmap&key=${googleApiKey}`;
    const googleMapUrl = `https://www.google.com/maps/place/${location}`;
    const haveBoat = Boolean(job.boat.name || job.boat.make || job.boat.model);
    const haveLocation = Boolean(job.boatLocation && job.boatLocation.street);

    return (
      <Wrapper className="job-summary">
        {job.id && (
          <ContentWrapper>
            <ContainerWrapper>
              <ContentHeader>
                <span>job summary.</span>
                <PrintButton onClick={window.print}>
                  <Icon src={PrintIcon} />
                </PrintButton>
              </ContentHeader>
              <ContentInfo>
                <Row>
                  <Col xs={12} sm={6} md={6} className="logoXS">
                    {LogoImg && (
                      <Logo
                        src={LogoImg}
                        className="logoXS"
                        onError={handleImageError}
                      />
                    )}
                  </Col>
                  {Boolean(job.provider && job.provider.name) && (
                    <Col xs={12} sm={6} md={6}>
                      <TitleSection>{job.provider.name || ""}</TitleSection>
                      <span>
                        <strong>Job</strong> #{job.id || ""}
                      </span>
                      {job.orderId && (
                        <span>
                          <strong>Order</strong> #{job.orderId}
                        </span>
                      )}
                      <span>
                        <strong>Date</strong> {job.updatedAt}
                      </span>
                    </Col>
                  )}
                  <Col xs={12} sm={6} md={6}>
                    {LogoImg && (
                      <Logo src={LogoImg} onError={handleImageError} />
                    )}
                  </Col>
                </Row>

                {Boolean(!isEmpty(job.user)) && (
                  <Row>
                    <Col xs={12} sm={6} md={6}>
                      <Fragment>
                        <TitleSection>Customer Info</TitleSection>
                        {Boolean(job.user.first_name || job.user.last_name) && (
                          <span>
                            <strong>Name: </strong>
                            {job.user.first_name || ""}{" "}
                            {job.user.last_name || ""}
                          </span>
                        )}
                        {Boolean(job.user.email) && (
                          <span>
                            <strong>Email: </strong>
                            {job.user.email || ""}
                          </span>
                        )}
                        {Boolean(job.user.phone_number) && (
                          <span>
                            <strong>Phone: </strong>
                            {formatPhoneNumber(job.user.phone_number) || ""}
                          </span>
                        )}
                      </Fragment>
                    </Col>
                  </Row>
                )}
              </ContentInfo>
              <ContentInfoItems>
                <ItemsWrapper>
                  {job.services &&
                    job.services.map((item, idx) => (
                      <Fragment key={`item_${idx}`}>
                        <Row>
                          <Col className="firstColumn" xs={4} sm={4} md={4}>
                            <TitleSubSection>Service</TitleSubSection>
                          </Col>
                          <Col xs={4} sm={4} md={4}>
                            <TitleSubSection>Due Date</TitleSubSection>
                          </Col>

                          {item.time && (
                            <Col xs={4} sm={4} md={4}>
                              <TitleSubSection>Time</TitleSubSection>
                            </Col>
                          )}
                        </Row>

                        {Boolean(item.name) && (
                          <Row className="itemRow">
                            <Col className="firstColumn" xs={4} sm={4} md={4}>
                              <span>{item.name || "-"}</span>
                            </Col>

                            <Col xs={4} sm={4} md={4}>
                              <span>{item.due_date || "-"}</span>
                            </Col>
                            {item.time && (
                              <Col xs={4} sm={4} md={4}>
                                <span>{item.time || "-"}</span>
                              </Col>
                            )}
                          </Row>
                        )}
                        {Boolean(item.special_instructions) && (
                          <Row className={`instructions job_instructions`}>
                            <Col xs={12} className="mt-4">
                              <TitleSubSection>
                                Special Instructions
                              </TitleSubSection>
                              <p>{item.special_instructions}</p>
                            </Col>
                          </Row>
                        )}
                        {Boolean(
                          job.services.length > 1 &&
                            idx !== job.services.length - 1
                        ) && <div className="divisor service-row" />}
                      </Fragment>
                    ))}

                  {Boolean(haveLocation || job.specialInstructions) && (
                    <div className="divisor" />
                  )}
                </ItemsWrapper>
                {Boolean(
                  haveLocation || job.specialInstructions || haveBoat
                ) && (
                  <DescriptionWrapper>
                    {Boolean(haveBoat && haveLocation) && (
                      <Row className="container location_map">
                        <CustomCol xs={12} sm={6} md={6} style={!haveLocation ? { padding: 0 } : {}}>
                          {Boolean(haveBoat) && (
                            <CustomCol
                              xs={12}
                              sm={12}
                              md={12}
                              style={!haveLocation ? { paddingTop: 40 } : {}}
                            >
                              <TitleSubSection>Boat</TitleSubSection>
                              {Boolean(job.boat.name) && (
                                <p>
                                  <strong>Name </strong>
                                  {job.boat.name || ""}
                                </p>
                              )}
                              {job.boat.make && (
                                <p>
                                  <strong>Make </strong>
                                  {job.boat.make || ""}
                                </p>
                              )}
                              {job.boat.model && (
                                <p>
                                  <strong>Model </strong>
                                  {job.boat.model || ""}
                                </p>
                              )}
                              {job.boat.length && (
                                <p>
                                  <strong>Lenght </strong>
                                  {job.boat.length || ""}
                                </p>
                              )}
                            </CustomCol>
                          )}
                          {Boolean(haveLocation) && (
                            <CustomCol
                              xs={12}
                              sm={12}
                              md={12}
                              className={
                                Boolean(haveBoat) ? "location-boat" : ""
                              }
                            >
                              <TitleSubSection>Location</TitleSubSection>
                              {job.boatLocation.street && (
                                <p>{job.boatLocation.street || ""}</p>
                              )}
                              {job.boatLocation.city ||
                                (job.boatLocation.state && (
                                  <p>
                                    {job.boatLocation.city || ""},{" "}
                                    {job.boatLocation.state || ""}
                                  </p>
                                ))}
                            </CustomCol>
                          )}
                        </CustomCol>
                        <CustomCol xs={12} sm={6} md={6}>
                          {location && (
                            <a
                              href={googleMapUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Map src={googleStaticMap} />
                            </a>
                          )}
                        </CustomCol>
                      </Row>
                    )}
                    {Boolean(job.notes) && (
                      <Row className={`customer_notes`}>
                        <CustomCol xs={12} className="mt-4">
                          <TitleSubSection>Customer Notes</TitleSubSection>
                          <p>{job.notes}</p>
                        </CustomCol>
                      </Row>
                    )}
                  </DescriptionWrapper>
                )}
                {Boolean(
                  job.state &&
                    job.state !== "accepted" &&
                    job.state !== "declined"
                ) && (
                  <FooterContainer className="border-none">
                    <BlackButton
                      key={"accept_job"}
                      className="document_button mt-4"
                      onClick={() => this.jobTransition("accept")}
                    >
                      ACCEPT
                    </BlackButton>
                    <HollowButton
                      key={"decline_job"}
                      className="document_button  mt-4"
                      onClick={() => this.jobTransition("decline")}
                    >
                      DECLINE
                    </HollowButton>
                  </FooterContainer>
                )}
              </ContentInfoItems>

              <PoweredByContent>
                <PoweredBy src={PoweredByImage} />
              </PoweredByContent>
            </ContainerWrapper>
          </ContentWrapper>
        )}
        {loading && (
          <LoadingSpinner style={{ position: "fixed" }} loading={loading} />
        )}
      </Wrapper>
    );
  }
}

export default withRouter(Job);
