import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
// import { capitalize } from 'lodash';
import { formatCard, formatTwoDigitsMonth } from 'helpers';   

import CheckedMarker from 'resources/checked_marker.png'

export const Wrapper = styled.div`
  height: 35px;
  margin-bottom: 15px;
  margin-top: 25px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const CardInfo = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-family: 'Source Sans', sans-serif;
  color: #8f8f8f;
  display: flex;
  align-items: center;
  &.selected {
    color: #003247;
  }
  padding-left: 20px;
`

export const RadioButton = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;
  &::before {
    display: block;
    box-sizing: border-box;
    content: '';
    width: 16px;
    height: 16px;
    border: 1px solid #003247;
    border-radius: 8px;
  }
  &.active::after {
    position: absolute;
    display: block; 
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 4.5px;
    background-color: #003247;
    top: 3px;
    left: 3px;
  }
`;

const ExpirationInfo = styled.div`
  color: #898889;
  font-size: 12px;
  opacity: 1;
  margin-left: 10px;
  font-family: "Source Sans", sans-serif !important;
  text-align: left;
  &.one_row{
    position: absolute;
    margin-top: 40px;
    margin-left: 36px;
    opacity: 0.7;
  }
`;

const MarkerImg = styled.img`
  width: 23px;
  height: 20px;
  margin-left: 20px;
`

export default ({ creditCard: { id, attributes: { name, last4, expirationMonth, expirationYear, isDefault } }, isSelected, onSelect, oneRowExp }) => (
  <Wrapper className="credit_card_option" onClick={() => onSelect(id)} >
    <RadioButton onClick={() => onSelect(id)} className={classNames({ active: isSelected })}/>
    <CardInfo className={classNames({ selected: isSelected })}>
      {formatCard(name)} xxxx{last4}
    </CardInfo>
    <ExpirationInfo className={classNames({ "one_row": oneRowExp })}>
      Exp {formatTwoDigitsMonth(expirationMonth)}/{expirationYear}
    </ExpirationInfo>
    {isDefault && <MarkerImg src={CheckedMarker} />}
  </Wrapper>
)