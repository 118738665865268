import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { startCase } from 'lodash';

import { MessageItem, TemplateItem } from 'components/basic/Message';

const Wrapper = styled.div``;

class TemplateSelector extends React.Component {
  state = {
    selected: ''
  };

  getTemplates = () => {
    const { privilege, globalTemplates, localTemplates } = this.props;
    if (privilege === 'admin') {
      return globalTemplates;
    }
    return localTemplates;
    //return globalTemplates.filter((e) => e.triggerKey === 'invoice_for_customer' || e.triggerKey === 'new_quote_available' )
  };

  renderTemplates = () => {
    const { onSelect, providerLocationId } = this.props;
    const { selected } = this.state;
    const templates = this.getTemplates();

    return templates.map(template => {
      const { triggerKey, subject } = template;
      let itemStyles = {};
      if(providerLocationId && !(triggerKey === "new_quote_available" || triggerKey === "invoice_for_customer")){
        itemStyles = { display: 'none' };
      }

      return (<MessageItem
        onClick={() => {
          this.setState({ selected: triggerKey });
          if (onSelect) {
            onSelect(triggerKey);
          }
        }}
        style={itemStyles}
        className={selected === triggerKey ? 'active' : 'detactive'}
        key={triggerKey}
      >
        <TemplateItem
          title={startCase(triggerKey)}
          description={subject}
        />
      </MessageItem>);
    });
  };

  render() {
    return (
      <Wrapper>
        {this.renderTemplates()}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTemplates: state.messageTemplate.globalTemplates,
  localTemplates: state.messageTemplate.localTemplates,
  privilege: state.auth.privilege,
  providerLocationId: state.auth.providerLocationId
})

export default connect(mapStateToProps)(TemplateSelector);