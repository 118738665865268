import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Form, Field } from "react-final-form";
import { Row, Col } from "react-flexbox-grid";
import { get, isEmpty } from "lodash";
import { toastr } from "react-redux-toastr";

import { apiBaseUrl } from "api/config";
import LoadingSpinner from "components/basic/LoadingSpinner";
import MMLogo from "resources/boatshow/mm_color_logo.png";
import { IntercomAPI } from "components/basic/Intercom";
import {
  Wrapper,
  FormContainer,
  ContentWrapper,
  ContainerWrapper,
  Img,
  TitleLabel,
  DescLabel,
  SectionWrapper,
  SectionContainer,
  SectionTitle,
  InputRow,
  InputField,
  MaskInput,
  Footer,
  FooterActionWrapper,
  ActionButton,
  Error,
  required,
  emailValidation
} from "./BoatHoldDeposit";

import { phoneValidation } from './../BoatHold';
import TagManager from 'react-gtm-module';

class BoatHoldFree extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cost: 0,
      currentWidth: 1000,
      currentHeight: 0,
      tagManagerArgs: {},
      origin: window.location.pathname.includes("web")
        ? "web"
        : "onlineexperience"
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    if(window.location.pathname.includes("/web")){
      const queryParams = queryString.parse(this.props.location.search);
      const tagManagerArgs = {
        dataLayer: {
          ...queryParams,
          page: "boatFree"
        },
        dataLayerName: 'MarineMax'
      };
      this.setState({ tagManagerArgs });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    const currentWidth = window.innerWidth;
    const currentHeight = window.innerHeight;
    this.setState({ currentWidth, currentHeight });
  };

  openChat = () => {
    IntercomAPI("show");
  };

  handleSubmit = values => {
    const { origin } = this.state;
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && !isEmpty(queryParams)) {
      this.setState({ loading: true });
      const yacht = queryParams.yacht
        ? Boolean(queryParams.yacht)
        : queryParams.isYacht
          ? Boolean(parseInt(queryParams.isYacht))
          : false;

      let params = {
        reservation: {
          ...queryParams,
          yacht,
          cost: this.state.cost,
          zip: "32006", //added to fix an api error, remove later
          ...values
        }
      };

      if (origin === "web") {
        params.reservation.lead_source_details = "boat_hold";
      }

      axios
        .post(`${apiBaseUrl}/reservations`, params)
        .then(() => {
          this.setState({ loading: false });
          this.props.history.push(`/${origin}/boat-hold-done`);
        })
        .catch(e => {
          this.setState({ loading: false });
          const errorMessage = get(e.response, "data.message");
          if (errorMessage) {
            toastr.error("Error", get(e.response, "data.message"));
          } else {
            if (e.response && e.response.data && !isEmpty(e.response.data)) {
              for (let error in e.response.data) {
                for (let i in e.response.data[error]) {
                  toastr.error("Error", e.response.data[error][i]);
                }
              }
            }
          }
        });
    } else {
      toastr.error("Error", "Missing parameters");
    }
  };

  render() {
    const { loading, currentWidth, currentHeight, tagManagerArgs } = this.state;

    if(origin === "web" && tagManagerArgs.dataLayer){
      TagManager.dataLayer(tagManagerArgs);
    }

    return (
      <Wrapper height={800} currentHeight={currentHeight}>
        <ContentWrapper height={700}>
          <ContainerWrapper>
            <Form
              onSubmit={this.handleSubmit}
              render={({ handleSubmit, submitting }) => (
                <FormContainer onSubmit={handleSubmit}>
                  <Img src={MMLogo} />
                  <TitleLabel>{"Almost There!"}</TitleLabel>
                  <DescLabel>
                    {
                      <div>
                        <div>
                          Enter your information below to reserve years of
                          memorable experiences on the water. A MarineMax sales
                          consultant will contact you to confirm the
                          availability of your boat and guide you
                          {currentWidth <= 600 &&
                            " through your purchase options."}
                        </div>
                        {currentWidth > 600 && (
                          <div className="last-row">
                            through your purchase options.
                          </div>
                        )}
                      </div>
                    }
                  </DescLabel>
                  <SectionWrapper>
                    <SectionContainer>
                      <SectionTitle>{"Contact"}</SectionTitle>
                      <Row>
                        <Col xs={12} sm={6} md={6}>
                          <InputRow>
                            <InputField
                              name="first_name"
                              component="input"
                              placeholder="First Name"
                              validate={required}
                            />
                            <Error name="first_name" />
                          </InputRow>
                        </Col>
                        <Col xs={12} sm={6} md={6}>
                          <InputRow>
                            <InputField
                              name="last_name"
                              component="input"
                              placeholder="Last Name"
                              validate={required}
                            />
                            <Error name="last_name" />
                          </InputRow>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={6} md={6}>
                          <InputRow>
                            <Field name="phone" validate={phoneValidation}>
                              {props => (
                                <div>
                                  <MaskInput
                                    {...props.input}
                                    placeholder="Phone"
                                    mask={"(999) 999-9999"}
                                  />
                                </div>
                              )}
                            </Field>
                            <Error name="phone" />
                          </InputRow>
                        </Col>
                        <Col xs={12} sm={6} md={6}>
                          <InputRow>
                            <InputField
                              name="email"
                              type="email"
                              component="input"
                              placeholder="Email"
                              validate={emailValidation}
                            />
                            <Error name="email" />
                          </InputRow>
                        </Col>
                      </Row>
                    </SectionContainer>
                  </SectionWrapper>
                  <Footer>
                    <SectionWrapper>
                      <SectionContainer>
                        <FooterActionWrapper>
                          <ActionButton type="submit" disabled={submitting}>
                            {"Reserve My Boat"}
                          </ActionButton>
                        </FooterActionWrapper>
                      </SectionContainer>
                    </SectionWrapper>
                  </Footer>
                </FormContainer>
              )}
            />
          </ContainerWrapper>
        </ContentWrapper>
        {loading && (
          <LoadingSpinner style={{ position: "fixed" }} loading={loading} />
        )}
      </Wrapper>
    );
  }
}

export default withRouter(BoatHoldFree);
