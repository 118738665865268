import { handleActions } from 'redux-actions';
import { produce } from 'immer';
import { get } from 'lodash';
import { actionTypes } from '../actions/contractors';

const initialState = {
  currentTeamStatus: '',
  teamErrors: null,
  teamContractors: [],
  currentTeamContractor: {},

  currentStatus: '',
  contractors: [],
  tags: [],
  currentContractor: {},
  errors: null,
  page: 1,
  perPage: 10,
  total: 0
};


export default handleActions(
  {
    [actionTypes.GET_TEAM_CONTRACTORS]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentTeamStatus = type;
        draft.teamPage = get(payload, 'params.page', 1);
        draft.TeamErrors = null;
      }),
    [actionTypes.GET_TEAM_CONTRACTORS_SUCCESS]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentTeamStatus = type;
        draft.teamContractors = payload;
      }),
    [actionTypes.GET_TEAM_CONTRACTORS_FAILURE]: (state, action) =>
      produce(state, draft => {
        const { type, payload: {errors} } = action;
        draft.currentTeamStatus = type;
        draft.teamErrors = errors;
      }),

    [actionTypes.GET_TEAM_CONTRACTOR]: (state, action) =>
      produce(state, draft => {
        const { type } = action;
        draft.currentTeamStatus = type;
        draft.teamErrors = null;
      }),
    [actionTypes.GET_TEAM_CONTRACTOR_SUCCESS]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentTeamStatus = type;
        draft.currentTeamContractor = payload;
      }),
    [actionTypes.GET_TEAM_CONTRACTOR_FAILURE]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentTeamStatus = type;
        draft.teamErrors = payload;
      }),

    [actionTypes.CREATE_TEAM_CONTRACTOR]: (state, action) =>
      produce(state, draft => {
        const { type } = action;
        draft.currentTeamStatus = type;
        draft.teamErrors = null;
      }),
    [actionTypes.CREATE_TEAM_CONTRACTOR_SUCCESS]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentTeamStatus = type;
        draft.currentTeamContractor = payload;
      }),
    [actionTypes.CREATE_TEAM_CONTRACTOR_FAILURE]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentTeamStatus = type;
        draft.teamErrors = payload;
      }),

    [actionTypes.UPDATE_TEAM_CONTRACTOR]: (state, action) =>
      produce(state, draft => {
        const { type } = action;
        draft.currentTeamStatus = type;
        draft.teamErrors = null;
      }),
    [actionTypes.UPDATE_TEAM_CONTRACTOR_SUCCESS]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentTeamStatus = type;
        draft.currentTeamContractor = payload;
      }),
    [actionTypes.UPDATE_TEAM_CONTRACTOR_FAILURE]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentTeamStatus = type;
        draft.teamErrors = payload;
      }),

    [actionTypes.DELETE_TEAM_CONTRACTOR]: (state, action) =>
      produce(state, draft => {
        const { type } = action;
        draft.currentTeamStatus = type;
        draft.teamErrors = null;
      }),
    [actionTypes.DELETE_TEAM_CONTRACTOR_SUCCESS]: (state, action) =>
      produce(state, draft => {
        const { type } = action;
        draft.currentTeamStatus = type;
      }),
    [actionTypes.DELETE_TEAM_CONTRACTOR_FAILURE]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentTeamStatus = type;
        draft.teamErrors = payload;
      }),

      
    [actionTypes.GET_CONTRACTORS]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentStatus = type;
        draft.page = get(payload, 'params.page', 1);
        draft.errors = null;
      }),
    [actionTypes.GET_CONTRACTORS_SUCCESS]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        const { total, perPage, contractors } = payload;
        draft.currentStatus = type;
        draft.total = total;
        draft.perPage = perPage;
        draft.contractors = contractors;
      }),
    [actionTypes.GET_CONTRACTORS_FAILURE]: (state, action) =>
      produce(state, draft => {
        const { type, payload: {errors} } = action;
        draft.currentStatus = type;
        draft.errors = errors;
      }),
    [actionTypes.GET_TAGS]: (state, action) =>
      produce(state, draft => {
        const { type } = action;
        draft.currentStatus = type;
        draft.errors = null;
      }),
      [actionTypes.GET_TAGS_SUCCESS]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentStatus = type;
        draft.tags = payload;
      }),
    [actionTypes.GET_TAGS_FAILURE]: (state, action) =>
      produce(state, draft => {
        const { type, payload: {errors} } = action;
        draft.currentStatus = type;
        draft.errors = errors;
      }),
    [actionTypes.GET_CONTRACTOR]: (state, action) =>
      produce(state, draft => {
        const { type } = action;
        draft.currentStatus = type;
        draft.errors = null;
      }),
    [actionTypes.GET_CONTRACTOR_SUCCESS]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentStatus = type;
        draft.currentContractor = payload;
      }),
    [actionTypes.GET_CONTRACTOR_FAILURE]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentContractor = {};
        draft.currentStatus = type;
        draft.errors = payload;
      }),

    [actionTypes.CREATE_CONTRACTOR]: (state, action) =>
      produce(state, draft => {
        const { type } = action;
        draft.currentStatus = type;
        draft.errors = null;
      }),
    [actionTypes.CREATE_CONTRACTOR_SUCCESS]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentStatus = type;
        draft.currentContractor = payload;
      }),
    [actionTypes.CREATE_CONTRACTOR_FAILURE]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentStatus = type;
        draft.errors = payload;
      }),

    [actionTypes.UPDATE_CONTRACTOR]: (state, action) =>
      produce(state, draft => {
        const { type } = action;
        draft.currentStatus = type;
        draft.errors = null;
      }),
    [actionTypes.UPDATE_CONTRACTOR_SUCCESS]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentStatus = type;
        draft.currentContractor = payload;
      }),
    [actionTypes.UPDATE_CONTRACTOR_FAILURE]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentStatus = type;
        draft.errors = payload;
      }),

    [actionTypes.DELETE_CONTRACTOR]: (state, action) =>
      produce(state, draft => {
        const { type } = action;
        draft.currentStatus = type;
        draft.errors = null;
      }),
    [actionTypes.DELETE_CONTRACTOR_SUCCESS]: (state, action) =>
      produce(state, draft => {
        const { type } = action;
        draft.currentStatus = type;
      }),
    [actionTypes.DELETE_CONTRACTOR_FAILURE]: (state, action) =>
      produce(state, draft => {
        const { type, payload } = action;
        draft.currentStatus = type;
        draft.errors = payload;
      })
    },
  initialState
);
