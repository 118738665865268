import { createAction } from 'redux-actions';

export const actionTypes = {
  GET_TEAM_CONTRACTORS: '[CONTRACTORS] - Get team contractors',
  GET_TEAM_CONTRACTORS_SUCCESS: '[CONTRACTORS] - Get team contractors success',
  GET_TEAM_CONTRACTORS_FAILURE: '[CONTRACTORS] - Get team contractors failure',

  GET_TEAM_CONTRACTOR: '[CONTRACTORS] - Get team contractor',
  GET_TEAM_CONTRACTOR_SUCCESS: '[CONTRACTORS] - Get team contractor success',
  GET_TEAM_CONTRACTOR_FAILURE: '[CONTRACTORS] - Get team contractor failure',

  CREATE_TEAM_CONTRACTOR: '[CONTRACTORS] - Create team contractor',
  CREATE_TEAM_CONTRACTOR_SUCCESS: '[CONTRACTORS] - Create team contractor success',
  CREATE_TEAM_CONTRACTOR_FAILURE: '[CONTRACTORS] - Create team contractor failure',

  UPDATE_TEAM_CONTRACTOR: '[CONTRACTORS] - Update team contractor',
  UPDATE_TEAM_CONTRACTOR_SUCCESS: '[CONTRACTORS] - Update team contractor success',
  UPDATE_TEAM_CONTRACTOR_FAILURE: '[CONTRACTORS] - Update team contractor failure',

  DELETE_TEAM_CONTRACTOR: '[CONTRACTORS] - Delete team contractor',
  DELETE_TEAM_CONTRACTOR_SUCCESS: '[CONTRACTORS] - Delete team contractor success',
  DELETE_TEAM_CONTRACTOR_FAILURE: '[CONTRACTORS] - Delete team contractor failure',

  GET_CONTRACTORS: '[CONTRACTORS] - Get contractors',
  GET_CONTRACTORS_SUCCESS: '[CONTRACTORS] - Get contractors success',
  GET_CONTRACTORS_FAILURE: '[CONTRACTORS] - Get contractors failure',

  GET_TAGS: '[CONTRACTORS] - Get tags',
  GET_TAGS_SUCCESS: '[CONTRACTORS] - Get tags success',
  GET_TAGS_FAILURE: '[CONTRACTORS] - Get tags failure',

  GET_CONTRACTOR: '[CONTRACTORS] - Get contractor',
  GET_CONTRACTOR_SUCCESS: '[CONTRACTORS] - Get contractor success',
  GET_CONTRACTOR_FAILURE: '[CONTRACTORS] - Get contractor failure',

  CREATE_CONTRACTOR: '[CONTRACTORS] - Create contractor',
  CREATE_CONTRACTOR_SUCCESS: '[CONTRACTORS] - Create contractor success',
  CREATE_CONTRACTOR_FAILURE: '[CONTRACTORS] - Create contractor failure',

  UPDATE_CONTRACTOR: '[CONTRACTORS] - Update contractor',
  UPDATE_CONTRACTOR_SUCCESS: '[CONTRACTORS] - Update contractor success',
  UPDATE_CONTRACTOR_FAILURE: '[CONTRACTORS] - Update contractor failure',

  DELETE_CONTRACTOR: '[CONTRACTORS] - Delete contractor',
  DELETE_CONTRACTOR_SUCCESS: '[CONTRACTORS] - Delete contractor success',
  DELETE_CONTRACTOR_FAILURE: '[CONTRACTORS] - Delete contractor failure',
};

export const GetTeamContractors = createAction(actionTypes.GET_TEAM_CONTRACTORS, payload => payload);
export const GetTeamContractorsSuccess = createAction(actionTypes.GET_TEAM_CONTRACTORS_SUCCESS, payload => payload);
export const GetTeamContractorsFailure = createAction(actionTypes.GET_TEAM_CONTRACTORS_FAILURE, payload => payload);

export const GetTeamContractor = createAction(actionTypes.GET_TEAM_CONTRACTOR, payload => payload);
export const GetTeamContractorSuccess = createAction(actionTypes.GET_TEAM_CONTRACTOR_SUCCESS);
export const GetTeamContractorFailure = createAction(actionTypes.GET_TEAM_CONTRACTOR_FAILURE);

export const CreateTeamContractor = createAction(actionTypes.CREATE_TEAM_CONTRACTOR, payload => payload);
export const CreateTeamContractorSuccess = createAction(actionTypes.CREATE_TEAM_CONTRACTOR_SUCCESS);
export const CreateTeamContractorFailure = createAction(actionTypes.CREATE_TEAM_CONTRACTOR_FAILURE);

export const UpdateTeamContractor = createAction(actionTypes.UPDATE_TEAM_CONTRACTOR, payload => payload);
export const UpdateTeamContractorSuccess = createAction(actionTypes.UPDATE_TEAM_CONTRACTOR_SUCCESS);
export const UpdateTeamContractorFailure = createAction(actionTypes.UPDATE_TEAM_CONTRACTOR_FAILURE);

export const DeleteTeamContractor = createAction(actionTypes.DELETE_TEAM_CONTRACTOR, payload => payload);
export const DeleteTeamContractorSuccess = createAction(actionTypes.DELETE_TEAM_CONTRACTOR_SUCCESS);
export const DeleteTeamContractorFailure = createAction(actionTypes.DELETE_TEAM_CONTRACTOR_FAILURE);

export const GetContractors = createAction(actionTypes.GET_CONTRACTORS, payload => payload);
export const GetContractorsSuccess = createAction(actionTypes.GET_CONTRACTORS_SUCCESS, payload => payload);
export const GetContractorsFailure = createAction(actionTypes.GET_CONTRACTORS_FAILURE, payload => payload);

export const GetTags = createAction(actionTypes.GET_TAGS, payload => payload);
export const GetTagsSuccess = createAction(actionTypes.GET_TAGS_SUCCESS, payload => payload);
export const GetTagsFailure = createAction(actionTypes.GET_TAGS_FAILURE, payload => payload);

export const GetContractor = createAction(actionTypes.GET_CONTRACTOR, payload => payload);
export const GetContractorSuccess = createAction(actionTypes.GET_CONTRACTOR_SUCCESS);
export const GetContractorFailure = createAction(actionTypes.GET_CONTRACTOR_FAILURE);

export const CreateContractor = createAction(actionTypes.CREATE_CONTRACTOR, payload => payload);
export const CreateContractorSuccess = createAction(actionTypes.CREATE_CONTRACTOR_SUCCESS);
export const CreateContractorFailure = createAction(actionTypes.CREATE_CONTRACTOR_FAILURE);

export const UpdateContractor = createAction(actionTypes.UPDATE_CONTRACTOR, payload => payload);
export const UpdateContractorSuccess = createAction(actionTypes.UPDATE_CONTRACTOR_SUCCESS);
export const UpdateContractorFailure = createAction(actionTypes.UPDATE_CONTRACTOR_FAILURE);

export const DeleteContractor = createAction(actionTypes.DELETE_CONTRACTOR, payload => payload);
export const DeleteContractorSuccess = createAction(actionTypes.DELETE_CONTRACTOR_SUCCESS);
export const DeleteContractorFailure = createAction(actionTypes.DELETE_CONTRACTOR_FAILURE);
