import React from 'react';

import MainRoutes from './mainRoutes';

class AppRoutes extends React.Component {
  render() {
    return <MainRoutes />;
  }
}

export default AppRoutes;
