import React from 'react';

import {
  SectionHeaderWrapper,
  LeftPart,
  RightPart
} from 'components/basic/Header';
import { HollowButton, OrangeButton } from 'components/basic/Buttons';
import { PageTitle } from 'components/basic/Typho';

export const CustomerDetailsHeader = ({ name, onDelete, onNewOrder }) => (
  <SectionHeaderWrapper>
    <LeftPart>
      <PageTitle>{name}</PageTitle>
      <HollowButton onClick={onDelete}>
        Delete
      </HollowButton>
    </LeftPart>
    <RightPart>
      <OrangeButton className="desktop" onClick={onNewOrder}>
        New Order
      </OrangeButton>
    </RightPart>
  </SectionHeaderWrapper>
);
