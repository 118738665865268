import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { toastr } from "react-redux-toastr";
import { get } from "lodash";
import queryString from "query-string";

import { HollowButton, OrangeButton } from "components/basic/Buttons";
import { NormalText } from 'components/basic/Typho'
import ContractorDetailsHeader from "../components/ContractorDetailsHeader";
import CompanyInfo from "../components/CompanyInfo";
import ContactInfo from "../components/ContactInfo";
import TagsInfo from "../components/TagsInfo";
import LoadingSpinner from "components/basic/LoadingSpinner";
import Modal from "components/compound/Modal";
import { formatPhoneNumber } from "utils/basic";

import {
  GetContractor,
  CreateContractor,
  UpdateContractor,
  DeleteContractor,
  GetTags
} from "store/actions/contractors";

const EditorWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  box-sizing: border-box;
  margin: 27px 24px;
  padding: 38px 77px 26px;
  @media (max-width: 900px) {
    padding: 30px 50px 26px;
  }
  @media (max-width: 600px) {
    padding: 25px;
  }
`;

const EditorContent = styled.div`
  border-bottom: 1px solid #dfdfdf;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 24px;
`;

export const Description = styled(NormalText)`
  font-family: 'Open sans-serif', sans-serif;
  padding: 10px 0;
`;

class ContractorDetails extends React.Component {
  state = {
    deleteConfirmationModal: false,
    contractorId: null,
    saving: false,
    edit: false
  };

  componentDidMount() {
    this.props.GetTags({});
    const { id } = queryString.parse(this.props.location.search);

    if (id) {
      this.setState({ contractorId: id, edit: true }, () => {
        this.loadContractor();
      });
    }
  }

  loadContractor() {
    const { GetContractor } = this.props;
    const { contractorId } = this.state;
    GetContractor({
      contractorId
    });
  }

  getDefaultCompanyInfo = () => {
    const { contractor } = this.props;
    const { contractorId } = this.state;

    if (!contractorId) {
      return {
        name: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        websiteUrl: "",
        description: ""
      };
    } else {
      const websiteUrl = get(contractor, "websiteUrl", "");
      const name = get(contractor, "companyName", "");
      const street = get(contractor, "address", "");
      const city = get(contractor, "city", "");
      const state = get(contractor, "state", "");
      const zip = get(contractor, "zip", "");
      const serviceType = get(contractor, "serviceType", "contractor");
      const description = get(contractor, "description", "");
      return {
        name,
        street,
        city,
        state,
        zip,
        websiteUrl,
        serviceType,
        description
      };
    }
  };

  getDefaultContactInfo = () => {
    const { contractor } = this.props;
    const { contractorId } = this.state;

    if (!contractorId) {
      return {
        contactName: "",
        contactEmail: "",
        contactPhone: ""
      };
    } else {
      const contactName = get(contractor, "name", "");
      const contactEmail = get(contractor, "email", "");
      const contactPhone = get(contractor, "phoneNumber", "");
      return {
        contactName,
        contactEmail,
        contactPhone: formatPhoneNumber(contactPhone)
      };
    }
  };

  getSelectedTags = () => {
    const { contractor } = this.props;
    const { contractorId } = this.state;

    if (!contractorId) {
      return [];
    } else {
      const tags = get(contractor, "tags", []);
      return tags;
    }
  };

  createContractor = () => {
    const companyInfo = this.companyFields.getFieldValues();
    const contactInfo = this.contactFields.getFieldValues();
    const tagsInfo = this.tagsFields ? this.tagsFields.getFieldValues() : [];
    const { CreateContractor } = this.props;
    const {
      name,
      websiteUrl,
      street,
      city,
      state,
      zip,
      serviceType,
      description
    } = companyInfo;
    const { contactName, contactPhone, contactEmail } = contactInfo;
    const params = {
      tag_ids: tagsInfo,
      name: contactName,
      email: contactEmail,
      phone_number: contactPhone,
      company_name: name,
      published: false,
      address: street,
      description,
      city,
      state,
      zip,
      service_type: serviceType,
      website_url: websiteUrl
    };
    CreateContractor({
      data: {
        provider_location_directory: params
      },
      success: response => {
        const { data: {id} } = response;
        toastr.success('Success', 'Created successfully!');
        this.setState({ saving: false, contractorId: id, edit: true });
        if(id) this.props.history.push(`/contractor-details/?id=${id}`);
      },
      error: e => {
        this.setState({ saving: false });
        toastr.error("Error", e.message);
      }
    });
  };

  updateContractor = (toPublish = null, deleteAccount = false) => {
    const companyInfo = this.companyFields.getFieldValues();
    const contactInfo = this.contactFields.getFieldValues();
    const tagsInfo =  this.tagsFields ? this.tagsFields.getFieldValues() : [];
    const { UpdateContractor, contractor } = this.props;
    const { contractorId } = this.state;
    const {
      name,
      websiteUrl,
      street,
      city,
      state,
      zip,
      serviceType,
      description
    } = companyInfo;
    const { contactName, contactPhone, contactEmail } = contactInfo;
    const params = {
      tag_ids: deleteAccount ? [] : tagsInfo,
      name: contactName,
      email: contactEmail,
      phone_number: contactPhone,
      company_name: name,
      address: street,
      description,
      city,
      state,
      zip,
      service_type: serviceType,
      website_url: websiteUrl,
      published:
        toPublish !== null
          ? Boolean(toPublish)
          : contractor && contractor.published
            ? contractor.published
            : false
    };
    UpdateContractor({
      contractorId,
      data: {
        provider_location_directory: params
      },
      success: () => {
        if(deleteAccount){
          this.deleteContractor();
        }else{
          toastr.success('Success', 'Updated successfully!');
          this.setState({ saving: false, edit: true });
        }
      },
      error: e => {
        this.setState({ saving: false });
        toastr.error("Error", e.message);
      }
    });
  };

  onBack = () => {
    this.props.history.push(`/contractors/`);
  }

  deleteContractor = () => {
    const { DeleteContractor } = this.props;
    const { contractorId } = this.state;

    this.hideConfirmationModal();
    this.setState({ saving: true });

    DeleteContractor({
      contractorId,
      success: () => {
        toastr.success('Success', 'Deleted successfully!');
        this.onBack();
      },
      error: e => {
        this.setState({ saving: false,  });
        toastr.error("Error", e.message);
      }
    });
  }

  saveData = () => {
    const { edit } = this.state;
    const { contractor } = this.props;
    const isCompanyValid = this.companyFields.validateFields();
    const isContactValid = this.contactFields.validateFields();

    if (isCompanyValid && isContactValid) {
      this.setState({ saving: true });
      if (edit && contractor && contractor.id) {
        this.updateContractor();
      } else {
        this.createContractor();
      }
    }
  };

  setCompanyFieldsRef = ref => {
    this.companyFields = ref;
  };

  setContactFieldsRef = ref => {
    this.contactFields = ref;
  };

  setTagsFieldsRef = ref => {
    this.tagsFields = ref;
  };

  showConfirmationModal = () => {
    this.setState({deleteConfirmationModal: true});
  }

  hideConfirmationModal = () => {
    this.setState({deleteConfirmationModal: false});
  }

  handlePublish = () => {
    const { contractor } = this.props;
    const { contractorId } = this.state;

    if (contractorId && contractor.id) {
      this.setState({ saving: true });
      this.updateContractor(!contractor.published);
    }
  };

  render() {
    const { saving, edit, deleteConfirmationModal } = this.state;
    const { tags, contractor, locationName } = this.props;

    const modalActions = [
      <HollowButton onClick={this.hideConfirmationModal} key="modal_btn_cancel">
        Cancel
      </HollowButton>,
      <OrangeButton onClick={() => this.updateContractor(null, true)} key="modal_btn_save">
        Confirm
      </OrangeButton>
    ];

    const companyInfo = this.getDefaultCompanyInfo();
    const contactInfo = this.getDefaultContactInfo();
    const selectedTags = this.getSelectedTags();

    return (
      <Fragment>
        <ContractorDetailsHeader
          edit={edit}
          onDelete={this.showConfirmationModal}
          contractor={contractor}
          canPublish={Boolean(locationName === "LMC")}
          onPublish={this.handlePublish}
        />
        <EditorWrapper>
          <EditorContent>
            <CompanyInfo
              ref={this.setCompanyFieldsRef}
              defaultValues={companyInfo}
              locationName={locationName}
            />
            <ContactInfo
              ref={this.setContactFieldsRef}
              defaultValues={contactInfo}
            />
            { Boolean(tags && tags.length !== 0) &&
              <TagsInfo
                tags={tags}
                ref={this.setTagsFieldsRef}
                defaultValues={selectedTags}
              />
            }
          </EditorContent>
          <ButtonWrapper>
            <OrangeButton onClick={this.saveData}>SAVE</OrangeButton>
          </ButtonWrapper>
        </EditorWrapper>
        <Modal
          title={"Are You Sure?"}
          actions={modalActions}
          normal={true}
          open={deleteConfirmationModal}
          onClose={this.hideConfirmationModal}
        >
          <Description>
            Deleting{" "}
            this contractor is permanent and cannot be undone.
          </Description>
        </Modal>
        {saving && <LoadingSpinner loading={saving} />}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentStatus: state.contractor.currentStatus,
  contractor: state.contractor.currentContractor,
  tags: state.contractor.tags,
  locationName: state.auth.locationName
});

const mapDispatchToProps = {
  GetContractor,
  CreateContractor,
  UpdateContractor,
  DeleteContractor,
  GetTags
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContractorDetails)
);
