import _ from "lodash";

export const getProviderPrimaryLocation = provider => {
  const locationRelationship = _.get(
    provider,
    "relationships.primaryLocation.data"
  );
  const included = _.get(provider, "included");
  const locationType = _.get(locationRelationship, "type");
  const locationId = _.get(locationRelationship, "id");
  const location = _.find(
    included,
    item => item.type === locationType && item.id === locationId
  );
  return location;
};

export const marineMaxTagManagerScript = (
  w = window,
  d = document,
  s = "script",
  l = "dataLayer",
  i = ""
) => {
  w[l] = w[l] || [];
  w[l].push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js"
  });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l !== "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
  f.parentNode.insertBefore(j, f);
};