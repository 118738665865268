import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Col, Row } from "react-flexbox-grid";
import { get, isEmpty } from "lodash";
import { toastr } from "react-redux-toastr";
import { BlackButton } from "components/basic/Buttons";
import { cdnBaseUrl } from "api/config";
import LoadingSpinner from "components/basic/LoadingSpinner";
import PoweredByImage from "resources/powered_by.png";
import PrintIcon from "resources/print_icon.png";
import { formatCurrency } from "utils/basic";
import InvoicePaymentMethod from "components/template/CreditCardSection/InvoicePaymentMethod";
import {
  CustomCol,
  Wrapper,
  ContentWrapper,
  ContainerWrapper,
  ContentHeader,
  ContentInfo,
  ContentInfoItems,
  ItemsWrapper,
  TotalsWrapper,
  TitleSection,
  Logo,
  PoweredBy,
  PoweredByContent,
  PrintButton,
  Icon,
  FooterContainer,
  getOrderInfo,
  getCreditCards,
  createCreditCard,
  createPayment,
  handleImageError,
  TitleSubSection
} from "./index";

class Invoice extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      paid: false,
      openPayment: false,
      loadingCards: true,
      orderId: 0,
      order: {
        provider: {
          address: {}
        },
        user: {},
        boat: {},
        lineItems: []
      },
      creditCards: [],
      selectedCardId: -1,
      token: ""
    };
  }

  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && !isEmpty(queryParams)) {
      getOrderInfo(queryParams)
        .then(order => {
          this.setState(
            {
              order,
              token: order.token,
              orderId: queryParams.order_id,
              loading: false
            },
            () => {
              getCreditCards(order.token)
                .then(creditCards => {
                  this.setState({ creditCards });
                })
                .catch(e => toastr.error("Error", "Missing parameters"));
            }
          );
        })
        .catch(e => toastr.error("Error", "Missing parameters"));
    } else {
      this.setState({ loading: true }, () => {
        setTimeout(() => toastr.error("Error", "Missing parameters"), 200);
      });
    }
  }

  handleChangeCreditCard = id => {
    this.setState({ selectedCardId: id });
  };

  handleSaveSelection = () => {
    const { token, selectedCardId, orderId, order } = this.state;
    const params = {
      token,
      data: {
        order_id: parseInt(orderId),
        credit_card_id: selectedCardId,
        amount: parseFloat(order.balance || 0)
      }
    };
    this.setState({ loading: true }, () => {
      createPayment(params)
        .then(response => {
          const paymentStatus = get(
            response,
            "data.data.attributes.state",
            "failed"
          );
          if (paymentStatus === "failed") {
            this.setState({ loading: false, openPayment: false }, () => {
              toastr.error("Error", "The order payment was declined.");
            });
          } else {
            this.setState({ loading: false, paid: true, openPayment: true });
          }
        })
        .catch(e => {
          this.setState({ loading: false });
          toastr.error("Error", "Something went wrong. Try again later.");
          console.warn(e);
        });
    });
  };

  handleCreateCreditCard = data => {
    const { token } = this.state;
    const params = {
      token,
      data: {
        is_default: true,
        ...data
      }
    };
    this.setState({ loading: true }, () => {
      createCreditCard(params)
        .then(response => {
          const creditCardId = get(response, "data.data.id", null);
          if (creditCardId) {
            this.setState({ selectedCardId: creditCardId }, () => {
              this.handleSaveSelection();
            });
          } else {
            this.setState({ loading: false });
            toastr.error("Error", "Something went wrong. Try again later.");
          }
        })
        .catch(e => {
          this.setState({ loading: false });
          toastr.error("Error", "Invalid Card");
          console.warn(e);
        });
    });
  };

  showPaymentModal = () => {
    this.setState({ openPayment: !this.state.openPayment });
  };

  render() {
    const { loading, openPayment, creditCards, order, paid } = this.state;
    const LogoImg = order.provider.id
      ? `${cdnBaseUrl}provider/logo/${order.provider.id}/logo.png`
      : "";
    const canPay = Boolean(
      order.balance && parseInt(order.balance) !== 0 && !paid
    );

    const amountPaid =
      order.total && order.balance
        ? parseFloat(order.total) - parseFloat(order.balance)
        : 0;

    return (
      <Wrapper>
        <ContentWrapper>
          <ContainerWrapper>
            <ContentHeader>
              <span>invoice.</span>
              <PrintButton onClick={window.print}>
                <Icon src={PrintIcon} />
              </PrintButton>
            </ContentHeader>
            <ContentInfo>
              <Row>
                <CustomCol xs={12} sm={6} md={6} className="logoXS">
                  {LogoImg && (
                    <Logo
                      src={LogoImg}
                      className="logoXS"
                      onError={handleImageError}
                    />
                  )}
                </CustomCol>
                <CustomCol xs={12} sm={6} md={6}>
                  <TitleSection>Prepared For</TitleSection>
                  <span>
                    {order.user.first_name || ""} {order.user.last_name || ""}
                  </span>
                  <span>
                    <strong>Order</strong> #{order.orderId || order.id || ""}
                  </span>
                  <span>
                    <strong>Invoiced Date</strong> {order.updatedAt}
                  </span>
                </CustomCol>
                <CustomCol xs={12} sm={6} md={6}>
                  {LogoImg && <Logo src={LogoImg} onError={handleImageError} />}
                </CustomCol>
              </Row>

              <Row>
                <CustomCol xs={12} sm={6} md={6}>
                  <TitleSection>Boat Info</TitleSection>
                  {order.boat.name && (
                    <span>
                      <strong>Boat Name: </strong>
                      {order.boat.name || ""}
                    </span>
                  )}
                  <span>
                    <strong>Make: </strong>
                    {order.boat.make || ""}
                  </span>
                  <span>
                    <strong>Model: </strong>
                    {order.boat.model || ""}
                  </span>
                  <span>
                    <strong>Length: </strong>
                    {order.boat.length || ""}
                  </span>
                </CustomCol>
                <CustomCol xs={12} sm={6} md={6}>
                  <TitleSection className="right rightInfo">
                    {order.provider.name || ""}
                  </TitleSection>
                  <span className="right">
                    {order.provider.address.street || ""}
                  </span>
                  <span className="right">
                    {order.provider.address.city || ""}
                    {order.provider.address.state
                      ? `, ${order.provider.address.state}`
                      : ""}
                    {order.provider.address.zip
                      ? `, ${order.provider.address.zip}`
                      : ""}
                  </span>
                  <span className="right">
                    {order.provider.address.country || ""}
                  </span>
                </CustomCol>
              </Row>
            </ContentInfo>
            <ContentInfoItems>
              <ItemsWrapper>
                {order.lineItems.map((item, idx) => {
                  let currentComment = Boolean(item.comment)
                    ? item.comment
                    : "";
                  currentComment = currentComment
                    .replace(/~/g, "<br/>")
                    .replace(/\n/g, "<br/>");

                  return (
                    <Fragment key={`item_${idx}`}>
                      <Row>
                        <Col className="firstColumn" xs={3} sm={6} md={6}>
                          <TitleSection>Item</TitleSection>
                        </Col>
                        <Col xs={3} sm={2} md={2}>
                          <TitleSection>Qty</TitleSection>
                        </Col>
                        <Col xs={3} sm={2} md={2}>
                          <TitleSection>Rate</TitleSection>
                        </Col>
                        <Col xs={3} sm={2} md={2} className="lastColumn">
                          <TitleSection>Total</TitleSection>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="firstColumn" xs={3} sm={6} md={6}>
                          {item.provider_location_service && (
                            <span>
                              {item.provider_location_service.name || ""}
                            </span>
                          )}
                          {Boolean(
                            !item.provider_location_service && item.service
                          ) && <span>{item.service.name || ""}</span>}
                        </Col>
                        <Col xs={3} sm={2} md={2}>
                          <span>{item.quantity}</span>
                        </Col>
                        <Col xs={3} sm={2} md={2}>
                          <span>{formatCurrency(parseFloat(item.cost))}</span>
                        </Col>
                        <Col xs={3} sm={2} md={2} className="lastColumn">
                          <span>
                            {formatCurrency(
                              parseFloat(item.cost * item.quantity)
                            )}
                          </span>
                        </Col>
                      </Row>

                      {Boolean(item.comment) && (
                        <Row className={`instructions job_instructions`}>
                          <Col xs={12} className="mt-4">
                            <TitleSubSection>Description</TitleSubSection>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: currentComment
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                      {Boolean(
                        order.lineItems.length > 1 &&
                          idx !== order.lineItems.length - 1
                      ) && <div className="divisor service-row" />}
                    </Fragment>
                  );
                })}

                <div className="divisor service-row" />
              </ItemsWrapper>
              <TotalsWrapper>
                <Row className="container">
                  <CustomCol xs={12} sm={8} md={8} className="termsContainer">
                    <TitleSection>Payment Terms</TitleSection>
                    <span>Payment due upon receipt</span>
                  </CustomCol>
                  <CustomCol xs={12} sm={4} md={4}>
                    <Row>
                      <CustomCol className="right title" xs={6} sm={6} md={6}>
                        <span>
                          <strong>Sub Total</strong>
                        </span>
                      </CustomCol>
                      <Col className="right" xs={6} sm={6} md={6}>
                        <span>
                          {formatCurrency(
                            parseFloat(order.subTotal || order.total)
                          )}
                        </span>
                      </Col>
                    </Row>
                    {order.tax &&
                      parseFloat(order.tax) > 0 && (
                        <Row>
                          <Col className="right title" xs={6} sm={6} md={6}>
                            <span>
                              <strong>Tax</strong>
                            </span>
                          </Col>
                          <Col className="right" xs={6} sm={6} md={6}>
                            <span>{formatCurrency(parseFloat(order.tax))}</span>
                          </Col>
                        </Row>
                      )}
                    {order.deposit &&
                      parseFloat(order.deposit) > 0 && (
                        <Row>
                          <Col className="right title" xs={6} sm={6} md={6}>
                            <span>
                              <strong>Deposit</strong>
                            </span>
                          </Col>
                          <Col className="right" xs={6} sm={6} md={6}>
                            <span>
                              {formatCurrency(parseFloat(order.deposit))}
                            </span>
                          </Col>
                        </Row>
                      )}
                    {order.discount &&
                      parseFloat(order.discount) > 0 && (
                        <Row>
                          <Col className="right title" xs={6} sm={6} md={6}>
                            <span>
                              <strong>Discount</strong>
                            </span>
                          </Col>
                          <Col className="right" xs={6} sm={6} md={6}>
                            <span>
                              {formatCurrency(parseFloat(order.discount))}
                            </span>
                          </Col>
                        </Row>
                      )}
                    {amountPaid !== 0 && (
                      <Row>
                        <Col className="right title" xs={6} sm={6} md={6}>
                          <span>
                            <strong>Paid</strong>
                          </span>
                        </Col>
                        <Col className="right" xs={6} sm={6} md={6}>
                          <span>{formatCurrency(parseFloat(amountPaid))}</span>
                        </Col>
                      </Row>
                    )}
                    <Row className="last">
                      <Col className="right title" xs={6} sm={6} md={6}>
                        <span>
                          <strong>Total</strong>
                        </span>
                      </Col>
                      <Col className="right" xs={6} sm={6} md={6}>
                        <span>
                          {formatCurrency(parseFloat(order.total - amountPaid))}
                        </span>
                      </Col>
                    </Row>
                  </CustomCol>
                </Row>
              </TotalsWrapper>
              {canPay && (
                <PoweredByContent>
                  <PoweredBy src={PoweredByImage} />
                </PoweredByContent>
              )}
            </ContentInfoItems>
            {!canPay && (
              <PoweredByContent>
                <PoweredBy src={PoweredByImage} />
              </PoweredByContent>
            )}
            {canPay && (
              <FooterContainer>
                <BlackButton
                  key={"pay_invoice"}
                  className="document_button"
                  onClick={this.showPaymentModal}
                >
                  PAY INVOICE
                </BlackButton>
              </FooterContainer>
            )}
          </ContainerWrapper>
        </ContentWrapper>
        {Boolean(loading) && (
          <LoadingSpinner style={{ position: "fixed" }} loading={true} />
        )}
        <InvoicePaymentMethod
          open={openPayment}
          loading={loading}
          paid={paid}
          user={order.user}
          creditCards={creditCards}
          provider={order.provider.name}
          total={formatCurrency(parseFloat(order.total - amountPaid))}
          onChange={this.handleChangeCreditCard}
          onClose={this.showPaymentModal}
          onSaveSelection={this.handleSaveSelection}
          onCreateCreditCard={this.handleCreateCreditCard}
        />
      </Wrapper>
    );
  }
}

export default withRouter(Invoice);
