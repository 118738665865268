import React from 'react';
import styled from 'styled-components';

import { EditButton } from 'components/basic/Buttons';
import { formatPhoneNumber } from 'utils/basic';

const Wrapper = styled.div`
  margin-bottom: 15px;
`;

const Name = styled.div`
  color: #004258 !important;
  font-family: 'Source Sans', sans-serif !important;
  font-size: 14px;
`;

const FieldValue = styled.div`
  font-family: 'Source Sans Pro';
  color: #898889;
  font-size: 14px;
`;

const EditWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export default ({ firstName, lastName, email, emailAlias, phoneNumber, isDisabled, customerId, isMarineMax, onEdit }) => (
  <Wrapper>
    <EditWrapper>
      <Name>
        {firstName} {lastName}
      </Name>
      {onEdit && !isDisabled && <EditButton onClick={onEdit} />}
    </EditWrapper>
    <FieldValue>{formatPhoneNumber(phoneNumber)}</FieldValue>
    <FieldValue>{emailAlias || email}</FieldValue>
    {isMarineMax && customerId && <FieldValue>MarineMax ID: {customerId}</FieldValue>}
  </Wrapper>
);
