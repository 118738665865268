export const WHEN_FLEXIBLE_OPTION = `I'm Flexible`;
export const WHEN_ASAP_OPTION = 'As Soon As Possible';
export const WHEN_SPEICFIC_DATE_OPTION = 'On A Specific Date';
export const WHEN_COMPLICATED_OPTION = `It's Complicated`;

export const WHEN_OPTIONS = [
  WHEN_FLEXIBLE_OPTION,
  WHEN_ASAP_OPTION,
  WHEN_SPEICFIC_DATE_OPTION,
  WHEN_COMPLICATED_OPTION
];

export const WHEN_SLOT_MORNING = 'Morning';
export const WHEN_SLOT_AFTERNOON = 'Afternoon';

export const SLOT_OPTIONS = [
  WHEN_SLOT_MORNING,
  WHEN_SLOT_AFTERNOON
];

export const BY_PROVIDER_ID = 1;

export const PROVIDER_MESSAGE_TEMPLATES = [
  'quote_accepted',
  'quote_rejected',
  'order_completed',
  'notify_customer_for_order_with_preferred_provider',
  'invoice_paid',
  'order_rejected',
  'customer_places_order_with_preferred_provider',
  'order_accepted',
  'customer_places_order_without_preferred_provider',
  'admin_assigns_order_to_provider',
  'receipt_for_customer'
];
