import React, { useState, useEffect }from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from 'components/template/Login';
import ForgotPassword from 'components/template/ForgotPassword';
import ResetPassword from 'components/template/ResetPassword';
import CreatePassword from 'components/template/CreatePassword';
import ConfirmAccount from 'components/template/ConfirmAccount';

import Dashboard from 'components/template/Dashboard';
import Order from 'containers/Orders/OrderList';
import OrderDetails from 'containers/Orders/OrderDetails';
import { GeneralTeamList, TeamDetails } from 'containers/Teams/screens';
import Customers from 'containers/Customers/screens/Customers';
import CustomerDetails from 'containers/Customers/screens/CustomerDetails';
import Calendar from 'components/template/Calendar';
import Providers from 'containers/Providers/screens/Providers';
import ProviderEditor from 'containers/Providers/screens/ProviderEditor';
import UpdateProfile from 'containers/Profiles/screens/UpdateProfile';
import BoatShow from 'containers/Boats/BoatShow';
import BoatHold from 'containers/Boats/BoatHold';
import BoatReservationDone from 'containers/Boats/BoatReservationDone';
import Services from 'containers/Services/screens/Services';
import AddService from 'containers/Services/screens/AddService';
import ServiceDetails from 'containers/Services/screens/ServiceDetails';
import Categories from 'containers/Categories/screens/Categories';
import CategoryDetails from 'containers/Categories/screens/CategoryDetails';
import Contractors from 'containers/Contractors/screens/Contractors';
import ContractorDetails from 'containers/Contractors/screens/ContractorDetails';
import Inbox from 'containers/Message/Inbox';
import OpenedInvoices from 'containers/Invoices/OpenedInvoices';
import { QRBox, TemplateBox } from 'containers/Message';
import { Users, UserDetails } from 'containers/Users';
import { isAuthenticatedSelector } from 'store/selectors/auth';
import { SetRefreshFlag } from 'store/actions/auth';
import PrivateRoute from './privateRoute';
import PrivilegeRoute from './privilegeRoute';
import Intercom from 'components/basic/Intercom';
import { intercomAppId, mmIntercomAppId, lmcIntercomAppId, mmGtmId } from '../api/config';
import MainPageTemplate from 'components/template/MainPageTemplate';
import BackgroundImage from '../resources/auth/login-bg.png';
import MarineMaxBackgroundImage from '../resources/auth/mm-bg.jpg';
import IntercomProvider from './IntercomProvider';
import SendApp from 'containers/SendApp';
import AddPaymentMethod from 'components/template/CreditCardSection/AddPaymentMethod';
import ConfirmReservation from 'containers/Forms/confirmReservation';
import ActivitiesReminder from 'containers/Forms/activitiesReminder';
import ConfirmLmcAccount from 'containers/Forms/confirmLmcAccount';
import OnlineExperienceBoatHold from 'containers/Boats/onlineExperience/BoatHold';
import OnlineExperienceBoatHoldDeposit from 'containers/Boats/onlineExperience/BoatHoldDeposit';
import OnlineExperienceBoatHoldFree from 'containers/Boats/onlineExperience/BoatHoldFree';
import OnlineExperienceBoatHoldDone from 'containers/Boats/onlineExperience/BoatHoldDone';
import QuoteDocument from 'containers/Documents/Quote';
import InvoiceDocument from 'containers/Documents/Invoice';
import JobDocument from 'containers/Documents/Job';
import TagManager from 'react-gtm-module';

const locationBackground = window.location.href.includes("marinemax") ? MarineMaxBackgroundImage : BackgroundImage;
const Wrapper = styled.div`
  background-image: url(${locationBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const noBackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

const MainRoutes = ({refreshPage, SetRefreshFlag, ...props}) => {
  const [key, setKey] = useState('Wrapper');
  const { profile, isAuthenticated } = props;
  let interComId = intercomAppId;
  let showItercom = true;
  let WrapperComp = MainPageTemplate;
  if (isAuthenticated) {
    if (window.location.href.indexOf('/lmc/reset-password') > -1){
      WrapperComp = Wrapper;
      interComId = lmcIntercomAppId;
    } else if (window.location.href.indexOf('/lmc/') > -1 || 
      window.location.href.indexOf('/document/') > -1
    ) {
      WrapperComp = noBackgroundWrapper;
      interComId = lmcIntercomAppId;
    } else if (window.location.href.indexOf('login') > -1 ||
    window.location.href.indexOf('forgot-password') > -1 ||
    window.location.href.indexOf('reset-password') > -1 ||
    window.location.href.indexOf('/lmc/reset-password') > -1 ||
    window.location.href.indexOf('create-password') > -1 ||
    window.location.href.indexOf('add-payment-method') > -1 ||
    window.location.href.indexOf('confirm-account') > -1) {
      WrapperComp = Wrapper;
    } else if (window.location.href.indexOf('onlineboat') > -1 || 
      window.location.href.indexOf('textmetheapp') > -1 || 
      window.location.href.indexOf('marinemaxapp') > -1 || 
      window.location.href.indexOf('hold-my-boat') > -1 ||
      window.location.href.indexOf('/onlineexperience/') > -1 ||
      window.location.href.indexOf('/web/') > -1 ||
      window.location.href.indexOf('proapp') > -1) {
      WrapperComp = noBackgroundWrapper;
      interComId = mmIntercomAppId;
    }
  } else {
    if (window.location.href.indexOf('onlineboat') > -1 || 
    window.location.href.indexOf('textmetheapp') > -1 || 
    window.location.href.indexOf('marinemaxapp') > -1 || 
    window.location.href.indexOf('hold-my-boat') > -1 ||
    window.location.href.indexOf('/onlineexperience/') > -1 ||
    window.location.href.indexOf('/web/') > -1 ||
    window.location.href.indexOf('proapp') > -1) {
      WrapperComp = noBackgroundWrapper;
      interComId = mmIntercomAppId;
    } else if (window.location.href.indexOf('/lmc/reset-password') > -1){
      WrapperComp = Wrapper;
      interComId = lmcIntercomAppId;
    }else if (window.location.href.indexOf('/lmc/') > -1 || 
      window.location.href.indexOf('/document/') > -1){
      WrapperComp = noBackgroundWrapper;
      interComId = lmcIntercomAppId;
    } else {
      WrapperComp = Wrapper;
    }
  }

  if(window.location.href.indexOf('textmetheapp') > -1 || 
    window.location.href.indexOf('proapp') > -1 ||
    window.location.href.indexOf('/document/') > -1){
      showItercom = false;
  }

  if(window.location.href.indexOf('/web/') > -1 ){
    TagManager.initialize({
      gtmId: mmGtmId,
      dataLayerName: 'MarineMax'
    });
  }

  useEffect(() => {
    if (refreshPage) {
      SetRefreshFlag({
        flag: false,
        success: () => {
          setKey(Math.random());
        }
      });
    }
  });

  let user = {};

  if (isAuthenticated) {
    user = {
      user_id: profile.id,
      email: profile.email,
      name: `${profile.firstName} ${profile.lastName}`
    };
  }

  return (
  <>
  <Router>
    <IntercomProvider>
      <WrapperComp key={key}>
        <Route path="/login/" component={Login} />
        <Route path="/forgot-password/" component={ForgotPassword} />
        <Route path="/reset-password/" component={ResetPassword} />
        <Route path="/lmc/reset-password/" component={ResetPassword} />
        <Route path="/marinemax/reset-password/" component={ResetPassword} />
        <Route path="/create-password/" component={CreatePassword} />
        <Route path="/confirm-account/" component={ConfirmAccount} />
        <Route path="/onlineboatshow" component={BoatShow} />
        <Route path="/onlineboat/done" component={BoatReservationDone} />
        <Route path="/textmetheapp" component={SendApp} />
        <Route path="/boatus/textmetheapp" component={SendApp} />
        <Route path="/proapp" component={SendApp} />
        <Route path="/marinemaxapp" component={SendApp} />
        <Route path="/hold-my-boat" component={BoatHold} />
        <Route path="/onlineexperience/boat-hold" component={OnlineExperienceBoatHold} />
        <Route path="/onlineexperience/boat-hold-deposit" component={OnlineExperienceBoatHoldDeposit} />
        <Route path="/onlineexperience/boat-hold-free" component={OnlineExperienceBoatHoldFree} />
        <Route path="/onlineexperience/boat-hold-done" component={OnlineExperienceBoatHoldDone} />
        <Route path="/web/boat-hold" component={OnlineExperienceBoatHold} />
        <Route path="/web/boat-hold-deposit" component={OnlineExperienceBoatHoldDeposit} />
        <Route path="/web/boat-hold-free" component={OnlineExperienceBoatHoldFree} />
        <Route path="/web/boat-hold-done" component={OnlineExperienceBoatHoldDone} />
        <Route path="/lmc/confirm-reservation" component={ConfirmReservation} />
        <Route path="/lmc/activities-reminder" component={ActivitiesReminder} />
        <Route path="/lmc/confirm-account" component={ConfirmLmcAccount} />
        <Route path="/add-payment-method/" component={AddPaymentMethod} />
        <Route path="/document/quote" component={QuoteDocument} />
        <Route path="/document/invoice" component={InvoiceDocument} />
        <Route path="/document/job" component={JobDocument} />

        <PrivateRoute exact path="/update-profile" component={UpdateProfile} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/dashboard/" component={Dashboard} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/inbox/" component={Inbox} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/quick-replies/" component={QRBox} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/templates/" component={TemplateBox} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/orders/:id/detail" component={OrderDetails} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/orders/" component={Order} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/order-details/" component={OrderDetails} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/team/:type/list" component={GeneralTeamList} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/team/member-details/" component={TeamDetails} isAuthenticated={isAuthenticated}  />
        {/*<PrivilegeRoute exact path="/team/contractor-details/" component={TeamContractorDetails } privilege='provider' {...props} />*/}
        <PrivateRoute exact path="/contractors/" component={Contractors} isAuthenticated={isAuthenticated} />
        <PrivilegeRoute exact path="/contractor-details/" privilege='provider' component={ContractorDetails} isAuthenticated={isAuthenticated} {...props} />

        <PrivateRoute exact path="/calendar/" component={Calendar} isAuthenticated={isAuthenticated} />

        <PrivilegeRoute exact path="/providers/" component={Providers} privilege='admin' {...props} />
        <PrivilegeRoute exact path="/provider-details/" component={ProviderEditor} privilege='admin' {...props}  />
        <PrivilegeRoute exact path="/invoices/" component={OpenedInvoices} privilege='provider' {...props} />
        <PrivilegeRoute exact path="/services/" component={Services} privilege='provider' {...props} />
        <PrivilegeRoute exact path="/services/new/" component={AddService} privilege='provider' {...props}  />
        <PrivilegeRoute exact path="/service-details/" component={ServiceDetails} privilege='provider' {...props}  />
        <PrivilegeRoute exact path="/categories/" component={Categories} privilege='admin' {...props} />
        <PrivilegeRoute exact path="/category-details/" component={CategoryDetails} privilege='admin' {...props} />
        <PrivilegeRoute exact path="/customers/" component={Customers } privilege='provider' {...props} />
        <PrivilegeRoute exact path="/customer-details/" component={CustomerDetails} privilege={['provider', 'admin']} {...props} />
        <PrivilegeRoute exact path="/users/" component={Users} privilege='admin' {...props} />
        <PrivilegeRoute exact path="/user-details/" component={UserDetails} privilege='admin' {...props} />
        <PrivateRoute exact path="/" component={Dashboard} {...props}  />
      </WrapperComp>
    </IntercomProvider>
  </Router>
  { showItercom && <Intercom appID={interComId}  { ...user } /> }
  </>
)}
const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticatedSelector(state),
  loggedInPrivilege: state.auth.privilege,
  refreshPage: state.auth.refreshPage,
  profile: state.profile
});

const mapDispatchToProps = {
  SetRefreshFlag
};
export default connect(mapStateToProps, mapDispatchToProps)(MainRoutes);
