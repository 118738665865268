import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import styled from 'styled-components';

import { actionTypes as childActions, UpdateChildAccount } from 'store/actions/child-accounts';
import { actionTypes as userActions, UpdateUser } from 'store/actions/users';
import InfoSection from './InfoSection';
import CustomerModal from './CustomerModal';
import Modal from 'components/compound/Modal';
import { HollowButton, OrangeButton } from 'components/basic/Buttons';
import { NormalText } from 'components/basic/Typho'

const Label = styled(NormalText)`
  font-family: 'Open sans-serif', sans-serif;
  padding: 10px 0;
`;

class CustomerInfoSection extends React.Component {
  state = {
    edit: false,
    updateEmailModal: false,
    updateEmail: false,
    data: null
  }
  onSave = (userData) => {
    const { 
      customerInfo: { id, type, email }, 
      refreshInfo, 
      UpdateChildAccount, 
      UpdateUser, 
      privilege 
    } = this.props;

    const { updateEmail } = this.state;

    let data = userData ? userData : this.state.data;

    if (type === 'users') {
      let user = data.user;
    
      if(privilege === "admin" && user.email !== email){
        if(!updateEmail) {
          return this.setState({updateEmailModal:true, data });
        }else{
          user.confirmation = false;
        }
      }

      /*
        if (user.hasOwnProperty('locations_attributes')) {
          delete user['locations_attributes'];
        }
      */
      
      UpdateUser({
        userId: id, data: { user },
        success: (currentUser) => {
          this.hideModal();
          refreshInfo(currentUser);
        },
        error: (e) => {
          toastr.error('Error', e.message);
        }
      });
    } else {
      UpdateChildAccount({
        childAccountId: id, data: { child_account: { ...data.user } },
        success: () => {
          this.hideModal();
          refreshInfo();
        },
        error: (e) => {
          toastr.error('Error', e.message);
        }
      });
    }
  };

  showModal = () => {
    this.setState({
      edit: true,
    })
  };

  hideModal = () => {
    this.setState({
      edit: false, 
      updateEmail: false,
      data: null
    })
  };

  hideUpdateEmailModal = () => {
    this.setState({
      updateEmailModal: false,
      data: null
    })
  }

  saveWithEmail = () => {
    this.setState({
      updateEmail: true,
      updateEmailModal: false,
    }, () => {
      this.onSave();
    });
  }

  render() {
    const { type, customerInfo, childStatus, userStatus, isMarineMax } = this.props;
    const { edit, updateEmailModal, data } = this.state;

    const userName = data && data.user && data.user.first_name ? data.user.first_name : '';
    const userEmail = data && data.user && data.user.email ? data.user.email : '';

    const actions = [
      <HollowButton onClick={this.hideUpdateEmailModal} key="modal_btn_cancel">Cancel</HollowButton>,
      <OrangeButton onClick={this.saveWithEmail} key="modal_btn_save">Confirm</OrangeButton>
    ];

    return (
      <React.Fragment>
        <InfoSection {...customerInfo} onEdit={this.showModal} isMarineMax={isMarineMax} />
        <CustomerModal
          title={type === 'user' ? 'Edit User' : 'Edit Customer'}
          open={edit}
          loading={childStatus === childActions.UPDATE_CHILD_ACCOUNT || userStatus === userActions.UPDATE_USER}
          customerInfo={customerInfo}
          onClose={this.hideModal}
          onSave={this.onSave}
          isMarineMax={isMarineMax}
        />
        <Modal
          title={'Are You Sure?'}
          actions={actions}
          normal={true}
          open={updateEmailModal}
          onClose={this.hideUpdateEmailModal}
        >
          <Label>Please confirm that you would like to change {userName}'s email to {userEmail}</Label>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  childStatus: state.childAccount.currentStatus,
  userStatus: state.user.currentStatus,
  privilege: state.auth.privilege,
  isMarineMax: Boolean(state.auth.providerId === 2)
});

const mapDispatchToProps = {
  UpdateChildAccount,
  UpdateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfoSection);