const defaultServiceCategories = [
  {
    attributes: {
      customIcon: null,
      defaultIcon: "https://new-staging-api-storage.s3.amazonaws.com/uploads/staging/icon/icon/54/boat_icon.png",
      description: null,
      iconId: 54,
      name: "Cleaning"  
    }
  },
  {
    attributes: {
      customIcon: null,
      defaultIcon: "https://new-staging-api-storage.s3.amazonaws.com/uploads/staging/icon/icon/27/facility_icon.png",
      description: null,
      iconId: 27,
      name: "Service"
    }
  },
  {
    attributes: {
      customIcon: null,
      defaultIcon: "https://new-staging-api-storage.s3.amazonaws.com/uploads/staging/icon/icon/9/pilotage_icon.png",
      description: null,
      iconId: 9,
      name: "Captain Services"
    }
  },
  {
    attributes: {
      customIcon: null,
      defaultIcon: "https://new-staging-api-storage.s3.amazonaws.com/uploads/staging/icon/icon/38/fuel_icon.png",
      description: null,
      iconId: 38,
      name: "Fuel"
    }
  },
  {
    attributes: {
      customIcon: null,
      defaultIcon: "https://new-staging-api-storage.s3.amazonaws.com/uploads/staging/icon/icon/11/wrench_icon.png",
      description: null,
      iconId: 11,
      name: "Maintenance Plans"
    }
  },
  {
    attributes: {
      customIcon: null,
      defaultIcon: "https://new-staging-api-storage.s3.amazonaws.com/uploads/staging/icon/icon/5/boat-5_icon.png",
      description: null,
      iconId: 5,
      name: "On-Water Help"
    }
  }
];

export default defaultServiceCategories;
