import React from 'react'
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { get, startCase, orderBy } from 'lodash';
import moment from 'moment';

import { Section } from 'components/basic/InfoSection';
import * as constants from 'utils/constants';

const TR = styled.tr`
  &:last-child {
  }
`;

const Label = styled.td`
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px;
  color: #004258;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 15px;
  padding-right: 10px;
  line-height: 20px;
  vertical-align: top;
`;

const FieldValue = styled.td`
  font-family: 'Source Sans Pro';
  color: #07384b;
  font-size: 16px;
  font-weight: 400;
  padding: 0 0 19px 0;
`;

const FieldValueLink = styled.a`
  color: rgb(247,148,30);
  line-height: 20px;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-weight: 600;
`;

export default class OrderSummarySection extends React.Component {
  renderWhenValues = () => {
    const { order } = this.props;
    const lineItems = get(order, 'lineItems', []);
    if (lineItems && lineItems.length > 0) {
      const orderedItems = orderBy(lineItems, 'id', 'asc');
      const scheduleItems = get(orderedItems[0], 'relationships.lineItemSchedules', []);
      if (scheduleItems && scheduleItems.length > 0) {
        const scheduleItem = scheduleItems[0];
        if (scheduleItem) {
          if (get(scheduleItem, 'attributes.flexible')) {
            return (
              <TR>
                <Label>WHEN</Label>
                <FieldValue sm={3} md={3} lg={3}>{constants.WHEN_FLEXIBLE_OPTION}</FieldValue>
              </TR>
            );
          } else if (get(scheduleItem, 'attributes.asap')) {
            return (
              <TR>
                <Label>WHEN</Label>
                <FieldValue sm={3} md={3} lg={3}>{constants.WHEN_ASAP_OPTION}</FieldValue>
              </TR>
            );
          } else if (get(scheduleItem, 'attributes.complicated')) {
            const complicated = get(scheduleItem, 'attributes.complicated');
            return (
              <TR>
                <Label>WHEN</Label>
                <FieldValue sm={3} md={3} lg={3}>{`${constants.WHEN_COMPLICATED_OPTION} - ${complicated}`}</FieldValue>
              </TR>
            );
          } else {
            const startAt = moment(get(scheduleItem, 'attributes.specificStart'));
            console.log(get(scheduleItem, 'attributes.specificStart'));
            console.log(startAt.format('H'));
            let morning = true;
            if (startAt.hours() >= 10) {
              morning = false;
            }
            return (
              <TR>
                <Label>WHEN</Label>
                <FieldValue sm={3} md={3} lg={3}>{`${startAt.format('MM/DD/YYYY')} (${morning ? 'Morning' : 'Afternoon'})`}</FieldValue>
              </TR>
            );
          }
        }
      }
    }
    return null;
  };

  renderProperties = () => {
    const { order } = this.props;
    const properties = get(order, 'attributes.properties', {});
    const fields = [];
    for (const key in properties) {
      const value = get(properties, key);
      const answer = typeof value === 'boolean' ? value === true ? 'Yes' : 'No' : value
      fields.push(
        <TR key={`${key} - ${value}`}>
          <Label>
            {startCase(key)}
          </Label>
          <FieldValue sm={3} md={3} lg={3}>
            {answer.includes('https') ? <FieldValueLink rel="noopener noreferrer" target='_blank' href={`${answer}`}>{answer}</FieldValueLink> : <span dangerouslySetInnerHTML={{ __html: answer.replace(/\r?\n/g, '<br />')}} /> }
          </FieldValue>
        </TR>
      );
    }
    return fields;
  };

  render () {
    const { lineItem, order, memorialization } = this.props;
    const specialInstructions = get(order, 'attributes.specialInstructions');
    const slipNumber = get(order, 'attributes.slipNumber');
    //const serviceName = get(memorialization, 'service.name', '');
    const providerLocationServiceName = get(memorialization, 'providerLocationService.name', '');
    const serviceName = get(memorialization, 'service.name');
    const serviceNameRelation = get(lineItem, 'relationships.service.attributes.name');
    //const providerLocationServiceName = get(lineItem, 'relationships.providerLocationService.attributes.name');
    return (isEmpty(lineItem) && isEmpty(slipNumber) && isEmpty(specialInstructions)) ? false : (
      <Section title="Order Summary">
        <table>
          <tbody>
            {!isEmpty(lineItem) && <TR>
              <Label>
                SERVICE
              </Label>
              <FieldValue sm={3} md={3} lg={3}>
                {providerLocationServiceName || serviceName || serviceNameRelation}
              </FieldValue>
            </TR>}
            {this.renderWhenValues()}
            {this.renderProperties()}
            {!isEmpty(slipNumber) && <TR>
              <Label>
                Slip Number
              </Label>
              <FieldValue sm={3} md={3} lg={3}>
                {slipNumber}
              </FieldValue>
            </TR>}
            {!isEmpty(specialInstructions) && <TR>
              <Label>
                Special Instructions
              </Label>
              <FieldValue sm={3} md={3} lg={3}>
                <div dangerouslySetInnerHTML={{ __html: specialInstructions.replace(/\r?\n/g, '<br />')}} />
              </FieldValue>
            </TR>}
          </tbody>
        </table>
      </Section>
    )
  }
}
