import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import styled from 'styled-components';
import { get, isEmpty } from 'lodash';
import { capitalize } from "lodash";
import { formatCard, formatTwoDigitsMonth } from 'helpers';  
import { actionTypes, CreateCreditCard, UpdateCreditCard } from 'store/actions/credit-cards';
import { HollowButton, OrangeButton } from 'components/basic/Buttons';
import Modal from 'components/compound/Modal';
import FormFields from 'components/template/FormFields';

const Divider = styled.hr`
  margin: 20px 0;
  border-top: 1px solid #f1f1f1;
`;

const coutries = [
  {
    label: 'United States',
    value: 'US'
  },
  {
    label: 'Canada',
    value: 'CA'
  }
];

class CreateModal extends React.Component {
  getCardFields = () => {
    const { selectedCard } = this.props;
    const hasSelectedCard = !isEmpty(selectedCard);
    return [
      {
        type: 'text_field',
        field: 'card_number',
        className: 'primary',
        label: 'CARD NUMBER',
        errorMessage: 'Please enter a valid card number.',
        required: !hasSelectedCard,
        disabled: hasSelectedCard,
        defaultValue: selectedCard && selectedCard.attributes ? 
          `${formatCard(capitalize(selectedCard.attributes.name))} xxxx${selectedCard.attributes.last4}` : "",
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
        xl: 6
      },
      {
        type: 'text_field',
        field: 'month',
        className: 'primary',
        label: 'MONTH',
        mask: '99',
        required: !hasSelectedCard,
        errorMessage: 'Required',
        disabled: hasSelectedCard,
        defaultValue: selectedCard && selectedCard.attributes && selectedCard.attributes.expirationMonth ? 
          `${formatTwoDigitsMonth(selectedCard.attributes.expirationMonth)}` : "",
        xs: 12,
        sm: 6,
        md: 2,
        lg: 2,
        xl: 2
      },
      {
        type: 'text_field',
        field: 'year',
        className: 'primary',
        label: 'YEAR',
        required: !hasSelectedCard,
        errorMessage: 'Required',
        disabled: hasSelectedCard,
        defaultValue: get(selectedCard, "attributes.expirationYear", ''),
        mask: '9999',
        xs: 12,
        sm: 6,
        md: 2,
        lg: 2,
        xl: 2
      },
      {
        type: 'text_field',
        field: 'cvv',
        className: 'primary',
        label: 'CVV',
        required: true,
        errorMessage: 'Required',
        disabled: hasSelectedCard,
        defaultValue: hasSelectedCard ? "xxx" : "",
        xs: 12,
        sm: 6,
        md: 2,
        lg: 2,
        xl: 2
      },
    ];
  }

  getInfoFields = () => {
    const { user, selectedCard } = this.props;
    const hasSelectedCard = !isEmpty(selectedCard);
    const postalCode = get(selectedCard, "attributes.postalCode", '');
    const defaultCountry = /[a-zA-Z]/g.test(postalCode) ? coutries[1].value : coutries[0].value;

    return [
      {
        type: 'text_field',
        field: 'first_name',
        className: 'primary',
        label: 'First Name',
        required: true,
        errorMessage: 'Required',
        disabled: hasSelectedCard,
        defaultValue: get(user, 'firstName', ''),
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
        xl: 6
      },
      {
        type: 'text_field',
        field: 'last_name',
        className: 'primary',
        label: 'Last Name',
        required: true,
        errorMessage: 'Required',
        disabled: hasSelectedCard,
        defaultValue: get(user, 'lastName', ''),
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
        xl: 6
      },
      {
        type: 'text_field',
        field: 'zip',
        className: 'primary',
        label: 'Zip Code',
        required: true,
        defaultValue:  postalCode,
        errorMessage: 'Required',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
        xl: 6
      },
      {
        type: 'select_box',
        field: 'country',
        className: 'primary',
        label: 'Country',
        options: coutries,
        disabled: hasSelectedCard,
        defaultValue: defaultCountry,
        required: true,
        errorMessage: 'Choose Country',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
        xl: 6
      }
    ];
  }

  onSuccess = () => {
    this.props.refreshCards();
    this.props.onClose();
  };

  setCardFieldsRef = (ref) => {
    this.cardFields = ref;
  };

  setInfoFieldsRef = (ref) => {
    this.infoFields = ref;
  };

  save = () => {
    const cardsVailid = this.cardFields.validateFields();
    const infoValid = this.infoFields.validateFields();
    const { user, selectedCard } = this.props;
    const hasSelectedCard = !isEmpty(selectedCard);

    if(!hasSelectedCard){
      if (cardsVailid && infoValid) {
        const cardValue = this.cardFields.getFieldValues();
        const infoValues = this.infoFields.getFieldValues();
        const data = { ...cardValue, ...infoValues };
        if (user.type === 'child_accounts') {
          data['child_account_id'] = user.id;
        } else {
          data['user_id'] = user.id;
        }
        this.props.CreateCreditCard({
          data,
          success: this.onSuccess,
          error: (e) => {
            toastr.error('Error', e.message);
          }
        });
      }
    }else{
      
      
      if (infoValid) {
        const infoValues = this.infoFields.getFieldValues();
        if(infoValues.zip && selectedCard.id){
          const data = {
            postal_code: infoValues.zip,
          }
          this.props.UpdateCreditCard({ 
            creditCardId: selectedCard.id, 
            data, 
            success: this.onSuccess 
          });
        } 
      }
    }
  };

  render() {
    const { open, onClose, currentStatus, selectedCard } = this.props;
    const actions = [
      <HollowButton onClick={onClose} key="modal_btn_cancel">CANCEL</HollowButton>,
      <OrangeButton onClick={this.save} key="modal_btn_save">SAVE</OrangeButton>
    ];
    const cardFields = this.getCardFields();
    const infoFields = this.getInfoFields();
    return (
      <Modal
        title={`${selectedCard ? "Edit": "New"} Payment Method`}
        actions={actions}
        loading={currentStatus === actionTypes.CREATE_CREDIT_CARD || currentStatus === actionTypes.UPDATE_CREDIT_CARD}
        open={open}
        onClose={onClose}
      >
        <FormFields ref={this.setCardFieldsRef} fields={cardFields} />
        <Divider />
        <FormFields ref={this.setInfoFieldsRef} fields={infoFields} />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  currentStatus: state.creditCard.currentStatus
});

const mapDispatchToProps = {
  CreateCreditCard,
  UpdateCreditCard
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateModal);
