import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import MMLogo from "resources/boatshow/mm_color_logo.png";
import TagManager from 'react-gtm-module';

import {
  Wrapper,
  ContentWrapper,
  ContainerWrapper,
  Img,
  Footer,
  FooterActionWrapper,
  ActionButton
} from "./BoatHoldDeposit";

const TitleLabel = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 45px;
  color: #3d485c;
  letter-spacing: -1.85px;
  text-align: center;
  margin-bottom: 10px;
`;

const DescLabel = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #3d485c;
  text-align: center;
  padding: 0px 4px;
  line-height: 22px;
`;

const SectionWrapper = styled.div`
  width: 100%;
`;

const SectionContainer = styled.div`
  padding: 20px 60px;
`;

class BoatHoldDone extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tagManagerArgs: {},
      origin: window.location.pathname.includes("web")
        ? "web"
        : "onlineexperience"
    };
  }

  componentDidMount(){
    if(window.location.pathname.includes("/web")){
      const tagManagerArgs = {
        dataLayer: {
          page: "reservationDone"
        },
        dataLayerName: 'MarineMax'
      };
      this.setState({ tagManagerArgs });
    }
  }

  handleSubmit = () => {
    const { origin } = this.state;
    const url =
      origin === "web"
        ? "https://www.marinemax.com"
        : "https://www.marinemax.com/experiences/exclusive-sales-event";
    document.location.href = url;
  };

  render() {
    const { origin, tagManagerArgs } = this.state;

    if(origin === "web" && tagManagerArgs.dataLayer){
      TagManager.dataLayer(tagManagerArgs);
    }

    return (
      <Wrapper>
        <ContentWrapper>
          <ContainerWrapper>
            <Img src={MMLogo} />
            <SectionWrapper>
              <SectionContainer>
                <TitleLabel>{"Congratulations!"}</TitleLabel>
                <DescLabel>
                  {
                    "Your boat has been reserved. One of our MarineMax sales professionals will contact you shortly to answer your questions and assist you in purchasing your new boat."
                  }
                </DescLabel>
              </SectionContainer>
            </SectionWrapper>
            <Footer>
              <SectionWrapper>
                <SectionContainer>
                  <FooterActionWrapper>
                    <ActionButton onClick={this.handleSubmit}>
                      {origin === "web"
                        ? "Return to MarineMax.com"
                        : "Return to the Event"}
                    </ActionButton>
                  </FooterActionWrapper>
                </SectionContainer>
              </SectionWrapper>
            </Footer>
          </ContainerWrapper>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

export default withRouter(BoatHoldDone);
