import { get } from 'lodash';

export const refineUsers = (users) => {
  return users.map(user => {
    return {
      id: user.id,
      type: user.type,
      ...user.attributes
    };
  });
};


export const getUserLocationAddress = (relations, includes) => {
  let userLocation = {};
  if(relations && relations.locations && relations.locations.data && relations.locations.data[0]){
    const locationId = relations.locations.data[0].id;
    if(includes['locations'][locationId]){
      const locationPayload = includes['locations'][locationId];
      userLocation = get(locationPayload, "relationships.address.data", {});
    }
  }
  return userLocation || {};
}