import { startCase } from 'lodash'

//Camel Case string to snake case
export function camelToSnake(string) {
  return string.replace(/[\w]([A-Z])/g, function(m) {
    return m[0] + "_" + m[1];
  }).toLowerCase();
}

//Phone number formatting
export function formatPhoneNumber(phoneNumber) {
  var cleaned = ('' + phoneNumber).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

//Credit Card Formatting
export function formatCard(name) {
  switch(name)
  {
    case 'Visa':
      return 'Visa';
    case 'Mastercard':
      return 'MasterCard';
    case 'MasterCard':
        return 'MasterCard';
    case 'Master':
      return 'MasterCard';
    case 'Amex':
      return 'AmEx';
    case 'AmEx':
      return 'AmEx';
    case 'jcb':
      return 'JCB';
    case 'naranja':
        return 'Naranja';
    default:
      let defaultName = "";
      try{
        if(name && name.indexOf("moved") === -1){
          defaultName = startCase(name.replace(/_/gi, " ").toLowerCase());
        }
      }catch(e){}
      return defaultName;
  }
};

export function formatTwoDigitsMonth(month){
  return month && month < 10 ? `0${month}` : month;
}