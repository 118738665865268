import React, { Fragment } from "react";
import PropTypes from "prop-types";

import {
  SectionHeaderWrapper,
  LeftPart,
  RightPart
} from "components/basic/Header";
import { HollowButton, OrangeButton } from "components/basic/Buttons";
import { PageTitle } from "components/basic/Typho";

export default class ContractorDetailsHeader extends React.Component {
  handleClickDeleteButton = () => {
    const { onAction } = this.props;
    if (onAction) {
      onAction();
    }
  }

  getName = () => {
    const { contractor } = this.props;
    return contractor && contractor.name ? contractor.name : 'Add New Contractor';
  }

  render() {
    const { onDelete, canPublish, onPublish, edit, contractor } = this.props;
    const name = this.getName();

    return (
      <SectionHeaderWrapper>
        <LeftPart>
          <PageTitle>{edit ? name : "Add New Contractor"}</PageTitle>
        </LeftPart>
        <RightPart>
          {(edit && contractor && contractor.id) && (
            <Fragment>
              <OrangeButton onClick={onDelete} style={{ minWidth: 100 }}>
                Delete
              </OrangeButton>
              {canPublish &&
                <HollowButton className="desktop" onClick={onPublish}>
                  {contractor && contractor.published ? 'UnPublish': 'Publish'}
                </HollowButton>
              }
            </Fragment>
          )}
        </RightPart>
      </SectionHeaderWrapper>
    );
  }
}

ContractorDetailsHeader.propTypes = {
  contractor: PropTypes.object,
  onDelete: PropTypes.func,
  onPublish: PropTypes.func
};

ContractorDetailsHeader.defaultProps = {
  contractor: {},
  onDelete: () => {},
  onPublish: () => {}
};
