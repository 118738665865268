import { findIndex, get, isEmpty, sortBy, /*filter,*/find } from 'lodash';
import moment from 'moment';

export const getUserFromOrder = (order, privilege = 'admin') => {
  // let user = get(order, 'relationships.user');
  // if (privilege !== 'admin') {
  //   user = get(order, 'relationships.childAccount');
  // }
  // if (!isEmpty(user)) {
  //   return {
  //     id: user.id,
  //     type: user.type,
  //     ...user.attributes
  //   };
  // }
  let user = get(order, 'relationships.childAccount');
  if (!isEmpty(user)) {
    if (user.hasOwnProperty('data')) {
      user = get(order, 'relationships.user');
    }
    if (!isEmpty(user)) {
      return {
        id: user.id,
        type: user.type,
        ...user.attributes
      };
    }
  }
  return user;
};

export const getChildAccountFromOrder = order => {
  const user = get(order, 'relationships.childAccount');
  if (!isEmpty(user)) {
    return {
      id: user.id,
      type: user.type,
      ...user.attributes
    };
  }
  return user;
};

export const getBoatFromOrder = order => {
  let boat = get(order, 'relationships.boat', {});
  if (!isEmpty(boat)) {
    boat = {
      id: boat.id,
      ...boat.attributes,
      location: boat.location
    };
  }
  return boat;
};

export const getProviderFromOrder = order => {
  let provider = get(order, 'relationships.provider', {});
  if (!isEmpty(provider)) {
    provider = {
      id: provider.id,
      ...provider.attributes,
      ...provider.relationships
    };
  }
  return provider;
};

export const getCustomerName = (order, privilege = 'admin') => {
  const user = getUserFromOrder(order, privilege);
  const firstName = get(user, 'firstName', '') || '';
  const lastName = get(user, 'lastName', '') || '';
  return `${firstName} ${lastName}`;
};

export const getCreationInfo = order => {
  const customerName = getCustomerName(order);
  const createdAt = get(order, 'attributes.createdAt');
  const dateString = moment(createdAt).format('MMM D, YYYY [at] h:mm A');
  return {
    time: moment(createdAt).valueOf(),
    message: `Order placed by ${customerName} on ${dateString}`
  };
};

export const getUpdatedStatus = order => {
  const updatedAt = get(order, 'attributes.updatedAt');
  const dateString = moment(updatedAt).format('MMM D, YYYY [at] h:mm A');
  return {
    time: moment(updatedAt).valueOf(),
    message: `Order updated on ${dateString}`
  };
};

export const getActivityInfo = (order, privilege) => {
  const result = [];
  if (!isEmpty(order)) {
    const activities = get(order, 'attributes.activityFeed');
    if (!isEmpty(activities)) {
      activities.forEach(activity => {
        const full_name = activity.actor ? `${activity.actor.firstName} ${activity.actor.lastName}` : ''; 
        const assignee = activity.assigned ? `${activity.assigned.firstName} ${activity.assigned.lastName}` : '';
        switch (activity.type) {
          case 'order_placed':
            result.push({
              time: moment(activity.at).valueOf(),
              message: `Order placed by ${full_name} on ${moment(activity.at).format('MMM D, YYYY [at] h:mm A')}`
            });
            break;
          case 'order_accepted':
            result.push({
              time: moment(activity.at).valueOf(),
              message: `Order accepted by ${full_name} on ${moment(activity.at).format('MMM D, YYYY [at] h:mm A')}`
            });
            break;
          case 'order_assigned':
            result.push({
              time: moment(activity.at).valueOf(),
              message: `${full_name} assigned order to ${assignee} on ${moment(activity.at).format('MMM D, YYYY [at] h:mm A')}`
            });
            break;
          case 'order_completed':
            result.push({
              time: moment(activity.at).valueOf(),
              message: `Order completed on ${moment(activity.at).format('MMM D, YYYY [at] h:mm A')}`
            });
            break;
          case 'invoice_sent':
            result.push({
              time: moment(activity.at).valueOf(),
              message: `Invoice sent by ${full_name} on ${moment(activity.at).format('MMM D, YYYY [at] h:mm A')}`
            });
            break;
          case 'quote_sent':
            result.push({
              time: moment(activity.at).valueOf(),
              message: `Quote sent by ${full_name} on ${moment(activity.at).format('MMM D, YYYY [at] h:mm A')}`
            });
            break;
          case 'order_rejected':
            if (privilege === 'admin') {
              result.push({
                time: moment(activity.at).valueOf(),
                message: `Order declined by ${full_name} on ${moment(activity.at).format('MMM D, YYYY [at] h:mm A')}`
              });
            }
            break;
          case 'payment_received':
            //const paymentType = activity.last4 ? `${activity.paymentType} xxxx${activity.last4}` : activity.paymentType;
            if(activity.actor){
              result.push({
                time: moment(activity.at).valueOf(),
                message: `Invoice paid by ${full_name} on ${moment(activity.at).format('MMM D, YYYY [at] h:mm A')}`
              });
            }
            break;
          case 'user_accepted_quote':
              result.push({
                time: moment(activity.at).valueOf(),
                message: `Quote Accepted by ${full_name} on ${moment(activity.at).format('MMM D, YYYY [at] h:mm A')}`
              });
              break;
          case 'card_request':
            if(activity.actor){
              result.push({
                time: moment(activity.at).valueOf(),
                message: `Credit card requested by ${full_name} on ${moment(activity.at).format('MMM D, YYYY [at] h:mm A')}`
              });
            }
            break;
          case 'card_added':
            if(activity.actor){
              result.push({
                time: moment(activity.at).valueOf(),
                message: `Credit card added by ${full_name} on ${moment(activity.at).format('MMM D, YYYY [at] h:mm A')}`
              });
            }
            break;
          default:
            break;
        }
      })
    }
  }
  return result;
}

export const getOrderProcessInfo = order => {
  const result = [];
  // console.log(order);
  if (!isEmpty(order)) {
    const timeStamps = get(order, 'attributes.historicTimestamps', {});
    const acceptance = get(order, 'attributes.acceptanceHistory', {});
    const acceptanceLocation = acceptance[Object.keys(acceptance)[0]];
    const keys = Object.keys(timeStamps);
    for (let i = 0; i < keys.length; i += 1) {
      const time = get(timeStamps, keys[i]);
      switch (keys[i]) {
        case 'assignedAt':
          if (!isEmpty(time)) {
            result.push({
              time: moment(time).valueOf(),
              message: `Order assigned on ${moment(time).format(
                'MMM D, YYYY [at] h:mm A'
              )}`
            });
          }
          break;
        case 'provisionedAt':
          if (!isEmpty(time)) {
            result.push({
              time: moment(time).valueOf(),
              message: `Order quoted on ${moment(time).format(
                'MMM D, YYYY [at] h:mm A'
              )}`
            });
          }
          break;
        case 'scheduledAt':
          if (!isEmpty(time)) {
            result.push({
              time: moment(time).valueOf(),
              message: `Order in progress on ${moment(time).format(
                'MMM D, YYYY [at] h:mm A'
              )}`
            });
          }
          break;
        case 'invoicedAt':
          if (!isEmpty(time)) {
            result.push({
              time: moment(time).valueOf(),
              message: `Order invoiced on ${moment(time).format(
                'MMM D, YYYY [at] h:mm A'
              )}`
            });
          }
          break;
        case 'acceptedAt':
          if (!isEmpty(time)) {
            result.push({
              time: moment(time).valueOf(),
              message: `Order accepted by ${acceptanceLocation} on ${moment(time).format(
                'MMM D, YYYY [at] h:mm A'
              )}`
            });
          }
          break;
        case 'dispatchedAt':
          if (!isEmpty(time)) {
            result.push({
              time: moment(time).valueOf(),
              message: `Order dispatched on ${moment(time).format(
                'MMM D, YYYY [at] h:mm A'
              )}`
            });
          }
          break;
        default:
          break;
      }
    }
  }
  return result;
};

export const generateOrderTimeline = (order, privilege = "admin") => {
  const activity_feed = get(order, 'attributes.activityFeed');
  //const creationInfo = getCreationInfo(order);
  //const updateInfo = getUpdatedStatus(order);
  const timeLine = isEmpty(activity_feed) ? getOrderProcessInfo(order) : getActivityInfo(order, privilege);
  //const activity = getActivityInfo(order);
  const result = [/*creationInfo,*/ ...timeLine];
  return sortBy(result, ['time']).reverse();
};

export const getLocationAddressFromOrder = order => {
  const { included } = order;
  const idx = findIndex(included, item => item.type === 'locations');
  return get(included, `[${idx}].relationships.address`);
};

export const getTeamMemberData = (data, included) => {
  // filter(data, ({attributes: {access }}) => access !== 'admin')
  return sortBy(
    data.map(row => {
      const {id, attributes: {firstName, lastName }} = find(included, row);
      return {id, fullName: `${firstName} ${lastName}` };
    }),
    ['fullName']
  )
}

export const getContractorsData = (data) => {
  const filterData = [];

  data.forEach(row => {
    const {id, attributes: {name, contactableId, teamMember}} = row;
    if(name){
      filterData.push({id, fullName: `${name}`, contactableId, teamMember });
    }
  });

  return sortBy(filterData, ['fullName']);
}

export const getLastCreditCard = (creditCards) => {
  let defaultCard = creditCards && creditCards[0] ? creditCards[0] : {};
  if(creditCards.length > 1){
    creditCards.forEach((card) => {
      try{
        const prevDate = moment(defaultCard.attributes.createdAt);
        const currentDate = moment(card.attributes.createdAt);
        if(currentDate.diff(prevDate) > 0){
          defaultCard = card;
        }
      }catch(e){}
    })
  }
  return defaultCard;
}