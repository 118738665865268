import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import MMLogo from "resources/boatshow/mm_color_logo.png";
import LoadingSpinner from "components/basic/LoadingSpinner";
import queryString from "query-string";
import TagManager from "react-gtm-module";

import {
  Wrapper,
  ContentWrapper,
  ContainerWrapper,
  Img,
  Footer,
  FooterActionWrapper
} from "./BoatHoldDeposit";

const TitleLabel = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 45px;
  color: #3d485c;
  letter-spacing: -1.85px;
  text-align: center;
  margin-bottom: 20px;
`;

const DescLabel = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #3d485c;
  text-align: center;
  padding: 0px 25px;
  line-height: 22px;
  margin-bottom: 20px;
  .purchase-button {
    //font-style: italic;
    font-weight: bold;
  }
`;

export const ActionButton = styled.button`
  background: #da3128;
  border-radius: 38.5px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  width: 220px;
  height: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  &.purchase-button {
    margin-left: 10px;
  }
  @media (max-width: 575px) {
    margin-bottom: 10px;
    &.purchase-button {
      margin-left: 0px;
    }
  }
`;

const SectionWrapper = styled.div`
  width: 100%;
`;

const SectionContainer = styled.div`
  padding: 20px 60px;
`;

class BoatHold extends React.PureComponent {
  state = {
    loading: false,
    tagManagerArgs: {},
    origin: window.location.pathname.includes("web")
      ? "web"
      : "onlineexperience"
  };

  componentDidMount() {
    if (window.location.pathname.includes("/web")) {
      const queryParams = queryString.parse(this.props.location.search);
      const tagManagerArgs = {
        dataLayer: {
          ...queryParams,
          page: "reserveBoat"
        },
        dataLayerName: "MarineMax"
      };
      this.setState({ tagManagerArgs });
    }
  }

  gotToFree = () => {
    this.setState({ loading: true }, () => {
      document.location.href = `/${this.state.origin}/boat-hold-free${
        this.props.location.search
      }`;
    });
  };

  gotToPurchase = () => {
    this.setState({ loading: true }, () => {
      document.location.href = `/${this.state.origin}/boat-hold-deposit${
        this.props.location.search
      }`;
    });
  };

  render() {
    const { loading, origin, tagManagerArgs } = this.state;

    if (origin === "web" && tagManagerArgs.dataLayer) {
      TagManager.dataLayer(tagManagerArgs);
    }

    return (
      <Wrapper>
        <ContentWrapper>
          <ContainerWrapper>
            <Img src={MMLogo} />
            <SectionWrapper>
              <SectionContainer>
                <TitleLabel>{"Reserve Your Boat"}</TitleLabel>
                <DescLabel>
                  Click the{" "}
                  <span className="purchase-button">Reserve My Boat</span>{" "}
                  button to reserve your boat for free for up to 24 hours.{" "}
                </DescLabel>
              </SectionContainer>
            </SectionWrapper>
            <Footer>
              <SectionWrapper>
                <SectionContainer>
                  <FooterActionWrapper>
                    <ActionButton onClick={this.gotToFree}>
                      {"Reserve My Boat"}
                    </ActionButton>
                    {/*<ActionButton
                      className="purchase-button"
                      onClick={this.gotToPurchase}
                    >
                      {"Start My Purchase"}
                    </ActionButton>*/}
                  </FooterActionWrapper>
                </SectionContainer>
              </SectionWrapper>
            </Footer>
          </ContainerWrapper>
        </ContentWrapper>
        {loading && (
          <LoadingSpinner style={{ position: "fixed" }} loading={loading} />
        )}
      </Wrapper>
    );
  }
}

export default withRouter(BoatHold);
