import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import { Row, Col } from 'react-flexbox-grid';
import { HollowButton, OrangeButton } from 'components/basic/Buttons';
import { CurrencyInput } from 'components/basic/Input'
import Modal from 'components/compound/Modal';
import { Wrapper } from './style';
import CardOption  from './components/CardOption';
import OtherOption  from './components/OtherOption';
import { toastr } from 'react-redux-toastr';

const RefundPaymentModal = ({payments, loading, open, onClose, onRefund}) => {
  const [selectedPaymentId, setSelectedPaymentId] = useState(payments[0].id);
  const [selectedPaymentType, setSelectedPaymentType] = useState(payments[0].attributes.paymentType);
  const [balance, setBalance] = useState(payments[0].attributes.amount);
  const [amount, setAmount] = useState(payments[0].attributes.amount);

  useEffect(() => {
    onOptionChange(selectedPaymentId)
  }, [selectedPaymentId])

  const handleRefund = () => {
    if(parseFloat(amount) > parseFloat(balance) || parseFloat(amount) <= 0){
      toastr.error('Error', "The refund amount is invalid");
    }else{
      const isPartialRefund = Boolean(parseFloat(amount) !== parseFloat(balance))
      onRefund(selectedPaymentId, selectedPaymentType, amount, isPartialRefund);
    }
  };

  const onOptionChange = (id) => {
    const selectedPayment = find(payments, {id});
    const refundedAmount = selectedPayment.attributes && selectedPayment.attributes.refundHistory && selectedPayment.attributes.refundHistory.length !== 0 ? 
      selectedPayment.attributes.refundHistory.reduce((a, b) => a + (b["amount"] || 0), 0) : 0;
    setSelectedPaymentId(id);
    setBalance(selectedPayment.attributes.amount - refundedAmount);
    setAmount(selectedPayment.attributes.amount - refundedAmount)
    setSelectedPaymentType(selectedPayment.attributes.paymentType)
  };

  const onAmountChange = (e) => {
    setAmount(e.target.value)
  }

  const action = [
    <HollowButton onClick={onClose} key="Cancel">Cancel</HollowButton>,
    <OrangeButton onClick={ev => handleRefund()} key="Next">Refund</OrangeButton>
  ];

  return (
    <Modal
      title="Refund Payments"
      actions={action}
      open={open}
      loading={loading}
      onClose={onClose}
    >
      <Wrapper>
        <Row>
          <Col sm={7}>
            <p className="title">Select Payment</p>
            {
              payments.map((p, idx) => {
                const refundedAmount = p.attributes && p.attributes.refundHistory && p.attributes.refundHistory.length !== 0 ? 
                  p.attributes.refundHistory.reduce((a, b) => a + (b["amount"] || 0), 0) : 0;
                return(
                  p.attributes.paymentType === 'cash' || p.attributes.paymentType === 'check' ?
                  <OtherOption
                    payment={p}
                    onSelect={id => onOptionChange(id)}
                    isSelected={selectedPaymentId === p.id}
                    key={`otheroption-${idx}`}
                    refundedAmount={refundedAmount || 0}
                  /> :
                  <CardOption
                    payment={p}
                    onSelect={id => onOptionChange(id)}
                    isSelected={selectedPaymentId === p.id}
                    key={`cardoption-${idx}`}
                    refundedAmount={refundedAmount || 0}
                  />
                )
              }
            )}
          </Col>
          <Col sm={5}>
            <p className="title">Refund Amount</p>
            <CurrencyInput 
              onChange={onAmountChange} 
              fixedDecimalScale 
              decimalScale={2} 
              defaultValue={balance} 
              value={amount} 
              disabled={false}
            />
          </Col>
        </Row>
      </Wrapper>
    </Modal>
  )
}

export default RefundPaymentModal;
