import React, { Fragment } from "react";
import axios from "axios";

import styled from "styled-components";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { toastr } from "react-redux-toastr";
import { isEmpty } from "lodash";

import { apiBaseUrl } from "api/config";
import LMCLogo from "../../resources/lmc-logo.png";

import FormContent from "./components/formContent";

const Img = styled.img`
  margin: 40px auto 0;
  flex-shrink: 0;
  width: 205px;
`;

const TitleThanksLabel = styled.div`
  font-size: 30px;
  color: #184961;
  margin-top: 10px;
  text-align: center;
`;

const DescThanksLabel = styled.div`
  font-size: 18px;
  margin: 5px auto;
  margin-bottom: 10px;
  color: #184961;
  width: 300px;
  text-align: center;
`;

const SectionWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const SectionContainer = styled.div`
  padding: 20px 50px;
`;

class ConfirmLmcAccount extends React.PureComponent {
  state = {
    loading: true
  };

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (
      query &&
      !isEmpty(query) &&
      Object.prototype.hasOwnProperty.call(query, "token")
    ) {
      const token = query.token;
      axios
        .get(`${apiBaseUrl}/users/confirmations/${token}`)
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(e => {
          toastr.error("Error", e.response.data[0]);
        });
    } else {
      setTimeout(
        () => toastr.error("Error", "Missing token to confirm account"),
        100
      );
    }
  }

  gotToWeb = () => {
    this.setState({ loading: true }, () => {
      window.location.href = "/";
    });
  };

  render() {
    const { loading } = this.state;

    return (
      <FormContent loading={loading}>
        <Fragment>
          <Img src={LMCLogo} />
          <SectionWrapper>
            <SectionContainer>
              <TitleThanksLabel>{"Thank You!"}</TitleThanksLabel>
              <DescThanksLabel>
                <span>
                  Your email has been confirmed.
                  <br />
                  You can now log in to your account.
                </span>
              </DescThanksLabel>
            </SectionContainer>
          </SectionWrapper>
        </Fragment>
      </FormContent>
    );
  }
}

export default withRouter(ConfirmLmcAccount);
