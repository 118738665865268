import React from "react";
import styled from "styled-components";
import LoadingSpinner from "components/basic/LoadingSpinner";
import BackgroundImage from "resources/lmc-background.jpg";

import "./../index.css";

const Wrapper = styled.div`
  background-image: url(${BackgroundImage});
  font-family: "Neutraface2-Text-Book";
  background-repeat: repeat;
  background-size: 300px;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  flex-shrink: 0;
`;

const ContentWrapper = styled.div`
  margin: auto 0px;
  margin-top: 30px;
  width: 100%;
  width: 550px;
  @supports (display: grid) {
    margin: auto 0px;
    box-shadow: 0 3px 20px 0 rgba(199, 199, 199, 0.5);
  }
`;

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #e8e6df;
`;

class FormContent extends React.PureComponent {
  render() {
    const { loading, children } = this.props;

    return (
      <Wrapper>
        <ContentWrapper>
          <ContainerWrapper>
            {children}
          </ContainerWrapper>
        </ContentWrapper>
        {loading && (
          <LoadingSpinner style={{ position: "fixed" }} loading={loading} />
        )}
      </Wrapper>
    );
  }
}

export default FormContent;
