import React from 'react';
import styled from 'styled-components';
import { findIndex, get } from 'lodash';
import deepEqual from 'fast-deep-equal';
import Modal from 'components/compound/Modal';
import { NormalText } from 'components/basic/Typho';

import { HollowButton, OrangeButton } from 'components/basic/Buttons';

import CreditCardOption from './CreditCardOption';
import CreationModal from './CreationModal';
import { getLastCreditCard } from 'utils/order';

const Wrapper = styled.div`
`;

const ActionWrapper = styled.div`
  margin-top: 22px;
  .add_card_button{
    margin-right: 0px;
    margin-left: 0px;
  }
`;

export const Label = styled(NormalText)`
  font-family: 'Open sans-serif', sans-serif;
  padding: 10px 0;
`;

const Title = styled.div`
  font-family: Montserrat, sans-serif;
  color: #07384b;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
`

export default class CreditCardSelector extends React.Component {
  constructor(props) {
    super(props);
    const idx = findIndex(props.creditCards, ({attributes: { isDefault }}) => isDefault)
    let id = get(props, `creditCards[${idx}].id`, -1)
    if(props.creditCards && props.creditCards.length > 0 && id === -1){
      const lastCreditCard = getLastCreditCard(props.creditCards);
      id = get(lastCreditCard, `id`, -1)
    }
    this.state = {
      selected: id,
      showNewPaymentModal: false,
      confirmRequest: false
    }
    props.onChange(id)
  }

  componentDidUpdate(prevProps) {
    if(!deepEqual(prevProps.creditCards, this.props.creditCards)) {
      const idx = findIndex(this.props.creditCards, ({attributes: { isDefault }}) => isDefault)
      let id = get(this.props, `creditCards[${idx}].id`, -1)
      if(this.props.creditCards && this.props.creditCards.length > 0 && id === -1){
        const lastCreditCard = getLastCreditCard(this.props.creditCards);
        id = get(lastCreditCard, `id`, -1)
      }
      this.setState({
        selected: id
      })
      this.props.onChange(id)
    }
  }

  onSelect = (selected) => {
    this.setState({ selected })
    this.props.onChange(selected)
  }

  showCreationModal = () => {
    this.setState({ showNewPaymentModal: true }, () => {
      this.props.toggleParent(true);
    });
  }

  closeCreationModal = () => {
    this.setState({ showNewPaymentModal: false }, () => {
      this.props.toggleParent(false);
    });
  }

  showConfirmRequestModal = () => {
    this.setState({ confirmRequest: true }, () => {
      this.props.toggleParent(true);
    });
  }

  closeConfirmRequestModal = () => {
    this.setState({ confirmRequest: false }, () => {
      this.props.toggleParent(false);
    });
  };

  confirmRequest = () => {
    const { user } = this.props;
    this.closeConfirmRequestModal();
    this.props.onRequest(user);
  }

  render() {
    const { creditCards, user, refreshCards, onRequest, privilege } = this.props;
    const { selected, showNewPaymentModal, confirmRequest } = this.state;

    const customerName = user ? `${user.firstName || ''} ${user.lastName || ''}` : '';

    const canRequest = Boolean(onRequest && user && user.type === "child_accounts" && privilege === "provider");

    const actions = [
      <HollowButton onClick={this.closeConfirmRequestModal} key="modal_btn_cancel">Cancel</HollowButton>,
      <OrangeButton onClick={this.confirmRequest} key="modal_btn_save">Confirm</OrangeButton>
    ];

    return (
      <Wrapper>
        <Title>Payment Method</Title>
        <React.Fragment>
          {
            creditCards.map((creditCard, idx) => (
              <CreditCardOption
                creditCard={creditCard}
                isSelected={selected === creditCard.id}
                onSelect={this.onSelect}
                key={`option_${idx}`}
              />
            ))
          }
        </React.Fragment>
        <ActionWrapper>
          <HollowButton onClick={this.showCreationModal} className={"add_card_button"}>Add Card</HollowButton>
          { canRequest && <HollowButton onClick={this.showConfirmRequestModal} >Request Card</HollowButton> }
        </ActionWrapper>
        <CreationModal
          user={user}
          open={showNewPaymentModal}
          onClose={this.closeCreationModal}
          refreshCards={refreshCards}
        />
         <Modal
          title={'Are You Sure?'}
          actions={actions}
          normal={true}
          open={confirmRequest}
          onClose={this.closeConfirmRequestModal}
        >
          <Label>Please confirm that you would like to request a new credit card {customerName ? `from ${customerName}` : ''}.</Label>
        </Modal>
      </Wrapper>
    );
  }
}