import { get, set, hasIn } from 'lodash';
import { thumbnailify } from 'utils/thumbnail';
import getHelpBoatUsImage from 'resources/serviceTemplate/pushForHelpBoatUs.png';
import getHelpImage from 'resources/serviceTemplate/pushForHelp.png';

export const setServiceTemplateData = (serviceInfo, templateInfo) => {
  const attributes = get(serviceInfo, 'attributes');
  const existingService = serviceInfo.hasOwnProperty('id');
  const { name } = attributes;
  const newTemplate = { ...templateInfo };
  const { data } = newTemplate;
  const newData = { ...data };

  set(newData, 'templateTitle', name);
  newData.templateTitle = name;
  if (hasIn(newData, 'data.description')) {
    if (existingService) {
      set(newData, 'data.description', get(attributes, 'description') || '');
    } else {
      const defaultData = get(newData, 'data.description');
      set(newData, 'data.description', get(attributes, 'description') || defaultData);
    }
  }
  if (hasIn(newData, 'data.secondaryDescription')) {
    if (existingService) {
      set(newData, 'data.secondaryDescription', get(attributes, 'secondaryDescription') || '');
    } else {
      const defaultData = get(newData, 'data.secondaryDescription');
      set(newData, 'data.secondaryDescription', get(attributes, 'secondaryDescription') || defaultData);
    }
  }
  if (hasIn(newData, 'data.cost')) {
    if (existingService) {
      set(newData, 'data.cost', get(attributes, 'cost'));
    } else {
      const defaultData = get(newData, 'data.cost');
      set(newData, 'data.cost', get(attributes, 'cost') || defaultData);
    }
  }
  if (hasIn(newData, 'data.unit')) {
    if (existingService) {
      set(newData, 'data.unit', get(attributes, 'costUnitText'));
    } else {
      const defaultData = get(newData, 'data.unit');
      set(newData, 'data.unit', get(attributes, 'costUnitText') || defaultData);
    }
  }
  const { additionalDetails } = attributes;

  if (additionalDetails) {
    const list = additionalDetails.split('\n');
    let listDescription = null;
    let listItems = [];
    if (list.length > 0) {

      if (list[0].substr(0, 1) === '*') {
        listDescription = list[0].substr(1);
      }
      if (list[0] === '*'){
        listDescription = ' ';
      }
      if (list.length > 1) {
        listItems = list.slice(1);
      }
    }

    if (hasIn(newData, 'data.listDescription')) {
      const defaultData = get(newData, 'data.listDescription');
      let newDescription = !listDescription ?  defaultData : listDescription === ' ' ? '' : listDescription;
      set(newData, 'data.listDescription', newDescription);
    }
    if (hasIn(newData, 'data.listItems')) {
      const defaultData = get(newData, 'data.listItems');
      const newListItems = [];
      for (let index = 0; index < listItems.length; index++) {
        const oldItem = get(defaultData, `${index}`);
        const newItem = get(listItems, `${index}`);
        newListItems.push(newItem || oldItem);
      }
      set(newData, 'data.listItems', newListItems);
    }
  }
  // if (hasIn(newData, 'data.unit')) {
  //   const defaultData = get(newData, 'data.unit');
  //   set(newData, 'data.unit', get(attributes, 'costType') || defaultData);
  // }
  return { ...newTemplate, data: { ...newData } };
}

export const setTemplateDataToServiceAttributes = (serviceInfo, templateInfo) => {
  const attributes = get(serviceInfo, 'attributes');
  
  return new Promise((resolve, reject) => {
    if(templateInfo){
      const { data, templateType } = templateInfo;

      if (hasIn(data, 'data.description')) {
        // const defaultValue = get(attributes, 'description');
        // set(attributes, 'description', get(data, 'data.description') || defaultValue);
        set(attributes, 'description', get(data, 'data.description'));
      }
      if (hasIn(data, 'data.secondaryDescription')) {
        // const defaultValue = get(attributes, 'secondaryDescription');
        // set(attributes, 'secondaryDescription', get(data, 'data.secondaryDescription') || defaultValue);
        set(attributes, 'secondaryDescription', get(data, 'data.secondaryDescription'));
      }else{
        set(attributes, 'secondaryDescription', null);
      }
      if (hasIn(data, 'data.cost')) {
        // const defaultValue = get(attributes, 'cost');
        // set(attributes, 'cost', get(data, 'data.cost') || defaultValue);
        set(attributes, 'cost', get(data, 'data.cost'));
      }
      if (hasIn(data, 'data.unit')) {
        set(attributes, 'unit', get(data, 'data.unit'));
      }
      let additionalDetails = [];
      if (hasIn(data, 'data.listDescription')) {
        const defaultData = get(data, 'data.listDescription');
        if (defaultData) {
          additionalDetails.push(`*${defaultData}`);
        }else{
          additionalDetails.push('*');
        }
      }
      if (hasIn(data, 'data.listItems')) {
        const defaultData = get(data, 'data.listItems');
        if (defaultData.length > 0) {
          additionalDetails = additionalDetails.concat(defaultData);
        }
      }

      if (additionalDetails.length > 0) {
        set(attributes, 'additionalDetails', additionalDetails.join('\n'));
      } else {
        const defaultValue = get(attributes, 'additionalDetails');
        if (defaultValue) {
          set(attributes, 'additionalDetails', additionalDetails.join('\n') || defaultValue);
        }
      }

      if(templateType === "get_help" || templateType === "boat_us_get_help"){
        if(templateType === "get_help"){
          thumbnailify(getHelpImage, 200, (base64) => {
            set(attributes, 'additionalDetails', base64);
            resolve(attributes);
          });
        }
    
        if(templateType === "boat_us_get_help"){
          thumbnailify(getHelpBoatUsImage, 200, (base64) => {
            set(attributes, 'additionalDetails', base64);
            resolve(attributes);
          });
        }
      }else{
        resolve(attributes);
      }
    }else{
      resolve(attributes);
    }
  });
  
  // if (hasIn(data, 'data.unit')) {
  //   const defaultValue = get(attributes, 'costType');
  //   set(attributes, 'data.unit', get(data, 'data.unit') || defaultValue);
  // }

}
