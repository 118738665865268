import React, { Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Table from "components/basic/Table";
import { GetContractors } from "store/actions/contractors";
import { PageTitle } from "components/basic/Typho";
import { OrangeButton } from "components/basic/Buttons";
import {
  SectionHeaderWrapper,
  LeftPart,
  RightPart
} from "components/basic/Header";

const Wrapper = styled.div`
  height: 100%;
  background-color: white;
`;

class TeamList extends React.Component {
  componentDidMount() {
    this.props.GetContractors({
      params: {
        per_page: 10,
        "provider_location_directory[team_member]": false,
        "provider_location_directory[sort]": "asc",
        "provider_location_directory[order]": "company_name"
      }
    });
  }

  toDetails = contractor => {
    this.props.history.push(`/contractor-details/?id=${contractor.id}`);
  };

  goToAddPage = () => {
    this.props.history.push(`/contractor-details/`);
  };

  changePage = page => {
    this.props.GetContractors({
      params: {
        page,
        per_page: 10,
        "provider_location_directory[team_member]": false,
        "provider_location_directory[sort]": "asc",
        "provider_location_directory[order]": "company_name"
      }
    });
  };

  getPageCount = () => {
    const { perPage, total } = this.props;
    return Math.ceil(total / perPage);
  };

  render() {
    const { contractors, page, locationName } = this.props;
    const pageCount = this.getPageCount();
    let title = "Contractors";
    let columns = [
      { label: "Provider Name", value: "companyName" },
      { label: "Contact Name", value: "name" },
      { label: "Phone", value: "phoneNumber", isPhone: true },
      { label: "email", value: "email" }
    ];

    if(locationName === "LMC"){
      columns = columns.concat([
        { label: "Type", value: "serviceType" }, 
        { label: "Publish", value: "published", isCheckbox: true }
      ]);
      title += "/Profecional Services";
    }

    return (
      <Fragment>
        <SectionHeaderWrapper>
          <LeftPart>
            <PageTitle>{title}</PageTitle>
          </LeftPart>
          <RightPart>
            <OrangeButton className="desktop" onClick={this.goToAddPage}>
              ADD CONTRACTOR
            </OrangeButton>
          </RightPart>
        </SectionHeaderWrapper>
        <Wrapper>
          <Table
            columns={columns}
            records={contractors}
            toDetails={this.toDetails}
            page={page}
            pageCount={pageCount}
            onPageChange={this.changePage}
          />
        </Wrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  contractor: { contractors, currentStatus, total, perPage, page },
  auth: { locationName }
}) => ({
  locationName,
  contractors,
  currentStatus,
  page: page ? page : 1,
  perPage: perPage ? perPage : 10,
  total: total ? total : 0
});

const mapDispatchToProps = {
  GetContractors
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TeamList)
);
