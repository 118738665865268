import styled from 'styled-components';

const Image = styled.img`
  width: 62.4px;
  height: 33.6px;
  object-fit: contain;
  margin-bottom: 18px;
  &.getHelp {
    width: 142px;
    height: 177px;
  }
`;

export default Image;
