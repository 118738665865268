import React from "react";
import styled from "styled-components";
import Trash from "resources/trash.svg";

const Btn = styled.div`
  width: 14px;
  float: right;
  display: inline-block;
  cursor: pointer;
`;
const Img = styled.img`
  width: 14px;
  object-fit: contain;
`;

export const RemoveButton = ({ onClick, ...rest }) => (
  <Btn {...rest} onClick={onClick}>
    <Img src={Trash} />
  </Btn>
);
