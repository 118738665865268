import React from 'react';
import styled from 'styled-components';

import FormFields from 'components/template/FormFields';

const Wrapper = styled.div`
  margin-bottom: 44px;
  width: 100%;
`;

const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003247;
`;

const FieldWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: #fafafa;
  margin-top: 25px;
  padding: 23px 25px 9px;
  .secondary{
    .field-section select {
      padding-left: 0px !important;
    }
    .field-section textarea {
      height: 36px;
      margin-top: 0px;
      min-height: unset;
      padding-top: 7px;
      padding-left: 10px;
    }
  }
`;

export default class CompanyInfo extends React.Component {
  getCompanyFieldInfo = () => {
    const { defaultValues: { name, street, city, state, zip, websiteUrl, serviceType, description }, locationName } = this.props;
    let companyFields = [
      {
        field: 'name',
        label: 'Company Name',
        type: 'text_field',
        className: 'secondary',
        defaultValue: name || '',
        errorMessage: 'Enter the company name',
        required: true,
        xs: 12,
        sm: 6,
        md: 3,
        lg: 3
      },
      {
        field: 'street',
        label: 'Address',
        type: 'text_field',
        className: 'secondary',
        defaultValue: street || '',
        errorMessage: 'Enter the address',
        required: false,
        xs: 12,
        sm: 6,
        md: 4
      },
      {
        field: 'city',
        label: 'City',
        type: 'text_field',
        className: 'secondary',
        defaultValue: city || '',
        errorMessage: 'Enter the city',
        required: false,
        xs: 12,
        sm: 4,
        md: 2
      },
      {
        field: 'state',
        label: 'State',
        type: 'text_field',
        className: 'secondary',
        defaultValue: state || '',
        errorMessage: 'Enter the state',
        required: false,
        xs: 12,
        sm: 4,
        md: 2
      },
      {
        field: 'zip',
        label: 'Zip',
        type: 'text_field',
        className: 'secondary',
        defaultValue: zip || '',
        errorMessage: 'Enter the zipcode',
        required: false,
        xs: 12,
        sm: 4,
        md: 1
      },
      {
        field: 'websiteUrl',
        label: 'Website URL',
        type: 'text_field',
        className: 'secondary',
        defaultValue: websiteUrl || '',
        xs: 12,
        sm: 6,
        md: 5,
        lg: 5,
        xl: 5
      }
    ];

    if(locationName === "LMC"){
      companyFields = companyFields.concat([{
        field: 'serviceType',
        label: 'Service Type',
        type: 'select_box',
        className: 'secondary select-big',
        errorMessage: 'Select Service Type',
        required: true,
        defaultValue: serviceType || 'contractor',
        options: [
          { value: 'contractor', label: 'Contractor' },
          { value: 'premier', label: 'Premier' },
          { value: 'professional', label: 'Professional' }
        ],
        xs: 6,
        sm: 6,
        md: 2,
        lg: 2,
        xl: 2
      },
      {
        type: "text_area",
        field: "description",
        className: 'secondary select-big',
        label: "Specialties:",
        required: false,
        defaultValue: description || '',
        xs: 12,
        sm: 5,
        md: 5,
        lg: 5,
        xl: 5
      }]);
    }

    return companyFields;
  }
  setRef = (ref) => {
    this.fields = ref;
  }
  getFieldValues = () => {
    return this.fields.getFieldValues();
  }
  validateFields = () => {
    return this.fields.validateFields();
  }
  render() {
    const fields = this.getCompanyFieldInfo();
    return (
      <Wrapper>
        <Title>Company Info</Title>
        <FieldWrapper>
          <FormFields ref={this.setRef} fieldSize="big" fields={fields} />
        </FieldWrapper>
      </Wrapper>
    )
  }
}