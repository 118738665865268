import React, { Fragment } from "react";
import axios from "axios";
import styled from "styled-components";
import queryString from "query-string";
import { get, orderBy, capitalize } from "lodash";
import { toastr } from "react-redux-toastr";
import { apiBaseUrl } from "api/config";

import { Row, Col } from "react-flexbox-grid";

import { HollowButton, OrangeButton } from "components/basic/Buttons";
import FormFields from "components/template/FormFields";
import Modal from "components/compound/Modal";

import LoadingSpinner from "components/basic/LoadingSpinner";
import CreditCard from "./CreditCard";

const Wrapper = styled(Row)`
  height: 35px;
  padding: 0 10px;
  > div {
    font-size: 10pt;
    line-height: 1.42857;
    font-weight: 600;
    font-family: Montserrat, sans-serif;
    color: #003247;
  }
`;

const CreditList = styled.div`
  padding-bottom: 20px;
`;

const Divider = styled.hr`
  margin: 20px 0;
  border-top: 1px solid #f1f1f1;
`;

const Text = styled.div`
  text-align: left;
  line-height: 24px;
  font-size: 16px;
  color: #003247;
`;

const Title = styled.div`
  font-size: 12pt;
  line-height: 1.42857;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: Montserrat, sans-serif;
  color: #003247;
`;

const RadioTitle = styled.div`
  text-align: center;
  width: 100%;
`;

const coutries = [
  {
    label: "United States",
    value: "US"
  },
  {
    label: "Canada",
    value: "CA"
  }
];

const cardFields = [
  {
    type: "text_field",
    field: "card_number",
    className: "primary",
    label: "CARD NUMBER",
    errorMessage: "Please enter a valid card number.",
    required: true,
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6
  },
  {
    type: "text_field",
    field: "month",
    className: "primary",
    label: "MONTH",
    mask: "99",
    required: true,
    errorMessage: "Required",
    xs: 12,
    sm: 6,
    md: 2,
    lg: 2,
    xl: 2
  },
  {
    type: "text_field",
    field: "year",
    className: "primary",
    label: "YEAR",
    required: true,
    errorMessage: "Required",
    mask: "9999",
    xs: 12,
    sm: 6,
    md: 2,
    lg: 2,
    xl: 2
  },
  {
    type: "text_field",
    field: "cvv",
    className: "primary",
    label: "CVV",
    required: true,
    errorMessage: "Required",
    xs: 12,
    sm: 6,
    md: 2,
    lg: 2,
    xl: 2
  }
];

let infoFields = [
  {
    type: "text_field",
    field: "first_name",
    className: "primary",
    label: "First Name",
    required: true,
    errorMessage: "Required",
    defaultValue: "",
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6
  },
  {
    type: "text_field",
    field: "last_name",
    className: "primary",
    label: "Last Name",
    required: true,
    errorMessage: "Required",
    defaultValue: "",
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6
  },
  {
    type: "text_field",
    field: "zip",
    className: "primary",
    label: "Zip Code",
    required: true,
    errorMessage: "Required",
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6
  },
  {
    type: "select_box",
    field: "country",
    className: "primary",
    label: "Country",
    options: coutries,
    defaultValue: coutries[0].value,
    required: true,
    errorMessage: "Choose Country",
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6
  }
];

class UpdatePaymentMethods extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createMode: false,
      thanks: false,
      confirm: false,
      loading: true,
      init: false,
      provider: "",
      token: "",
      creditCards: [],
      user: {},
      selected: '',
      selectedData: {}
    };
  }

  getCreditcards = () => {
    const { token } = this.state;
    axios
      .get(`${apiBaseUrl}/credit_card_requests?authorization_token=${token}`)
      .then(response => {
        const creditCardsResponse =
          response && response.data && response.data.data
            ? response.data.data
            : [];
        const user =
          response &&
          response.data &&
          response.data.included &&
          response.data.included[0] &&
          response.data.included[0].attributes
            ? response.data.included[0].attributes
            : {};

        const creditCards = creditCardsResponse.map(creditCard => {
          return {
            id: creditCard.id,
            attributes: {
              name: creditCard.attributes.name,
              last4: creditCard.attributes.last4,
              isDefault: false
            }
          };
        });

        const provider =
          response && response.headers && response.headers['x-provider-name']
            ? response.headers['x-provider-name']
            : "";

        this.setState({
          loading: false,
          init: true,
          creditCards,
          user,
          provider,
          createMode: Boolean(creditCards.length === 0)
        });
      })
      .catch(() => {
        this.setState({ loading: false, createMode: true });
        toastr.error("Error", "Invalid or Expired Token");
      });
  };

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.token) {
      this.setState({ token: query.token }, () => {
        this.getCreditcards();
      });
    } else {
      setTimeout(() => toastr.error("Error", "Token Not Found"), 100);
    }
  }

  handleSetDefault = creditCard => {
    const { creditCards } = this.state;

    let selectedData = {};

    for (let i = 0; i < creditCards.length; i++) {
      selectedData =  Boolean(
        creditCards[i].id === creditCard
      ) ? creditCards[i].attributes : selectedData;
      creditCards[i].attributes.isDefault = Boolean(
        creditCards[i].id === creditCard
      );
    }

    this.setState({ selected: creditCard, selectedData });
  };

  handleSaveSelection = () => {
    const { token, selected } = this.state;

    if (selected) {
      this.setState({ showConfirmModal: false, loading: true });
      axios
        .patch(`${apiBaseUrl}/credit_card_requests/${token}`, {
          credit_card_request: {
            credit_card_id: parseInt(selected)
          }
        })
        .then(() => {
          this.setState({ loading: false, confirm: false, thanks: true });
        })
        .catch(e => {
          this.setState({ loading: false });
          toastr.error("Error", get(e.response, "data.message"));
        });
    } else {
      toastr.error("Error", "No payment method selected");
    }
  };

  handleSave = data => {
    const { token } = this.state;

    this.setState({ loading: true });
    const creditCard = {
      card_number: data.card_number,
      cvv: data.cvv,
      card_last_name: data.last_name,
      card_first_name: data.first_name,
      card_zip_code: data.zip,
      expiration_month: parseInt(data.month),
      expiration_year: parseInt(data.year),
      card_country: data.country,
    };

    axios
      .patch(`${apiBaseUrl}/credit_card_requests/${token}`, {
        credit_card_request: {
          ...creditCard
        }
      })
      .then(() => {
        this.setState({ loading: false, confirm: false, thanks: true });
      })
      .catch(e => {
        this.setState({ loading: false });
        toastr.error("Error", get(e.response, "data.message"));
      });
  };

  saveCreditCard = () => {
    const cardsVailid = this.cardFields.validateFields();
    const infoValid = this.infoFields.validateFields();
    if (cardsVailid && infoValid) {
      const cardValue = this.cardFields.getFieldValues();
      const infoValues = this.infoFields.getFieldValues();
      const data = { ...cardValue, ...infoValues };
      this.handleSave(data);
    }
  };

  showCreation = () => {
    this.setState({ createMode: true, thanks: false });
  };

  hideCreation = () => {
    this.setState({ createMode: false, thanks: false });
  };

  showConfirm = () => {
    const { selected } = this.state;

    if (selected) {
      this.setState({ confirm: true });
    } else {
      toastr.error("Error", "No payment method selected");
    }
  };

  hideConfirm = () => {
    this.setState({ confirm: false });
  };

  setCardFieldsRef = ref => {
    this.cardFields = ref;
  };

  setInfoFieldsRef = ref => {
    this.infoFields = ref;
  };

  render() {
    const {
      creditCards,
      loading,
      user,
      createMode,
      provider,
      selectedData,
      thanks,
      init,
      confirm
    } = this.state;

    const orderCards = orderBy(creditCards, ["attributes.createdAt"], ["desc"]);
    const actionsSelect = [
      <OrangeButton key={"select_success"} onClick={this.showConfirm}>
        SUBMIT
      </OrangeButton>
    ];
    let actionsCreate = [];
    if (creditCards.length !== 0) {
      actionsCreate.push(
        <HollowButton key={"create_cancel"} onClick={this.hideCreation}>
          CANCEL
        </HollowButton>
      );
    }
    actionsCreate.push(
      <OrangeButton key={"create_success"} onClick={this.saveCreditCard}>
        SAVE
      </OrangeButton>
    );

    const actionsConfirm = [
      <HollowButton key={"confirm_cancel"} onClick={this.hideConfirm}>
        CANCEL
      </HollowButton>,
      <OrangeButton key={"confirm_success"} onClick={this.handleSaveSelection}>
        CONFIRM
      </OrangeButton>
    ];

    infoFields[0].defaultValue = get(user, "first_name");
    infoFields[1].defaultValue = get(user, "last_name");

    let modalButtons = undefined;

    if (thanks) {
      modalButtons = undefined;
    } else if (confirm) {
      modalButtons = actionsConfirm;
    } else if (createMode) {
      modalButtons = actionsCreate;
    } else if (!createMode) {
      modalButtons = actionsSelect;
    }

    return (
      <Wrapper>
        {!init && <LoadingSpinner />}
        <Modal
          title={thanks ? "Thank You!" : "Add Payment Method"}
          open={init}
          actions={modalButtons}
          loading={loading}
          onClose={() => {}}
          showCloseIcon={false}
        >
          <Fragment>
            {!createMode &&
              !thanks &&
              !confirm && (
                <Fragment>
                  <Title>
                    Please select a payment method to add to your account{provider && ` with ${provider}`}.
                  </Title>
                  <Row>
                    <Col sm={8}>
                      <Wrapper>
                        <Col sm={6}>{capitalize("Payment Method")}</Col>
                        <Col sm={4}>
                          <RadioTitle>{capitalize("Select")}</RadioTitle>
                        </Col>
                        <Col sm={2} />
                      </Wrapper>
                      <CreditList>
                        {orderCards.map((creditCard, i) => (
                          <CreditCard
                            creditCard={creditCard}
                            onSetDefault={this.handleSetDefault}
                            key={`credit_card_${creditCard.id}`}
                          />
                        ))}
                      </CreditList>
                    </Col>
                    <Col sm={4} />
                  </Row>
                  <HollowButton
                    key={"new_payment_method"}
                    onClick={this.showCreation}
                  >
                    New Payment Method
                  </HollowButton>
                </Fragment>
              )}
            {createMode &&
              !thanks &&
              !confirm && (
                <Fragment>
                  {creditCards.length === 0 && (
                    <Fragment>
                      <Title>
                        Please add a credit card to your account
                        {provider && ` with ${provider}`}.
                      </Title>
                      <Divider />
                    </Fragment>
                  )}
                  <FormFields ref={this.setCardFieldsRef} fields={cardFields} />
                  {creditCards.length !== 0 && <Divider />}
                  <FormFields ref={this.setInfoFieldsRef} fields={infoFields} />
                </Fragment>
              )}
            {confirm && (
              <Text>
                Please confirm that you would like to provide your {selectedData.name} ending in -{selectedData.last4}{provider && ` to ${provider}`}.
              </Text>
            )}
            {thanks && <Text>Your payment method has been securely stored. We appreciate your business and look forward to serving you.</Text>}
          </Fragment>
        </Modal>
      </Wrapper>
    );
  }
}

export default UpdatePaymentMethods;
