import React from "react";
import axios from "axios";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Form, Field } from "react-final-form";
import { Row, Col } from "react-flexbox-grid";
import { get, isEmpty } from "lodash";
import { toastr } from "react-redux-toastr";
import InputMask from "react-input-mask";

import { apiBaseUrl } from "api/config";
import { validateEmail, formatCurrency } from "utils/basic";
import { phoneValidation } from './../BoatHold';
import LoadingSpinner from "components/basic/LoadingSpinner";
import BackgroundImage from "resources/boatshow/boat_show_bg_v3.png";
import MMLogo from "resources/boatshow/mm_color_logo.png";
import { IntercomAPI } from "components/basic/Intercom";
import TagManager from 'react-gtm-module';

export const Wrapper = styled.div`
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  width: 100vw;
  justify-content: center;
  height: ${props =>
    props.height && props.currentHeight < props.height
      ? `${props.height}px`
      : "100vh"};
`;

export const ContentWrapper = styled.div`
  margin: auto 0px;
  width: 600px;
  height: ${props => (props.height ? `${props.height}px` : "500px")};
  @supports (display: grid) {
    height: unset;
  }
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 26px;
  overflow: hidden;
`;

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Img = styled.img`
  margin: 30px auto 0;
`;

export const TitleLabel = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 42px;
  color: #3d485c;
  letter-spacing: -1.75px;
  text-align: center;
  padding: 0 50px;
  margin-bottom: 10px;
`;

export const DescLabel = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #3d485c;
  text-align: justify;
  line-height: 28px;
  margin-top: 5px;
  padding: 0 78px 30px;
  .link {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  .last-row {
    text-align: center;
    width: 100%;
  }
  @media (max-width: 600px) {
    padding: 0 40px 30px;
  }
`;

export const SectionWrapper = styled.div`
  border-top: 2px solid #ececec;
  width: 100%;
`;

export const SectionContainer = styled.div`
  padding: 20px 50px;
`;

export const SectionTitle = styled.div`
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  color: #3d4a5e;
  text-align: left;
  margin-bottom: 15px;
  letter-spacing: 1.5px;
`;

export const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputField = styled(Field)`
  position: relative;
  height: 36px;
  width: 100%;
  background-color: #fff !important;
  padding: 0 10px;
  border: 1px solid #979797;
  border-radius: 8px;
  border-radius: 6px !important;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 5px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 19px;
  &:disabled {
    background: #f1f1f1;
  }
  &:-internal-autofill-selected,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
    background-color: #fff !important;
  }
  &::placeholder {
    color: #c7c7c7;
  }
`;

export const MaskInput = styled(InputMask)`
  position: relative;
  height: 36px;
  width: 100%;
  background-color: #fff !important;
  padding: 0 10px;
  border: 1px solid #979797;
  border-radius: 8px;
  border-radius: 6px !important;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 5px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 19px;
  &:disabled {
    background: #f1f1f1;
  }
  &:-internal-autofill-selected,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
    background-color: #fff !important;
  }
  &::placeholder {
    color: #c7c7c7;
  }
`;

export const ErrorMessage = styled.div`
  color: #f7941e;
  height: 15px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  text-transform: none;
`;

export const Footer = styled.div`
  background: #fafafa;
  width: 100%;
`;

const FooterInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const FooterTitle = styled.div`
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #3d4a5e;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

const FooterValue = styled.div`
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #3d4a5e;
  text-align: right;
`;

export const FooterActionWrapper = styled.div`
  text-align: center;
  padding: 10px 0;
`;
export const ActionButton = styled.button`
  background: #da3128;
  border-radius: 38.5px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  width: 240px;
  height: 40px;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <ErrorMessage>{error}</ErrorMessage> : <ErrorMessage />
    }
  />
);

export const emailValidation = value =>
  value ? (validateEmail(value) ? undefined : "Invalid Email") : "Required";
export const required = value => (value ? undefined : "Required");

class BoatHoldDeposit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cost: 1000,
      currentWidth: 1000,
      currentHeight: 0,
      tagManagerArgs: {},
      origin: window.location.pathname.includes("web") ? "web": "onlineexperience"
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    if(window.location.pathname.includes("/web")){
      const queryParams = queryString.parse(this.props.location.search);
      const tagManagerArgs = {
        dataLayer: {
          ...queryParams,
          page: "boatDeposit"
        },
        dataLayerName: 'MarineMax'
      };
      this.setState({ tagManagerArgs });
    }
  }


  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    const currentWidth = window.innerWidth;
    const currentHeight = window.innerHeight;
    this.setState({ currentWidth, currentHeight });
  };

  openChat = () => {
    IntercomAPI("show");
  };

  handleSubmit = values => {
    const { origin } = this.state;
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams && !isEmpty(queryParams)) {
      this.setState({ loading: true });
      const yacht = queryParams.yacht
        ? Boolean(queryParams.yacht)
        : queryParams.isYacht
          ? Boolean(parseInt(queryParams.isYacht))
          : false;
      const cost = queryParams.cost ? parseInt(queryParams.cost) : this.state.cost;
      
      let params = {
        deposit: {
          ...queryParams,
          yacht,
          cost,
          ...values
        }
      };

      if(origin === "web"){
        params.deposit.lead_source_details = "boat_hold";
      }

      axios
        .post(`${apiBaseUrl}/deposits`, params)
        .then(() => {
          this.setState({ loading: false });
          this.props.history.push(`/${origin}/boat-hold-done`);
        })
        .catch(e => {
          this.setState({ loading: false });
          const errorMessage = get(e.response, "data.message");
          if (errorMessage) {
            toastr.error("Error", get(e.response, "data.message"));
          } else {
            if (e.response && e.response.data && !isEmpty(e.response.data)) {
              for (let error in e.response.data) {
                for (let i in e.response.data[error]) {
                  toastr.error("Error", e.response.data[error][i]);
                }
              }
            }
          }
        });
    } else {
      toastr.error("Error", "Missing parameters");
    }
  };

  render() {
    const { loading, cost, currentWidth, currentHeight, tagManagerArgs } = this.state;

    if(origin === "web" && tagManagerArgs.dataLayer){
      TagManager.dataLayer(tagManagerArgs);
    }

    return (
      <Wrapper height={1200} currentHeight={currentHeight}>
        <ContentWrapper height={1100}>
          <ContainerWrapper>
            <Form
              onSubmit={this.handleSubmit}
              render={({ handleSubmit, submitting }) => (
                <FormContainer onSubmit={handleSubmit}>
                  <Img src={MMLogo} />
                  <TitleLabel>{"Almost There!"}</TitleLabel>
                  <DescLabel>
                    {
                      <div>
                        <div>
                          Reserve years of memorable experiences on the water
                          with a $1,000 deposit. A MarineMax sales consultant
                          will contact you to confirm the availability of your
                          boat and guide you through your{" "}
                          {currentWidth <= 600 && "purchase options."}
                        </div>
                        {currentWidth > 600 && (
                          <div className="last-row">purchase options.</div>
                        )}
                      </div>
                    }
                  </DescLabel>
                  <DescLabel onClick={this.openChat}>
                    {
                      <div style={{ fontWeight: "bold" }}>
                        Not ready to put a deposit down? Connect with one of our
                        friendly sales pro{" "}
                        <span className="link">here on chat</span> to place a
                        free hold on this boat.
                      </div>
                    }
                  </DescLabel>
                  <SectionWrapper>
                    <SectionContainer>
                      <SectionTitle>{"Contact"}</SectionTitle>
                      <Row>
                        <Col xs={12} sm={6} md={6}>
                          <InputRow>
                            <InputField
                              name="first_name"
                              component="input"
                              placeholder="First Name"
                              validate={required}
                            />
                            <Error name="first_name" />
                          </InputRow>
                        </Col>
                        <Col xs={12} sm={6} md={6}>
                          <InputRow>
                            <InputField
                              name="last_name"
                              component="input"
                              placeholder="Last Name"
                              validate={required}
                            />
                            <Error name="last_name" />
                          </InputRow>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={6} md={6}>
                          <InputRow>
                            <Field name="phone" validate={phoneValidation}>
                              {props => (
                                <div>
                                  <MaskInput
                                    {...props.input}
                                    placeholder="Phone"
                                    mask={"(999) 999-9999"}
                                  />
                                </div>
                              )}
                            </Field>
                            <Error name="phone" />
                          </InputRow>
                        </Col>
                        <Col xs={12} sm={6} md={6}>
                          <InputRow>
                            <InputField
                              name="email"
                              type="email"
                              component="input"
                              placeholder="Email"
                              validate={emailValidation}
                            />
                            <Error name="email" />
                          </InputRow>
                        </Col>
                      </Row>
                    </SectionContainer>
                  </SectionWrapper>
                  <SectionWrapper>
                    <SectionContainer>
                      <SectionTitle>{"Payment Info"}</SectionTitle>
                      <Row>
                        <Col xs={12} sm={12} md={7}>
                          <InputRow>
                            <InputField
                              name="card_number"
                              component="input"
                              placeholder="Card Number"
                              validate={required}
                            />
                            <Error name="card_number" />
                          </InputRow>
                        </Col>
                        <Col xs={6} sm={6} md={3}>
                          <InputRow>
                            <Field name="expiration_date" validate={required}>
                              {props => (
                                <div>
                                  <MaskInput
                                    {...props.input}
                                    placeholder="Exp Date"
                                    mask={"99/99"}
                                  />
                                </div>
                              )}
                            </Field>
                            <Error name="expiration_date" />
                          </InputRow>
                        </Col>
                        <Col xs={6} sm={6} md={2}>
                          <InputRow>
                            <InputField
                              name="cvv2"
                              component="input"
                              placeholder="CCV"
                              validate={required}
                            />
                            <Error name="cvv2" />
                          </InputRow>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <InputRow>
                            <InputField
                              name="street"
                              component="input"
                              placeholder="Billing Address"
                              validate={required}
                            />
                            <Error name="street" />
                          </InputRow>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={5} md={5}>
                          <InputRow>
                            <InputField
                              name="city"
                              component="input"
                              placeholder="City"
                              validate={required}
                            />
                            <Error name="city" />
                          </InputRow>
                        </Col>
                        <Col xs={6} sm={5} md={5}>
                          <InputRow>
                            <InputField
                              name="state"
                              component="input"
                              placeholder="State"
                              validate={required}
                            />
                            <Error name="state" />
                          </InputRow>
                        </Col>
                        <Col xs={6} sm={2} md={2}>
                          <InputRow>
                            <InputField
                              name="zip"
                              component="input"
                              placeholder="Zip"
                              validate={required}
                            />
                            <Error name="zip" />
                          </InputRow>
                        </Col>
                      </Row>
                    </SectionContainer>
                  </SectionWrapper>
                  <Footer>
                    <SectionWrapper>
                      <SectionContainer>
                        <FooterInnerWrapper>
                          <FooterTitle>{"Deposit"}</FooterTitle>
                          <FooterValue>{formatCurrency(cost)}</FooterValue>
                        </FooterInnerWrapper>
                      </SectionContainer>
                    </SectionWrapper>
                    <SectionWrapper>
                      <SectionContainer>
                        <FooterActionWrapper>
                          <ActionButton type="submit" disabled={submitting}>
                            {"Reserve My Boat"}
                          </ActionButton>
                        </FooterActionWrapper>
                      </SectionContainer>
                    </SectionWrapper>
                  </Footer>
                </FormContainer>
              )}
            />
          </ContainerWrapper>
        </ContentWrapper>
        {loading && (
          <LoadingSpinner style={{ position: "fixed" }} loading={loading} />
        )}
      </Wrapper>
    );
  }
}

export default withRouter(BoatHoldDeposit);
