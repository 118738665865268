import React from 'react';
import { connect } from 'react-redux';
import { orderBy, find } from 'lodash';
import PropTypes from "prop-types";
import styled from 'styled-components';

import { Section } from 'components/basic/InfoSection';
import { NormalText } from 'components/basic/Typho';
import Modal from 'components/compound/Modal';
import InfoSection from './InfoSection';
import ListModal from './ListModal';
import CreationModal from './CreationModal';
import { DeleteCreditCard } from 'store/actions/credit-cards';

import { HollowButton, OrangeButton, EditButton } from 'components/basic/Buttons';

export const Label = styled(NormalText)`
  font-family: 'Open sans-serif', sans-serif;
  padding: 10px 0;
`;

export const EditWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
`;

export const AddCardButton = styled.div`
  margin-top: 30px;
`;

class CreditCardSection extends React.Component {
  state = {
    showPaymentModal: false,
    showNewPaymentModal: false,
    confirmRequest: false,
    confirmDelete: false,
    removeId: null,
    selectedCard: null
  };

  showListModal = () => {
    this.setState({
      showPaymentModal: true,
      selectedCard: null
    })
  };

  closeListModal = () => {
    this.setState({
      showPaymentModal: false,
      selectedCard: null
    })
  };

  showCreationModal = () => {
    this.setState({
      showListModal: false,
      showNewPaymentModal: true,
      selectedCard: null
    })
  };

  closeCreationModal = () => {
    this.setState({
      showNewPaymentModal: false,
      selectedCard: null
    })
  };

  hideConfirmRequestModal = () => {
    this.setState({
      confirmRequest: false,
      selectedCard: null
    })
  };

  hideConfirmDeleteModal = () => {
    this.setState({
      confirmDelete: false,
      selectedCard: null
    })
  };

  showConfirmRequestModal = () => {
    this.setState({
      confirmRequest: true,
    })
  }

  showConfirmDeleteModal = (removeId) => {
    this.setState({
      confirmDelete: true,
      removeId
    })
  }

  confirmRequest = () => {
    this.hideConfirmRequestModal();
    this.props.onRequest();
  }

  confirmDelete = () => {
    const { removeId } = this.state;
    this.hideConfirmDeleteModal();
    if(removeId){
      this.props.DeleteCreditCard({
        creditCardId: removeId,
        success: () => {
          this.setState({ removeId: null }, () => {
            this.props.onRefresh();
          });
        }
      })
    }
  }

  editCreditCard = (id) => {
    const { creditCards } = this.props;
    const selectedCard = find(creditCards, {id}, null);

    this.setState({
      showListModal: false,
      showNewPaymentModal: true,
      selectedCard
    })
  }

  render() {
    const { creditCards, customerName, isChildAccount, privilege } = this.props;
    const orderCards = orderBy(creditCards, ['attributes.isDefault'], ['desc']);
    const { selectedCard, showPaymentModal, showNewPaymentModal, confirmRequest, confirmDelete } = this.state;
    const { user } = this.props;

    const actionsConfirmRequest = [
      <HollowButton onClick={this.hideConfirmRequestModal} key="modal_btn_cancel">Cancel</HollowButton>,
      <OrangeButton onClick={this.confirmRequest} key="modal_btn_save">Confirm</OrangeButton>
    ];

    const actionsConfirmDelete = [
      <HollowButton onClick={this.hideConfirmDeleteModal} key="modal_btn_cancel">Cancel</HollowButton>,
      <OrangeButton onClick={this.confirmDelete} key="modal_btn_save">Confirm</OrangeButton>
    ];

    const editComponent = <div>
      {isChildAccount && privilege === "provider" && 
        <HollowButton onClick={this.showConfirmRequestModal} key="modal_btn_cancel">REQUEST CARD</HollowButton> 
      }
      {(creditCards && creditCards.length > 0) && 
        <EditWrapper><EditButton onClick={this.showListModal} /></EditWrapper>
      }
    </div>;

    return (
      <React.Fragment>
        <Section title="Payment Methods" mode="view" disabled={user.isDisabled} editComponent={editComponent} >
          {(creditCards && creditCards.length > 0) ?
            <React.Fragment>
              {orderCards.map(card => (
                <InfoSection 
                  key={`card_${card.id}`} 
                  creditCard={card.attributes} 
                />
              ))}
            </React.Fragment>
           :
           <NormalText>There are no payment methods.</NormalText>
          }
          <AddCardButton>
            <OrangeButton onClick={this.showCreationModal} key="modal_btn_save">ADD CARD</OrangeButton>
          </AddCardButton>
        </Section>
        <ListModal
          open={showPaymentModal}
          onClose={this.closeListModal}
          onEdit={this.editCreditCard}
          onRemove={this.showConfirmDeleteModal}
          refreshCards={this.props.onRefresh}
        />
        <CreationModal
          user={user}
          selectedCard={selectedCard}
          open={showNewPaymentModal}
          onClose={this.closeCreationModal}
          refreshCards={this.props.onRefresh}
        />
        <Modal
          title={'Are You Sure?'}
          actions={actionsConfirmRequest}
          normal={true}
          open={confirmRequest}
          onClose={this.hideConfirmRequestModal}
        >
          <Label>Please confirm that you would like to request a new credit card from {customerName}.</Label>
        </Modal>
        <Modal
          title={'Are You Sure?'}
          actions={actionsConfirmDelete}
          normal={true}
          open={confirmDelete}
          onClose={this.hideConfirmDeleteModal}
        >
          <Label>Please confirm that you would like to delete this credit card.</Label>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ creditCard: { creditCards } }) => ({
  creditCards
});

const mapDispatchToProps = {
  DeleteCreditCard
};

CreditCardSection.propTypes = {
  customerName: PropTypes.string,
  isChildAccount: PropTypes.bool,
  privilege: PropTypes.string,
  onRequest: PropTypes.func,
};

CreditCardSection.defaultProps = {
  customerName: "",
  isChildAccount: false,
  privilege: '',
  onRequest: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardSection)
