import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-flexbox-grid";
import { Form } from "react-final-form";
//import InputMask from "react-input-mask";
import { toastr } from "react-redux-toastr";
import { isMobile } from "react-device-detect";
import BackgroundImage from "../../resources/sendapp/app_bg.png";
import MMLogo from "../../resources/sendapp/mm_logo_white.png";
import BoatyardLogo from "../../resources/by_logo_white.png";

import LoadingSpinner from "components/basic/LoadingSpinner";

const Wrapper = styled.div`
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  margin: auto 0px;
  background-color: rgba(236, 236, 236, 0.25);
  width: 382px;
  //height: ${props => `${props.size}px`};
`;

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Img = styled.img`
  margin: 40px auto 0;
  width: ${props => `${props.size}px`};
`;

const TitleLabel = styled.div`
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  line-height: 29px;
  margin-bottom: 6px;
`;

const DescLabel = styled.div`
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  margin-bottom: 36px;
`;

const SectionWrapper = styled.div`
  width: 100%;
`;

const SectionContainer = styled.div`
  padding: 30px 30px;
  text-align: center;
`;

const QRContainer = styled.div`
  padding: 0px 20px;
  img {
    margin: 0px;
    width: 100%;
  }
`;

/*
const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MaskInput = styled(InputMask)`
  position: relative;
  height: 40px;
  width: 100%;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 6px;
  outline: none;
  box-sizing: border-box;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  border: none;
  &:disabled {
    background: #f1f1f1;
  }
  &:-internal-autofill-selected,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
    background-color: #fff !important;
  }
  &::placeholder {
    color: #cccccc;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  height: 15px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 600;
  text-transform: none;
  text-align: left;
`;

const ActionButton = styled.button`
  height: 51px;
  width: 273px;
  border-radius: 10px;
  background-color: #0767a7;
  font-family: "Roboto", sans-serif;
  color: #f8f6ed;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  font-weight: 700;
  border: none;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;
  &:disabled {
    opacity: 0.8;
  }
`;

/*
const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <ErrorMessage>{error}</ErrorMessage> : <ErrorMessage />
    }
  />
);

const required = value => (value ? undefined : "Phone number cannot be blank.");
*/

class SendApp extends React.PureComponent {
  state = {
    app: "Boatyard",
    loading: false
  };

  componentDidMount() {
    const app = window.location.href.includes("proapp")
      ? "Boatyard Pro"
      : "Boatyard";

    if (isMobile) {
      const name = this.platform();
      window.location.replace(
        name.includes("Boatyard")
          ? "https://boatyardapp.com/desktop"
          : "https://getapp.marinemax.com/desktop"
      );
    }
    this.setState({ app, loading: false });
  }

  handleSubmit = values => {
    const name = this.platform();
    const isBoatUs = window.location.href.includes("boatus");
    this.setState({ loading: true });
    window.branch.init(
      name === "Boatyard Pro"
        ? "key_live_mbTrNkh9AAdu9byuxQfHdpplBubn376R"
        : name === "Boatyard"
          ? "key_live_kjAohWbxsSo9MXXaPVqn7egatApwfrgu"
          : "key_live_clKCMal7vDaCEW3EiM5xgacnvsbe80dP"
    );

    let phone = values.phone;
    var callback = (err, result) => {
      this.setState({ loading: false });
      if (err) {
        toastr.error("Something went wrong. Validate phone number.");
      } else {
        toastr.success("Success", "SMS Sent Successfully!");
      }
    };

    if (isBoatUs) {
      const linkData = {
        tags: [],
        channel: "Website",
        feature: "Text-me-the-app",
        data: {
          $deeplink_path: `custom_deeplink_path`,
          $custom_sms_text:
            "Click here to download the BoatUS app on Boatyard {{ link }}",
          $desktop_url: "https://boatyardapp.com/boatus",
          $ios_url: "https://boatyardapp.com/boatus",
          $ipad_url: "https://boatyardapp.com/boatus",
          $android_url: "https://boatyardapp.com/boatus"
        }
      };
      window.branch.sendSMS(phone, linkData, { make_new_link: true }, callback);
    } else {
      window.branch.sendSMS(phone, {}, {}, callback);
    }
  };

  platform = () => {
    if (window.location.href.includes("proapp")) {
      return "Boatyard Pro";
    } else if (window.location.href.includes("marinemaxapp")) {
      return "MarineMax";
    } else {
      return "Boatyard";
    }
  };

  render() {
    const { loading } = this.state;
    const name = this.platform();

    const QR = "https://boatyard.com/download_app_qr.jfif";
    const QR_mmax = "https://boatyard.com/download_app_qr_mmax.jfif";

    return (
      <Wrapper>
        <ContentWrapper size={name.includes("Boatyard") ? 525 : 446}>
          <ContainerWrapper>
            <Form
              onSubmit={this.handleSubmit}
              render={({ handleSubmit, submitting }) => (
                <FormContainer onSubmit={handleSubmit}>
                  <Img
                    src={name.includes("Boatyard") ? BoatyardLogo : MMLogo}
                    size={name.includes("Boatyard") ? 266 : 231}
                  />
                  <SectionWrapper>
                    <SectionContainer>
                      <Row>
                        <Col xs={12}>
                          <TitleLabel>{`Welcome To ${name}!`}</TitleLabel>
                          <DescLabel
                          >{`Please scan the QR code below with your mobile device to download the ${name} app.`}</DescLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <QRContainer>
                            <Img
                              src={name.includes("Boatyard") ? QR : QR_mmax}
                            />
                          </QRContainer>
                        </Col>
                      </Row>
                      {/*
                      <Row>
                        <Col xs={12}>
                          <InputRow style={{ maxWidth: 273, margin: "auto" }}>
                            <Field name="phone" validate={required}>
                              {props => (
                                <div>
                                  <MaskInput
                                    {...props.input}
                                    placeholder="enter phone number"
                                    mask={"(999) 999-9999"}
                                  />
                                </div>
                              )}
                            </Field>
                            <Error name="phone" />
                          </InputRow>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <ActionButton
                            type="submit"
                            disabled={loading || submitting}
                          >
                            {"Text Me the app"}
                          </ActionButton>
                        </Col>
                      </Row>*/}
                    </SectionContainer>
                  </SectionWrapper>
                </FormContainer>
              )}
            />
          </ContainerWrapper>
        </ContentWrapper>
        {loading && <LoadingSpinner />}
      </Wrapper>
    );
  }
}

export default withRouter(SendApp);
