import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";
import { capitalize } from "lodash";
import { formatCard, formatTwoDigitsMonth } from 'helpers';   
import CheckedMarker from "resources/checked_marker.png";

const CardInfo = styled.div`
  color: #094359;
  font-family: "Source Sans", sans-serif !important;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
`;

const ExpirationInfo = styled.div`
  color: #898889;
  font-size: 12px;
  opacity: 0.8;
  font-family: "Source Sans", sans-serif !important;
  text-align: left;
`;

const MarkerImg = styled.img`
  width: 23px;
  height: 20px;
  float: right;
`;

export default ({
  creditCard: { name, last4, expirationMonth, expirationYear, isDefault }
}) => (
  <Row style={{ marginBottom: 10 }}>
    <Col sm={6}>
      <CardInfo>
        {formatCard(capitalize(name))} xxxx
        {last4}
      </CardInfo>
    </Col>
    <Col sm={4}>
      <ExpirationInfo>
        Exp {formatTwoDigitsMonth(expirationMonth)}/{expirationYear}
      </ExpirationInfo>
    </Col>
    {isDefault && (
      <Col sm={2}>
        <MarkerImg src={CheckedMarker} />
      </Col>
    )}
  </Row>
);
