const mode = process.env.REACT_APP_STAGE;

let apiUrl = '';
let locationApiUrl = '';
let revisedApiUrl = '';
let cdnUrl = 'https://cdn-staging.boatyard.com/uploads/staging/';
switch (mode) {
  case 'sandbox':
    apiUrl = 'https://sandbox-api.boatyard.com/api/v2';
    locationApiUrl = 'https://sandbox-api.boatyard.com/api/v3';
    revisedApiUrl = 'https://sandbox-api.boatyard.com/api/v4';
    break;
  case 'staging':
    apiUrl = 'https://staging-api.boatyard.com/api/v2';
    locationApiUrl = 'https://staging-api.boatyard.com/api/v3';
    revisedApiUrl = 'https://staging-api.boatyard.com/api/v4';
    break;
  case 'production':
    apiUrl = 'https://production-api.boatyard.com/api/v2';
    locationApiUrl = 'https://production-api.boatyard.com/api/v3';
    revisedApiUrl = 'https://production-api.boatyard.com/api/v4';
    cdnUrl = 'https://cdn-production.boatyard.com/uploads/';
    break;
  case 'local':
    apiUrl = 'http://localhost:3001/api/v2';
    locationApiUrl = 'http://localhost:3001//api/v3';
    revisedApiUrl = 'http://localhost:3001//api/v4';
    break;
  default:
    apiUrl = 'https://staging-api.boatyard.com/api/v2';
    locationApiUrl = 'https://staging-api.boatyard.com/api/v3';
    revisedApiUrl = 'https://staging-api.boatyard.com/api/v4';    
}

export const apiBaseUrl = apiUrl;
export const locationApiBaseUrl = locationApiUrl;
export const revisedApiBaseUrl = revisedApiUrl;
export const cdnBaseUrl = cdnUrl;
export const googleApiKey = 'AIzaSyDghecojWN6kq8467WZxlipKgIY_evSTJM';
export const spreedlyApiUrl = 'https://core.spreedly.com/v1/payment_methods.json?environment_key=7LAxQHurhgD3H3bqnU3XTtXjXlt';
export const spreedlyApiToken = '3Xp9txPFczmh4YA7PXaO4QOmShmjaNaRyIp44d8Ya347uxS8O3xH3NfPG2KSP7wk=';
export const intercomAppId = 'oshrxea5';
export const mmIntercomAppId = 'gykkzpcq';
export const lmcIntercomAppId = 'nymvqaxk';
export const mmGtmId = 'GTM-PC7VK27';
