import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { START_DATE, END_DATE, HORIZONTAL_ORIENTATION, ANCHOR_LEFT } from './constants';
import omit from 'lodash/omit';
import FilterIcon from '../../../resources/filter_icon.png';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  position: relative;
  margin-left: 5px;
  margin-top: -3px;
`;

const DropdownMenu = styled.div`
  &.show {
    display: block;
  }
  position: absolute;
  font-family: 'Source Sans Pro', sans-serif;
  display: none;
  border: 1px solid #eaeaea;
  left: -136px;
  background-color: white;
  position: absolute;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  width: ${props => props.size > 380 ? `${props.size - 1}px` : '380px'};
  z-index: 1;
  margin-top: 27px;
  .resetDate {
    margin-top: 11px;
    margin-bottom: 0px;
    width: 50px;
    font-size: 14px;
    float: right;
    &:hover {
      color: #f7941e;
    }
  }
  .closeDate {
    margin-top: 11px;
    margin-bottom: 0px;
    width: 50px;
    font-size: 14px;
    float: right;
    &:hover {
      color: #f7941e;
    }
  }
  .DateRangePickerInput_calendarIcon {
    outline: none;
    margin-left: 5px;
  }
  .DateRangePickerInput_calendarIcon_svg {
    &:hover {
      fill: unset;
    }
  }
  .DateRangePickerInput__withBorder {
    border: none;
  }
  .DateInput_input__focused {
    border-bottom: 2px solid #f7941e;
  }
  .DateInput_input {
    padding-top: 10px;
    font-size: 16px;
    font-family: inherit;
    &:hover {
      border-bottom: 2px solid #f7941e;
    }
  }
  .CalendarDay__selected_span {
    background: #f7941e;
    color: white;
    border: 1px double #f7941e;
  }
  .CalendarDay__selected {
    background: #f7941e;
    color: white;
    &:hover {
      background: #f7941e;
      border: 1px double #f7941e;
    }
  }
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: #f7941e;
    opacity: 0.5;
  }
  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }
  .DateRangePickerInput_clearDates  {
    display: none;
  }
  .DateInput_fang {
    top: 42px;
  }
`;

const Backdrop = styled.div`
  &.show {
    display: block;
  }
  display: none;
  cursor: default;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1;
`;

const Img = styled.img`
  float: right;
`;

const propTypes = {
  // example props for the demo
  handleDatesChange: PropTypes.func,
  currentSize: PropTypes.number
}
const defaultProps = {
  // example props for the demo
  autoFocus: false,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  currentSize: 380,
  presets: [],

  // input related props
  startDateId: START_DATE,
  startDatePlaceholderText: 'Start Date',
  endDateId: END_DATE,
  endDatePlaceholderText: 'End Date',
  disabled: false,
  required: false,
  screenReaderInputMessage: '',
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  handleDatesChange() {},
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},

  // day presentation and interaction related props
  renderDayContents: null,
  minimumNights: 0,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: day => false,
  isDayHighlighted: () => false,

  // internationalization
  displayFormat: () => moment.localeData().longDateFormat('L'),
  monthFormat: 'MMMM YYYY'
};

export class DateSelectionFilter extends React.Component {
  constructor() {
    super();

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
   document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        showMenu: false
      });
    }
  }

  handleDatesChange = (dates) => {
    const { startDate, endDate } = dates;
    this.setState({ startDate, endDate }, () => this.changeDates());
  } 

  changeDates = () => {
    const { startDate, endDate } = this.state;
    const { handleDatesChange } = this.props;
    handleDatesChange(startDate, endDate);
  }

  toogleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  }

  handleResetDate = () => {
    this.setState({endDate: null, startDate: null}, () => this.changeDates());
  }

  state = {
    showMenu: false,
    startDate: null,
    endDate: null,
    focusedInput: null
  };

  render() {
    const { showMenu, startDate, endDate } = this.state;
    const { currentSize } = this.props;
    const DatePickerProps = omit(this.props, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'presets',
      'handleDatesChange',
      'currentSize'
    ]);

    return (
      <Wrapper ref={this.setWrapperRef}>
        <Img 
          alt=''
          src={FilterIcon}
          onClick={this.toogleMenu} 
        />
        <Backdrop 
          className={showMenu ? 'show' : 'hide'}
          onClick={this.toogleMenu} 
        />
        <DropdownMenu 
          className={showMenu ? 'show' : 'hide'}
          size={Math.round(currentSize)}
        >
          <DateRangePicker
            {...DatePickerProps}
            startDate={this.state.startDate} 
            startDateId="your_unique_start_date_id"
            endDate={this.state.endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={this.handleDatesChange}
            focusedInput={this.state.focusedInput}
            onFocusChange={focusedInput => this.setState({ focusedInput })}
            showDefaultInputIcon={true}
            small={true}
            showClearDates={true}
          />
          <div className="closeDate" onClick={this.toogleMenu}>close</div>
          {(startDate !== null || endDate !== null) && <div className="resetDate" onClick={this.handleResetDate}>reset</div>}
        </DropdownMenu>
      </Wrapper>
    );
  }
}
DateSelectionFilter.propTypes = propTypes;
DateSelectionFilter.defaultProps = defaultProps;



