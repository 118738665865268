import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-flexbox-grid'

import { CurrencyInput } from 'components/basic/Input';

const Label = styled.div`
  color: #8f8f8f;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  line-height: 20px;
`;

const Value = styled.div`
  color: #8f8f8f;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  line-height: 20px;
`;

const Placeholder = styled.span`
  cursor: pointer;
  color: #ffbc48;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  line-height: 20px;
  &:hover {
    color: #d56f12;
  }
`;

const UpdatedRow = styled(Row)`
  min-height: 40px;
  align-items: center;
`;

export default class TaxEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      edit: false,
      value: props.value
    }
  }

  setEdit = () => {
    this.setState({
      edit: true
    })
  }

  resetEdit = () => {
    this.setState({
      edit: false,
    })
  }

  onChange = (values) => {
    this.props.onChange(values.value);
    let newValue = (parseFloat(values.value) <= 0)? 0 : Number(values.value);
    this.setState({
      value: newValue
    })
  }

  render() {
    const { edit, value } = this.state;
    const { taxAmount } = this.props;

    return edit ? (
      <UpdatedRow>
        <Col xs={6}>
          <CurrencyInput
            style={{ marginBottom: 0 }}
            fixedDecimalScale
            decimalScale={3}
            value={value}
            onBlur={this.resetEdit}
            onValueChange={this.onChange} />

        </Col>
        <Col xs={6}>
          <Value>
            ${parseFloat(taxAmount).toFixed(2)}
          </Value>
        </Col>
      </UpdatedRow>
    ) : (
      <UpdatedRow>
        <Col xs={6}>
          <Label>
            {'Tax '}
            <Placeholder onClick={this.setEdit}>
              ({`${value}%`})
            </Placeholder> :
          </Label>
        </Col>
        <Col xs={6}>
          <Value>
            ${parseFloat(taxAmount).toFixed(2)}
          </Value>
        </Col>
      </UpdatedRow>    
    )
  }
}