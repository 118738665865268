import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import BackgroundImage from "resources/auth/login-bg.png";
import MarineMaxBackgroundImage from "resources/auth/mm-bg.jpg";
import { Col } from "react-flexbox-grid";
import { apiBaseUrl } from "api/config";
import { get, orderBy } from "lodash";

const locationBackground = window.location.href.includes("marinemax")
  ? MarineMaxBackgroundImage
  : BackgroundImage;
export const Wrapper = styled.div`
  //background-image: url(${locationBackground});
  background-color: #EEEEEE;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-family: Montserrat, sans-serif;
  width: 100vw;
  min-height: 200vh;
  @supports (display: grid) {
    overflow: hidden;
    min-height: 100vh;
  }
`;

export const ContentWrapper = styled.div`
  margin: auto 0px;
  width: ${props => (props.currentWidth < 700 ? `unset` : "700px")};
  height: ${props => (props.height ? `${props.height}px` : "500px")};
  padding-top: 40px;
  padding-bottom: 40px;
  @supports (display: grid) {
    height: unset;
  }
  @media (max-width: 699px) {
    width: unset;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media print {
    width: 90%;
  }
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #e7e7e7;
`;

export const ContentHeader = styled.div`
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
  span {
    font-weight: 900;
    font-size: 35px;
    padding: 60px;
    color: #000;
  }
`;

export const ContentInfo = styled.div`
  border-bottom: 1px solid #e7e7e7;
  font-size: 16px;
  width: 100%;
  margin-top: 20px;
  .row {
    margin-bottom: 20px;
    padding: 20px 60px;
  }
  span {
    font-family: "Open Sans", sans-serif;
    margin-bottom: 10px;
    width: 100%;
    display: block;
    opacity: 0.7;
  }
  .right {
    text-align: right !important;
  }
  .logoXS {
    display: none;
  }
  @media (max-width: 699px) {
    .logoXS {
      display: block;
    }
    .right {
      text-align: left !important;
    }
    .rightInfo {
      margin-top: 60px;
    }
  }

  @media print {
    .logoXS {
      display: none;
    }
    .right {
      text-align: right !important;
    }
    .rightInfo {
      margin-top: 20px;
    }
  }
`;

export const CustomCol = styled(Col)`
  @media print {
    flex-basis: 50% !important;
    max-width: 50% !important;
    padding-left: 5px !important;
  }
`;

export const ContentInfoItems = styled.div`
  border-bottom: 1px solid #e7e7e7;
  font-size: 16px;
  width: 100%;

  .itemRow {
    margin-bottom: 10px;
  }

  .itemRow:last-child {
    margin-bottom: 0px;
  }

  span {
    font-family: "Open Sans", sans-serif;
    color: #000000;
    opacity: 0.7;
  }

  .divisor {
    border-bottom: 1px solid #e7e7e7;
    padding: 20px 60px;
  }

  .service-row {
    margin-bottom: 40px;
  }

  @media (max-width: 699px) {
    text-align: right !important;
    .firstColumn {
      text-align: left !important;
    }
  }
  @media print {
    .firstColumn {
      padding-left: 5px !important;
    }
  }
`;

export const ItemsWrapper = styled.div`
  margin-top: 15px;
  padding: 30px 60px 0px 60px;
  font-family: "Open Sans", sans-serif;
  .job_instructions {
    margin-top: 40px;
    text-align: left;
    p {
      font-style: italic;
      font-weight: 300;
      line-height: 26px;
    }
  }
  .lastColumn {
    text-align: right;
    padding-right: 20px;
  }
  @media (max-width: 699px) {
    .lastColumn {
      padding-right: 0px !important;
    }
  }
  @media print {
    .lastColumn {
      padding-right: 10px !important;
    }
  }
`;

export const TotalsWrapper = styled.div`
  padding: 0px 60px;
  margin-bottom: 40px;
  span {
    font-family: "Open Sans", sans-serif;
    opacity: 0.7;
  }
  .right {
    text-align: right;
  }
  .row {
    padding: 10px 0px;
    border-bottom: 2px solid #e7e7e7;
  }
  .row.container,
  .row.last {
    border-bottom: none;
  }
  .termsContainer {
    padding-top: 8px;
  }
  @media (max-width: 699px) {
    .termsContainer {
      text-align: center !important;
      margin-bottom: 60px;

    }
    .title {
      text-align: left !important;
    }
  }
  @media print {
    margin-bottom: -5px !important;
    .termsContainer {
      text-align: left !important;
      margin-bottom: 20px !important;
      margin-top: 10px !important;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  padding: 0px 60px;
  margin-bottom: 10px;
  span {
    font-family: "Open Sans", sans-serif;
    opacity: 0.7;
  }
  .right {
    text-align: right;
  }
  .row {
    padding: 10px 0px;
  }
  p {
    font-family: "Open Sans", sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .instructions,
  .customer_notes {
    margin-top: 40px;
    p {
      font-style: italic;
      font-weight: 300;
      line-height: 26px;
    }
  }
  .customer_notes {
  }
  .location_map {
    margin-bottom: 20px;
    margin-top: 40px;
  }
  .location-boat {
    margin-top: 40px;
  }
  @media (max-width: 699px) {
    text-align: left !important;
  }
`;

export const TitleSection = styled.div`
  width: 100%;
  font-weight: 900;
  margin-bottom: 20px;
  font-size: 22px;
  display: block;
  word-break: break-word;
  @media (max-width: 699px) {
    font-size: 18px;
  }
  @media (max-width: 370px) {
    font-size: 16px;
  }
`;

export const TitleSubSection = styled.div`
  width: 100%;
  font-weight: 900;
  margin-bottom: 10px;
  font-size: 22px;
  display: block;
  word-break: break-word;
  @media (max-width: 699px) {
    font-size: 18px;
  }
  @media (max-width: 370px) {
    font-size: 16px;
  }
`;

export const Logo = styled.img`
  text-align: right;
  margin-right: -10px;
  max-width: 225px;
  float: right;
  margin-top: 5px;
  max-height: 120px;
  @media (max-width: 699px) {
    display: none;
    &.logoXS {
      display: block;
      text-align: left;
      float: left;
      margin-bottom: 60px;
    }
  }
  @media print {
    display: block !important;
    &.logoXS {
      display: none;
    }
  }
`;

export const PoweredBy = styled.img`
  text-align: center;
  padding: 30px 0px;
  padding-left: 15px;
  width: 215px;
`;

export const PoweredByContent = styled.div`
  text-align: center;
`;

export const PrintButton = styled.button`
  cursor: pointer;
  float: right;
  border: none;
  padding: 0;
  outline: none;
  background: transparent;
  opacity: 0.8;
  &:hover {
    opacity: 0.5;
  }
  @media print {
    display: none;
  }
  @media (max-width: 440px) {
    display: none;
  }
`;

export const Icon = styled.img`
  width: 25px;
  margin-right: 40px;
  margin-top: 10px;
  cursor: pointer;
`;

export const FooterContainer = styled.div`
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
  padding: 20px 0px;
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
  .document_button {
    padding: 5px 25px !important;
    font-size: 14px !important;
    height: 40px !important;
    border-radius: 20px !important;
    border: 1px solid #979797 !important;
  }
  .document_button:first-child {
    margin-right: 5px;
  }
  &.border-none {
    border: none;
  }
  @media print {
    display: none;
  }
`;

export const handleImageError = e => {
  return (e.target.style.display = "none");
};

export const getOrderInfo = params => {
  const options = { headers: { "X-Boatyard-Dashboard": true } };
  return new Promise(async (resolve, reject) => {
    if (params.order_id && params.quote_id) {
      axios
        .get(
          `${apiBaseUrl}/public/orders/${params.order_id}?quote_id=${
            params.quote_id
          }`,
          options
        )
        .then(response => {
          const token =
            response && response.headers
              ? response.headers["x-boatyard-auth-token"] || ""
              : "";
          let order = {};
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data[0]
          ) {
            const orderData = response.data.data[0];
            const providerAddress = get(
              orderData,
              "attributes.provider_address",
              {}
            );
            let provider = get(orderData, "attributes.provider_attributes", {});
            provider.address = providerAddress;

            order = {
              id: orderData.id,
              orderId: get(
                orderData,
                "attributes.provider_location_order_sequence",
                orderData.id
              ),
              token,
              provider,
              createdAt: moment(
                get(orderData, "attributes.created_at", "")
              ).format("MM/DD/YYYY"),
              updatedAt: moment(
                get(orderData, "attributes.updated_at", "")
              ).format("MM/DD/YYYY"),
              childCreditCards: get(
                orderData,
                "attributes.child_account_credit_cards",
                []
              ),
              balance: get(orderData, "attributes.balance", "0"),
              total: get(orderData, "attributes.total", "0"),
              subTotal: get(orderData, "attributes.sub_total", "0"),
              discount: get(orderData, "attributes.discount", "0"),
              tax: get(orderData, "attributes.tax_amount", "0"),
              deposit: get(orderData, "attributes.deposit", "0"),
              state: get(orderData, "attributes.state", ""),
              user: get(orderData, "attributes.user_attributes", {}),
              boat: get(orderData, "attributes.boat_attributes", {}),
              lineItems: get(orderData, "attributes.line_items", [])
            };
          }
          resolve(order);
        })
        .catch(e => {
          reject(e);
        });
    } else {
      reject();
    }
  });
};

export const getCreditCards = token => {
  return new Promise(async (resolve, reject) => {
    if (token) {
      const options = { headers: { Authorization: token } };
      axios
        .get(`${apiBaseUrl}/credit_cards`, options)
        .then(response => {
          const creditCards = response.data.data.map(cc => {
            return {
              id: cc.id,
              attributes: {
                createdAt: cc.attributes.created_at,
                name: cc.attributes.name,
                last4: cc.attributes.last4,
                isDefault: cc.attributes.is_default,
                expirationMonth: cc.attributes.expiration_month,
                expirationYear: cc.attributes.expiration_year
              }
            };
          });
          resolve(orderBy(creditCards, ["attributes.createdAt"], ["desc"]));
        })
        .catch(e => {
          reject(e);
        });
    } else {
      reject();
    }
  });
};

export const getJobInfo = params => {
  const options = { headers: { "X-Boatyard-Dashboard": true } };
  return new Promise(async (resolve, reject) => {
    if (params.access_key && params.assignment_id) {
      axios
        .get(
          `${apiBaseUrl}/public/work_orders?access_key=${
            params.access_key
          }&assignment_id=${params.assignment_id}`,
          options
        )
        .then(response => {
          const token =
            response && response.headers
              ? response.headers["x-boatyard-auth-token"] || ""
              : "";
          let job = {};
          if (response && response.data && response.data.data) {
            const jobData = response.data.data;
            const providerAddress = get(
              jobData,
              "attributes.provider_location_address",
              {}
            );
            let provider = get(jobData, "attributes.provider", {});
            provider.address = providerAddress;

            job = {
              id: get(jobData, "attributes.job_number", jobData.id),
              token,
              provider,
              createdAt: moment(
                get(jobData, "attributes.created_at", "")
              ).format("MM/DD/YYYY"),
              updatedAt: moment(
                get(jobData, "attributes.updated_at", "")
              ).format("MM/DD/YYYY"),
              state: get(jobData, "attributes.state", ""),
              orderId: get(jobData, "attributes.order_id", ""),
              user: get(jobData, "attributes.customer_information", {}),
              boat: get(jobData, "attributes.boat_attributes", {}),
              boatLocation: get(jobData, "attributes.boat_address", {}),
              services: get(jobData, "attributes.services", []),
              notes: get(jobData, "attributes.notes", "")
            };
          }

          resolve(job);
        })
        .catch(e => {
          reject(e);
        });
    } else {
      reject();
    }
  });
};

export const formatDueDate = date => {
  let due_date = "-";
  if (date) {
    if (date.asap) {
      due_date = "As Soon As Possible";
    } else if (date.flexible) {
      due_date = "I'm Flexible";
    } else if (date.complicated) {
      due_date = "It's Complicated";
    }
  }
  return due_date;
};

export const updateOrder = params => {
  return new Promise(async (resolve, reject) => {
    if (params.orderId && params.token) {
      const options = { headers: { Authorization: params.token } };
      axios
        .patch(
          `${apiBaseUrl}/orders/${params.orderId}`,
          { order: params.data || {} },
          options
        )
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    } else {
      reject();
    }
  });
};

export const updateAssignment = params => {
  return new Promise(async (resolve, reject) => {
    if (params.assignmentId && params.token) {
      const options = { headers: { Authorization: params.token } };
      axios
        .patch(
          `${apiBaseUrl}/assignments/${params.assignmentId}`,
          { assignment: params.data || {} },
          options
        )
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    } else {
      reject();
    }
  });
};

export const createCreditCard = params => {
  return new Promise(async (resolve, reject) => {
    if (params.token) {
      const options = { headers: { Authorization: params.token } };
      axios
        .post(
          `${apiBaseUrl}/credit_cards/`,
          { credit_card: params.data || {} },
          options
        )
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    } else {
      reject();
    }
  });
};

export const createPayment = params => {
  return new Promise(async (resolve, reject) => {
    if (params.token) {
      const options = { headers: { Authorization: params.token } };
      axios
        .post(
          `${apiBaseUrl}/payments/`,
          { payment: params.data || {} },
          options
        )
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    } else {
      reject();
    }
  });
};
