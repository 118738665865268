import React, { Fragment } from "react";
import styled from "styled-components";
import { get, findIndex } from "lodash";
import { toastr } from "react-redux-toastr";
import deepEqual from 'fast-deep-equal';
import { Row, Col } from "react-flexbox-grid";

import { HollowButton, BlackButton } from "components/basic/Buttons";
import FormFields from "components/template/FormFields";
import Modal from "components/compound/Modal";

import CreditCardOption from "./CreditCardOption";
import { getLastCreditCard } from 'utils/order';

import CardImg1 from "resources/card_1.png";
import CardImg2 from "resources/card_2.png";
import CardImg3 from "resources/card_3.png";
import CheckImg from "resources/check_circle_invoice.png";

const Wrapper = styled(Row)`
  height: 35px;
  padding: 0 10px;
  > div {
    font-size: 10pt;
    line-height: 1.42857;
    font-weight: 600;
    font-family: Montserrat, sans-serif;
    color: #003247;
  }
  .modal_header_invoice {
      background-color: #fff !important;
  }
`;

const CreditList = styled.div`
  padding-bottom: 30px;
  margin-top: -10px;
  .credit_card_option{
      margin-top: 10px !important;
  }
`;

const Divider = styled.hr`
  margin: 20px 0;
  border-top: 1px solid #f1f1f1;
`;

const Text = styled.div`
  text-align: left;
  line-height: 24px;
  font-size: 16px;
  color: #003247;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 40px 25px 40px 25px;
`;

const CardLogo = styled.img`
  vertical-align: middle;
  margin-left: 5px;
`;

const Title = styled.div`
  font-family: "Open Sans";
  font-size: 16px;
  color: #8a898a;
  line-height: 26px;
  margin-left: 15px;
  margin-top: -5px;
`;

const coutries = [
  {
    label: "United States",
    value: "US"
  },
  {
    label: "Canada",
    value: "CA"
  }
];

const cardFields = [
  {
    type: "text_field",
    field: "card_number",
    className: "primary",
    label: "CARD NUMBER",
    errorMessage: "Please enter a valid card number.",
    required: true,
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6
  },
  {
    type: "text_field",
    field: "expiration_month",
    className: "primary",
    label: "MONTH",
    mask: "99",
    required: true,
    errorMessage: "Required",
    xs: 12,
    sm: 6,
    md: 2,
    lg: 2,
    xl: 2
  },
  {
    type: "text_field",
    field: "expiration_year",
    className: "primary",
    label: "YEAR",
    required: true,
    errorMessage: "Required",
    mask: "9999",
    xs: 12,
    sm: 6,
    md: 2,
    lg: 2,
    xl: 2
  },
  {
    type: "text_field",
    field: "cvv",
    className: "primary",
    label: "CVV",
    required: true,
    errorMessage: "Required",
    xs: 12,
    sm: 6,
    md: 2,
    lg: 2,
    xl: 2
  }
];

let infoFields = [
  {
    type: "text_field",
    field: "card_first_name",
    className: "primary",
    label: "First Name",
    required: true,
    errorMessage: "Required",
    defaultValue: "",
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6
  },
  {
    type: "text_field",
    field: "card_last_name",
    className: "primary",
    label: "Last Name",
    required: true,
    errorMessage: "Required",
    defaultValue: "",
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6
  },
  {
    type: "text_field",
    field: "card_zip_code",
    className: "primary",
    label: "Zip Code",
    required: true,
    errorMessage: "Required",
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6
  },
  {
    type: "select_box",
    field: "card_country",
    className: "primary",
    label: "Country",
    options: coutries,
    defaultValue: coutries[0].value,
    required: true,
    errorMessage: "Choose Country",
    xs: 12,
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6
  }
];

class InvoicePaymentMethods extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createMode: true,
      thanks: Boolean(this.props.thanksView),
      confirm: false,
      loading: false,
      init: false,
      provider: "",
      token: "",
      user: {},
      selected: "",
      selectedData: {}
    };
  }

  componentDidUpdate(prevProps) {
    if(!deepEqual(prevProps.creditCards, this.props.creditCards)) {
      const idx = findIndex(this.props.creditCards, ({attributes: { isDefault }}) => isDefault)
      let id = get(this.props, `creditCards[${idx}].id`, -1)
      if(this.props.creditCards && this.props.creditCards.length > 0 && id === -1){
        const lastCreditCard = getLastCreditCard(this.props.creditCards);
        id = get(lastCreditCard, `id`, -1)
      }
      this.setState({
        selected: id,
        createMode: Boolean(this.props.creditCards === 0)
      });
      this.props.onChange(id)
    }
    if(this.props.paid && !this.state.thanks){
      this.setState({
        thanks: true,
        createMode: false,
        confirm: false
      })
    }
  }

  getCreditCardById = () => {
    const { selected } = this.state;
    const { creditCards } = this.props;
    let selectedData = {};

    if(selected && selected > 0 && creditCards.length > 0) {
      creditCards.forEach((cc) => {
        if(cc.id === selected){
          selectedData = cc.attributes;
        }
      })
    }

    return selectedData;
  }

  showCreation = () => {
    this.setState({ createMode: true, thanks: false });
  };

  hideCreation = () => {
    const { creditCards, onClose } = this.props;
    if(creditCards.length === 0){
      onClose();
    }else{
      this.setState({ createMode: false, thanks: false });
    }
  };

  showConfirm = () => {
    const { selected } = this.state;

    if (selected) {
      this.setState({ confirm: true });
    } else {
      toastr.error("Error", "No payment method selected");
    }
  };

  hideConfirm = () => {
    this.setState({ confirm: false });
  };

  setCardFieldsRef = ref => {
    this.cardFields = ref;
  };

  setInfoFieldsRef = ref => {
    this.infoFields = ref;
  };

  onSelectCard = (selected) => {
    this.setState({ selected })
    this.props.onChange(selected)
  };

  saveCreditCard = () => {
    const cardsVailid = this.cardFields.validateFields();
    const infoValid = this.infoFields.validateFields();
    if (cardsVailid && infoValid) {
      const cardValue = this.cardFields.getFieldValues();
      const infoValues = this.infoFields.getFieldValues();
      const data = { ...cardValue, ...infoValues };
      this.props.onCreateCreditCard(data);
    }
  };

  render() {
    const {
      createMode,
      thanks,
      confirm,
      selected
    } = this.state;

    const { open, onClose, loading, user, creditCards, total, provider, onSaveSelection, onlyAddCard } = this.props;

    const actionsSelect = [
      <HollowButton className="rounded" key={"create_cancel"} onClick={onClose}>
        CANCEL
      </HollowButton>,
      <BlackButton className="rounded" key={"select_success"} onClick={this.showConfirm}>
        SUBMIT
      </BlackButton>
    ];
    let actionsCreate = [
      <HollowButton className="rounded" key={"confirm_cancel"} onClick={onlyAddCard? onClose : this.hideCreation}>
        CANCEL
      </HollowButton>,
      <BlackButton className="rounded" key={"create_success"} onClick={this.saveCreditCard}>
        {onlyAddCard ? "ADD CARD" : "PAY INVOICE"}
      </BlackButton>
    ];

    const actionsConfirm = [
      <HollowButton className="rounded" key={"confirm_cancel"} onClick={this.hideConfirm}>
        CANCEL
      </HollowButton>,
      <BlackButton className="rounded" key={"confirm_success"} onClick={onSaveSelection}>
        SUBMIT PAYMENT
      </BlackButton>
    ];

    const actionsThanks = [
      <BlackButton className="rounded" key={"confirm_success"} onClick={onClose}>
        DONE
      </BlackButton>
    ];

    infoFields[0].defaultValue = get(user, "first_name");
    infoFields[1].defaultValue = get(user, "last_name");

    let modalButtons = undefined;
    let cardLogoImg = CardImg1;
    let titleText = "Please select your preferred payment method.";
    const selectedCardData = this.getCreditCardById();

    if (thanks) {
      modalButtons = actionsThanks;
      cardLogoImg = CheckImg;
      titleText = onlyAddCard ? "Your payment method has been securely stored. If they have not already, our team will reach out to schedule your service." :
        "Thank you for your payment. We appreciate your business and look forward to serving you again.";
    } else if (confirm) {
      modalButtons = actionsConfirm;
      cardLogoImg = CardImg3;
      titleText =
        `Please confirm that you would like to pay ${total} with your credit card ending in: ${selectedCardData.last4}${provider ? ` to ${provider}` : '.'}`;
    } else if (createMode) {
      modalButtons = actionsCreate;
      cardLogoImg = CardImg2;
      titleText = onlyAddCard ? <span>Thank you for accepting the quote. Please add a payment method to keep on file for your order.</span> :
        `Please enter your credit card information below to complete your payment${provider ? ` to ${provider}` : '.'}`;
    } else if (!createMode) {
      modalButtons = actionsSelect;
      cardLogoImg = CardImg1;
      titleText = "Please select your preferred payment method.";
    }

    const customContent = () => (
      <HeaderContainer>
        <CardLogo src={cardLogoImg} />
        <Title>{titleText}</Title>
      </HeaderContainer>
    );

    return (
      <Wrapper>
        <Modal
          title={thanks ? "Thank You!" : onlyAddCard ? "Add Payment Method" : "Select Payment Method"}
          open={open}
          actions={modalButtons}
          loading={loading}
          onClose={onClose}
          normal
          classes={"header_white"}
          hideContent={Boolean(thanks || confirm)}
          customContent={customContent()}
        >
          <Fragment>
            {!createMode &&
              !thanks &&
              !confirm && (
                <Fragment>
                  <Row>
                    <Col sm={12}>
                      <CreditList>
                        <Row>
                          {creditCards.map((creditCard, idx) => (
                            <Col sm={12} md={6} key={`option_${idx}`}>
                              <CreditCardOption
                                creditCard={creditCard}
                                isSelected={selected === creditCard.id}
                                onSelect={this.onSelectCard}
                                key={`option_${idx}`}
                                oneRowExp={true}
                              />
                            </Col>
                          ))}
                        </Row>
                      </CreditList>
                    </Col>
                  </Row>
                  <HollowButton
                    key={"new_payment_method"}
                    className="rounded"
                    onClick={this.showCreation}
                  >
                    New Payment Method
                  </HollowButton>
                </Fragment>
              )}
            {createMode &&
              !thanks &&
              !confirm && (
                <Fragment>
                  {creditCards.length === 0 && !onlyAddCard && (
                    <Fragment>
                       {/*<Title hide>
                        Please add a credit card to your account
                        {provider && ` with ${provider}`}.
                      </Title>
                      <Divider />*/}
                    </Fragment>
                  )}
                  <FormFields ref={this.setCardFieldsRef} fields={cardFields} />
                  {creditCards.length !== 0 && <Divider />}
                  <FormFields ref={this.setInfoFieldsRef} fields={infoFields} />
                </Fragment>
              )}
            {confirm && (
              <Text hide>
                Please confirm that you would like to provide your{" "}
                {selectedCardData.name} ending in {selectedCardData.last4}
                {provider && ` to ${provider}`}.
              </Text>
            )}
            {thanks && (
              <Text hide>
                Your payment method has been securely stored. We appreciate your
                business and look forward to serving you.
              </Text>
            )}
          </Fragment>
        </Modal>
      </Wrapper>
    );
  }
}

export default InvoicePaymentMethods;
