import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FormFields from 'components/template/FormFields';

const Wrapper = styled.div`
  margin-bottom: 25px;
  width: 100%;
`

const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003247;
`;

const FieldWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: #fafafa;
  margin-top: 25px;
  padding: 23px 25px 9px;
`

class TagsInfo extends React.Component {

  getTagsInfoField = () => {
    const { tags, defaultValues } = this.props;

    let fields = [];
    
    tags.forEach((tag) => {
      fields.push({
        type: 'check_field',
        field: tag.id,
        title: tag.name,
        className: 'lower',
        defaultValue: Boolean(defaultValues.includes(tag.id)),
        color: '#003247',
        xs: 12,
        sm: 12,
        md: 3,
        lg: 3,
        xl: 3
      })
    })

    return fields;
  }
  setRef = (ref) => {
    this.fields = ref;
  }
  getFieldValues = () => {
    const fields = this.fields.getFieldValues();
    let selected = [];
    for(let i in fields){
      if(fields[i]){
        selected.push(parseInt(i));
      }
    };
    return selected;
  }
  validateFields = () => {
    return this.fields.validateFields();
  }
  render() {
    const fields = this.getTagsInfoField();
    return (
      <Wrapper>
        <Title>Tags</Title>
        <FieldWrapper>
          <FormFields ref={this.setRef} fieldSize="small" fields={fields} />
        </FieldWrapper>
      </Wrapper>
    )
  }
}

TagsInfo.propTypes = {
  tags: PropTypes.array,
  defaultValues: PropTypes.array
};

TagsInfo.defaultProps = {
  tags: [],
  defaultValues: []
};

export default TagsInfo;
