import React, { Fragment } from "react";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { Form } from "react-final-form";
import { Row } from "react-flexbox-grid";
import { toastr } from "react-redux-toastr";

import { apiBaseUrl } from "api/config";
import LMCLogo from "../../resources/lmc-logo.png";
import FormFields from "components/template/FormFields";

import FormContent from "./components/formContent";

const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  .instructions-field {
    margin-top: 20px;
  }
`;

const Img = styled.img`
  margin: 40px auto 0;
  flex-shrink: 0;
  width: 205px;
`;

const TitleLabel = styled.div`
  font-size: 30px;
  color: #184961;
  margin-top: 40px;
  text-align: center;
  padding: 0px 20px;
`;

const DescLabel = styled.div`
  font-size: 18px;
  margin-top: 5px;
  color: #184961;
  text-align: center;
`;

const TitleThanksLabel = styled.div`
  font-size: 30px;
  color: #184961;
  margin-top: 10px;
  text-align: center;
`;

const DescThanksLabel = styled.div`
  font-size: 18px;
  margin: 5px auto;
  margin-bottom: 10px;
  color: #184961;
  width: 300px;
  text-align: center;
`;

const VesselCode = styled.div`
  font-family: "Neutraface2-Text-Demi";
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 5px;
  color: #fff;
  text-align: center;
  background-color: #8b9cb8;
  width: 100%;
  padding: 15px;
  margin-bottom: 30px;
`;

const SectionWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const FielsContainer = styled.div`
  width: 100%;
`;

const SectionContainer = styled.div`
  padding: 20px 50px;
  .primary {
    .field-section:first-child > div {
      font-weight: 400;
      color: #184961;
      font-family: "Neutraface2-Text-Bold" !important;
    }
    .field-section > select {
      padding-left: 0px;
    }
    .field-section > select,
    .react-datepicker-ignore-onclickoutside,
    .react-datepicker__month-container,
    .field-section > textarea,
    .react-datepicker__input-container > input {
      padding-right: 0px;
      font-weight: 400;
      font-family: "Neutraface2-Text-Book" !important;
    }
    &.disabled {
      input {
        background: #f1f1f1;
        opacity: 0.7;
      }
    }
  }
`;

const ActionButton = styled.button`
  font-family: "Neutraface2-Text-Bold";
  background: #314357;
  border-radius: 20px;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  height: 50px;
  margin-top: 20px;
  border: none;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;
`;

const CancelLink = styled.a`
  font-family: "Neutraface2-Text-Book";
  font-weight: 400;
  font-size: 18px;
  color: #184961;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  text-decoration: underline;
  cursor: pointer;
`;

class ConfirmReservation extends React.PureComponent {
  state = {
    isDeparture: true,
    reservation: {},
    headers: {},
    order: 0,
    token: "",
    loading: true,
    thanks: false,
    cancelled: false
  };

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query && query.order && query.token) {
      this.setState({ order: query.order, token: query.token }, () => {
        this.loadReservation();
      });
    } else {
      setTimeout(() => toastr.error("Error", "Token Not Found"), 100);
    }
  }

  loadReservation = () => {
    const { order, token } = this.state;

    let headers = {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": "*",
      Authorization: token
    };

    axios
      .get(`${apiBaseUrl}/confirm_reservations/${order}`, {
        headers: headers
      })
      .then(response => {
        if (response.data) {
          const {
            departure,
            order_id,
            vessel,
            dockage,
            arrival_date,
            departure_date,
            special_instructions
          } = response.data;
          if (order_id) {
            this.setState({
              loading: false,
              isDeparture: Boolean(departure),
              headers,
              reservation: {
                id: order_id,
                vessel: vessel || "",
                dockage: dockage || "",
                arrival_date,
                departure_date,
                special_instructions: special_instructions || ""
              }
            });
          }
        }
      })
      .catch(e => {
        toastr.error("Error", "Link expired");
      });
  };

  getReservationFields = () => {
    const { isDeparture, reservation } = this.state;
    const fields = [
      {
        field: "dockage",
        label: "DOCKAGE:",
        type: "select_box",
        className: "primary no-padding-left",
        errorMessage: "Select a dockage",
        disabled: true,
        required: false,
        defaultValue: reservation.dockage || "",
        options: [
          { value: reservation.dockage || "", label: reservation.dockage || "" }
        ],
        xs: 12,
        sm: 4,
        md: 4,
        lg: 4,
        xl: 4
      },
      {
        type: "date",
        field: "arrival",
        className: `primary ${isDeparture ? "disabled" : ""}`,
        label: "ARRIVAL:",
        errorMessage: "Enter the arrival date",
        disabled: isDeparture,
        required: true,
        defaultValue: reservation.arrival_date
          ? new Date(reservation.arrival_date)
          : new Date(),
        xs: 12,
        sm: 4,
        md: 4,
        lg: 4,
        xl: 4
      },
      {
        type: "date",
        field: "departure",
        className: "primary",
        label: "DEPARTURE:",
        errorMessage: "Enter the departure date",
        required: true,
        defaultValue: reservation.departure_date
          ? new Date(reservation.departure_date)
          : new Date(),
        xs: 12,
        sm: 4,
        md: 4,
        lg: 4,
        xl: 4
      },
      {
        type: "text_area",
        field: "instructions",
        className: "primary instructions-field",
        label: "SPECIAL INSTRUCTIONS:",
        required: false,
        defaultValue: reservation.special_instructions || "",
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12
      }
    ];
    return fields;
  };

  handleSubmit = () => {
    const { order, headers } = this.state;

    if (this.mainInfoFields.validateFields()) {
      const fields = this.mainInfoFields.getFieldValues();
      const specific_start = moment(fields.arrival).format("YYYY-MM-DD");
      const specific_stop = moment(fields.departure).format("YYYY-MM-DD");

      if (moment(specific_start).isSameOrBefore(moment(specific_stop))) {
        const params = {
          dockage: fields.dockage,
          special_instructions: fields.instructions,
          specific_start,
          specific_stop
        };

        axios
          .put(
            `${apiBaseUrl}/confirm_reservations/${order}`,
            { confirm_reservation: params },
            {
              headers: headers
            }
          )
          .then(response => {
            this.setState({ thanks: true });
          })
          .catch(e => {
            toastr.error("Error", e.message);
          });
      } else {
        toastr.error("Error", "Departure date must be after Arrival date");
      }
    }
  };

  changeField = () => {};

  onChangeComment = () => {};

  handleCancel = e => {
    e.preventDefault();
    const { order, headers } = this.state;

    axios
      .delete(`${apiBaseUrl}/confirm_reservations/${order}`, {
        headers: headers
      })
      .then(response => {
        this.setState({ cancelled: true, thanks: true });
      })
      .catch(e => {
        toastr.error("Error", e.message);
      });
  };

  setMainFieldsRef = ref => {
    this.mainInfoFields = ref;
  };

  gotToWeb = () => {
    this.setState({ loading: true }, () => {
      window.location.href = "https://www.lauderdalemarinecenter.com/";
    });
  };

  render() {
    const { reservation, loading, thanks, cancelled, isDeparture } = this.state;
    const reservationFields = this.getReservationFields();

    return (
      <FormContent loading={loading}>
        <Fragment>
          {!thanks && (
            <Form
              onSubmit={this.handleSubmit}
              render={({ handleSubmit, submitting }) => (
                <FormContainer onSubmit={handleSubmit}>
                  <Img src={LMCLogo} />

                  <Fragment>
                    <TitleLabel>{"Your Reservation"}</TitleLabel>
                    <DescLabel>
                      {"Please confirm or modify your reservation."}
                    </DescLabel>
                    <SectionWrapper>
                      <SectionContainer>
                        <Row>
                          <VesselCode>
                            VESSEL: {reservation.vessel || ""}
                          </VesselCode>
                        </Row>
                        <Row>
                          <FielsContainer>
                            <FormFields
                              ref={this.setMainFieldsRef}
                              fields={reservationFields}
                            />
                          </FielsContainer>
                        </Row>
                        <Row>
                          <ActionButton>CONFIRM</ActionButton>
                        </Row>
                        {!isDeparture && (
                          <Row>
                            <CancelLink onClick={this.handleCancel}>
                              Cancel Reservation
                            </CancelLink>
                          </Row>
                        )}
                      </SectionContainer>
                    </SectionWrapper>
                  </Fragment>
                </FormContainer>
              )}
            />
          )}
          {thanks && (
            <Fragment>
              <Img src={LMCLogo} />
              <SectionWrapper>
                <SectionContainer>
                  <TitleThanksLabel>{"Thank You!"}</TitleThanksLabel>
                  <DescThanksLabel>
                    {!cancelled && (
                      <span>
                        Thank you for your confirmation.
                        <br />
                        We look forward to welcoming you to Lauderdale Marine
                        Center.
                      </span>
                    )}
                    {cancelled && (
                      <span>
                        One of our friendly staff will contact you shortly to
                        confirm.
                      </span>
                    )}
                  </DescThanksLabel>
                  <ActionButton onClick={this.gotToWeb}>
                    VIEW OUR WEBSITE
                  </ActionButton>
                </SectionContainer>
              </SectionWrapper>
            </Fragment>
          )}
        </Fragment>
      </FormContent>
    );
  }
}

export default withRouter(ConfirmReservation);
